import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import  './assets/css/master.css'

import '@aws-amplify/ui-vue'
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify'
import aws_exports from './aws-exports'
import './plugins/element.js'
import './assets/css/element-variables.scss'
import { Storage } from 'aws-amplify'
import VueI18n from "vue-i18n"
import { en } from "./messages/en"
import moment from 'moment-timezone';
import linkify from 'vue-linkify'
import VueObserveVisibility from 'vue-observe-visibility'

import { getCounselingType } from '@/views/Counseling/queries'

import ConnectionHelper from '@/utilities/connectionHelper'
import AuditLog from '@/utilities/auditLog'
import { setTenantStatus } from '@/views/System/Tenants/functions'
import { customListsByGroup } from '@/api/queries'
import { breakpointsPlugin } from '@/utilities/breakpoints'
import { getUsersPreferredLanguage } from '@/utilities/getLanguageBrowser';
import { customConsoleLogging } from '@/plugins/CustomConsoleLogging'

const userLocale = getUsersPreferredLanguage();
ConnectionHelper.addConnectionEvent(this)
const auditLog = new AuditLog()

console.warn('disabled consoles on production')
customConsoleLogging(localStorage.getItem("VUE_APP_HERA_ENV"))

Vue.directive('linkified', linkify)
Vue.use(require('vue-moment'))
Vue.use(VueObserveVisibility)
Vue.use(VueI18n)

if(process.env?.VUE_APP_HERA_ENV === 'pdr' && process.env?.VUE_APP_REGION === 'us-east-1'){
  console.log("/////////////////////////////-- PRODUCTION DR IN ACTION --///////////////////////////")
  Amplify.configure({
    Auth: {
        identityPoolRegion: 'us-east-2',
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-2:e08c0f55-179e-4a2f-9821-050e17fc9ee0', 
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-2', 
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_Lt8dKaptb',
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: '1pr832o5u6s4jl1hsiuucoh0av', 
    }
  });
}
Amplify.configure(aws_exports)

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]


function get(obj, path) {
  let cur = obj;
  for (let i = 0; i < path.length; i++) {
      cur = cur[path[i]];
  }
  return cur;
}

function printUserError(e){
  console.groupCollapsed('%cLog Events','color: rgba(128, 128, 255, .5)')
  console.group(`%c${e}`,'color: rgba(255, 128, 128, .5)')
  console.error({'User Error':e}, e)
  console.groupEnd()
  console.groupEnd()
}

function errorCatch(err) {
  const unauthorizedErrors = [
    'No current user',
    'not authenticated',
    'Not Authorized to access'
  ]
  if(unauthorizedErrors.includes(err) || unauthorizedErrors.includes(err.message)){
    if(window.location.pathname !== '/' ){
      router.push({
        path: '/',
        query: { redirect: window.location.pathname }
      })
    }
    return
  }

  printUserError(err)

  err.errors = err.errors.filter((e) => {
    // ignore unauthorized errors with null values
    // fix for amplify error: https://github.com/aws-amplify/amplify-cli/issues/4907
    if (unauthorizedErrors.includes(e.message)) {
      const val = get(err.data, e.path);
      if (val === null) {
        return false;
      }
    }
    return true;
  })

  if (err.errors.length !== 0) {
    throw err;
  }
  if(!err.data){
    err.data = {}
  }
  return err;
}

//redirect from push notifications
if (navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('message', event => {
      if (event.data && event.data.type === 'NAVIGATE_TO_ROUTE') {
        const { routeName, params } = event.data;
        // Navegar a la ruta usando Vue Router
        router.push({ name: routeName, params: params });
      }
  });
}
//redirect from push notifications
document.addEventListener('DOMContentLoaded', () => {
  const urlParams = new URLSearchParams(window.location.search);
  const routeName = urlParams.get('routeName');
  const paramsString = urlParams.get('params');
  const params = paramsString ? JSON.parse(paramsString) : null;

  if (routeName) {
      router.replace({ name: routeName, params: params });
  }
});

const limitFactor = (simpleResolverCount, arrayResolverCount, type)=>{
  const limits = {
    parent: {
      max: 1000,
      min: 50,
      decrementFactor: 2.3
    },
    children:{
      max: 1000,
      min: 300,
      decrementFactor: 1.5
    }
  }
  const { max, min, decrementFactor } = limits[type]
  const x =  max - ( (simpleResolverCount-1) + arrayResolverCount * 40 ) * decrementFactor
  const results = Math.round(x)
  return Math.max(results, min)
}

export const setLimit = function(query, input){
  if(!query || !input) return

  const regexpHeader = /\s*(?<connection>\w*)\s*(\((?<params>[\w\d,:\$\s]*)\))?\s*\{\s*items\s*\{/g
  const numberOfArrayResolvers = (query.match(regexpHeader) || []).length
  let numberOfResolvers = (query.match(/\{\s*id/g)||[]).length - numberOfArrayResolvers
  numberOfResolvers < 1 && (numberOfResolvers = 1)
  
  if(
    !/((query\s+Get)|(mutation\s+Create)|(mutation\s+Update)|(mutation\s+Delete))/g.test(query) &&
    typeof input.limit !== 'number'
  ){
    input.limit = limitFactor(numberOfResolvers, numberOfArrayResolvers, 'parent')
  }

  const limitAverage = limitFactor(numberOfResolvers, numberOfArrayResolvers, 'children')
  
  const newQuery = query.replace(regexpHeader, function(){
      const match = arguments[0]
      const {connection, params} = arguments[arguments.length-1]
      let tmpS, result
      if(params){
          const limit = /\s*limit\s*:\s*(?<prefix>\$)?(?<key>([\w\d])*)\s*/gm.exec(params)
          if(limit){
              const {prefix, key} = limit.groups || {}
              if(prefix && key){
                  if(!input[key]){
                    input[key] = limitAverage
                  }
              }
          }else{
              tmpS = params + `\n limit: ${limitAverage} \n`
              result = match.replace(params, tmpS)
          }
      }else{
          tmpS = connection + `(limit: ${limitAverage})`
          result = match.replace(connection, tmpS)
      }
      return result || match
  })
  return newQuery
}

//Register global mixins
export const mixinMethods = {
    isAndroid() {
      return /Android/i.test(navigator.userAgent);
    },
    isIos() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
    api: async (query, variables = {}, timestampQuery = null, timestampQueryName = null) => {
      let result
      let timestampResult
      
      try{
        
        if (query.includes('CreateAccidentInput')) variables.input.accidentCreatedByUserId = store.state.userInfo.id
        else if (query.includes('CreateDailyLogInput')) variables.input.dailyLogCreatedByUserId = store.state.userInfo.id

        // resrict access for expired trials and unpaid invoices 
        if(( query.includes('mutation Update') || query.includes('mutation Create')) && ((store.getters.isTrialAccount && store.getters.isTrialExpired) || store.getters.isOustandingExpired) && !store.getters.hasLogguedSupportAdministrator){
          // allow for updating tenant and card records
          if(!query.includes('mutation UpdateTenant') && !query.includes('mutation UpdateCard') && !query.includes('mutation CreateCard') && !query.includes('mutation CreatePremiumStatusHistory') && (!query.includes('mutation CreateValueList') || query.includes('mutation CreateValueListItem'))){
            var action = 'Please select a purchase option'
            if(store.getters.isOustandingExpired) action = "Please update your active payment method"

            throw {
              errors: [
                { message: action + ' to regain access to Hera.'}
              ]
            }
          }
        }

        else if(query.includes('mutation Update') && timestampQuery){
          var timestampInput = {
            id: variables.input.id,
            group: variables.input.group
          }
          timestampResult = await safeFunction(API.graphql)(graphqlOperation(timestampQuery, cleanVars(timestampInput, timestampQuery)))

          var timestamp = timestampResult.data[timestampQueryName].updatedAt
          
          if(variables.input.updatedAt != timestamp){
            console.log({
              passedInTimestamp: variables.input.updatedAt,
              currentTimestamp: timestamp,
              id: variables.input.id
            })
            var err = {
              errors:[
                {
                  message: "Record was updated by another user. Please copy any changes and refresh the page."
                }
              ]
            }


            throw err
          }
        }

        query = setLimit(query, variables)
        // run query or mutation
        result = await safeFunction(API.graphql)(graphqlOperation(query, cleanVars(variables, query)))

        //Add Audit Log Entry on successful api call.
        auditLog.create(variables, query, result, store);
      }
      catch(err){
        errorCatch(err)
        result = err
        // err.errors = err.errors.filter((e) => {
        //   // ignore unauthorized errors with null values
        //   // fix for amplify error: https://github.com/aws-amplify/amplify-cli/issues/4907
        //   if (e.message.indexOf("Not Authorized to access") === 0) {
        //     const val = get(err.data, e.path);
        //     if (val === null) {
        //       return false;
        //     }
        //   }
        //   return true;
        // })

        // if (err.errors.length !== 0) {
        //   throw err;
        // }

        // result = err;
      }
      return result

    },
    // detects only mobile device
    isDeviceMobileOnly() {
      let _isDeviceMobile = window.innerWidth < 768 ? true :  false;
      return _isDeviceMobile;
    },
    // detects devices like mobile, tablets ...
    isMobileDevice() {
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isIpad = navigator.maxTouchPoints > 1;
      return mobileRegex.test(navigator.userAgent) || isIpad;
    },
    disableMobileKeyboard() {
      const datePickerInputs = document.querySelectorAll('.custom-date-picker .el-input__inner');
      datePickerInputs.forEach((input) => {
       input.addEventListener('focus', this.preventMobileKeyboard);
      });
    },
    preventMobileKeyboard(event) {
      event.target.blur();
    },

    /**
     * Comma separates items in an array
     * @param {array} phoneNumber 
     * @returns 
     */
    commaSeparatedArray: function(array){
      return array.join(', ')
    },

    /**
     * Cleans a phone number
     * @param {phoneNumber} string 
     * */
    cleanPhoneNumber: function (phoneNumber) {
      var cleaned = ('' + phoneNumber).replace(/\D/g, '')
      return cleaned
    },

    timeAMPM: function (value) {
      if (!value) return '--:--'
      var timeValue = null;
      var date = new Date(value);
      var hours = date.getHours();
      if (hours > 0 && hours <= 12) {
        timeValue= "" + hours;
      } else if (hours > 12) {
        timeValue= "" + (hours - 12);
      } else if (hours == 0) {
        timeValue= "12";
      }
      if(hours >= 12){
        var abbrev = "PM"
      }
      else{
        var abbrev = "AM"
      }
      
      var minutes = "0" + date.getMinutes();
      var formattedTime = `${timeValue}:${minutes.substr(-2)} ${abbrev}`
      return formattedTime
    },

    /**
     * Cleans mileage to only have numbers
     * @param {mileage} string 
     * */
    cleanMileage: function (mileage) {
      var cleaned = ('' + mileage).replace(/\D/g, '')
      return cleaned
    },

    /**
     * Adds thousand separator to numbers
     * @param {mileage} string 
     * @returns 
     */
    formatMileage: function(mileage) {
      var cleaned = ('' + mileage).replace(/\D/g, '')
      return cleaned.replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
    },
    
    cleanTransporterId(transporterId){
      return transporterId.replace(/\s+/g, '')
    },

    /**
     * Cleans a email
     * @param {email} string 
     * */
    cleanEmail: function (email) {
      return email.toLowerCase().replace(/\s+/g, '')
    },

    
    getEnvPinpointNumber: function(){
      var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
      if(subdomain == 'dsp') return '+13342928628'
      else if (subdomain == 'dev') return '+17082942039'
      else if (subdomain == 'v2') return '+13342928628'
      else if (subdomain == 'v3') return '+19046267075'
      else return '+19046267075' // default to v3 for testing on local host
    },

        /**
   * Add background color and color to text on status pills
   */

  setClassStatus(status='') {
    return {
      'text-red-600 bg-red-500': status.toLowerCase().includes('inactive'),
      'text-purple-600 bg-purple-500': status.toLowerCase().includes('onboarding'),
      'text-green-600 bg-green-500': status.toLowerCase() === 'active',
    }
  },

    /**
     * Converting type of time
     */
    convert12to24Time(time) {
      return moment(time, ["hh:mm a"]).format("HH:mm:ss");
    },

    convert24to12Time(time) {
      return moment(time, ["hh:mm:ss"]).format("hh:mm a");
    },

    convertTo24Hour(timeStr) {
      const regex = /(\d{1,2}):(\d{2})\s*(AM|PM|am|pm|Am|Pm)?/;
      const match = timeStr?.match(regex);
  
      if (match) {
          let hours = parseInt(match[1]);
          const minutes = match[2];
          const period = match[3] ? match[3].toUpperCase() : null;
  
          if (period === 'PM' && hours < 12) {
              hours += 12;
          }
          if (period === 'AM' && hours === 12) {
              hours = 0;
          }
  
          const hoursStr = hours.toString().padStart(2, '0');
          const minutesStr = minutes.padStart(2, '0');
  
          return `${hoursStr}:${minutesStr}`;
      }
  
      return null;
    },
    /**
     * Cleans GraphQL input for updating/ creating accident
     * @param {Accident} accidentInput 
     * @returns 
     */
    cleanAccidentInput: function(accidentInput){
      delete accidentInput.staff
      delete accidentInput.vehicle
      delete accidentInput.verifiedBy
      delete accidentInput.createDaIssue
      delete accidentInput.createCounseling
      delete accidentInput.maintenanceRecords
      delete accidentInput.images
      delete accidentInput.vehicleDamageImages
      delete accidentInput.odometerReadingRoutes
      delete accidentInput.maintenanceVehicle
      delete accidentInput.damageVehicle
      delete accidentInput.odometerReadingVehicle
      delete accidentInput.deviceId
      delete accidentInput.vehicleDamageRoute
      delete accidentInput.incidentType
      delete accidentInput.isReplacement

      return accidentInput
    },
    

    /**
     * Cleans GraphQL input for updating a device
     * @param {counselingRoute} deviceInput 
     */
    cleanCounselingInput: function(counselingInput){
      delete counselingInput.staff
      delete counselingInput.user
      delete counselingInput.infractions
      delete counselingInput.images
      delete counselingInput.severity
      delete counselingInput.counselingType
      delete counselingInput.counselingSeverity
      delete counselingInput.__typename
      return counselingInput
    },

    /**
     * Cleans GraphQL input for updating a device
     * @param {deviceInput} string 
     * */
    cleanDeviceInput: function (deviceInput) {
      delete deviceInput.vehicle
      delete deviceInput.vehicle2
      delete deviceInput.route
      delete deviceInput.route2
      delete deviceInput.replaceByRoute
      return deviceInput
    },

    /**
     * Cleans GraphQL input for updating a infraction
     * @param {infractionInput} string 
     * */
    cleanInfractionInput: function (infractionInput) {
      delete infractionInput.staff
      delete infractionInput.counseling
      delete infractionInput.document
      delete infractionInput.documents
      delete infractionInput.route
      return infractionInput
    },    

    /**
     * Cleans GraphQL input for updating a staff member
     * @param {staffInput} string 
     * */
    cleanRouteInput: function (routeInput) {
      delete routeInput.routeStatusHistory
      delete routeInput.rescuers
      delete routeInput.kudos
      delete routeInput.dailyLogs
      delete routeInput.device
      delete routeInput.device2
      delete routeInput.vehicle
      delete routeInput.staff
      delete routeInput.accidentReport
      delete routeInput.document
      delete routeInput.injuryReport
      delete routeInput.helper
      delete routeInput.replaceByRoute
      delete routeInput.rescuer
      delete routeInput.messages
      delete routeInput.odometerReadings
      delete routeInput.vehicleDamage
      delete routeInput.isReplacement
      delete routeInput.parkingSpace
      delete routeInput.status
      delete routeInput.helperStatus
      return routeInput
    },

    cleanUpdateRouteInput: function(routeInput) {
      delete routeInput.device
      delete routeInput.device2
      delete routeInput.vehicle
      delete routeInput.staff
      delete routeInput.accidentReport
      delete routeInput.document
      delete routeInput.injuryReport
      delete routeInput.helper
      delete routeInput.replaceByRoute
      delete routeInput.rescuer
      delete routeInput.messages
      delete routeInput.odometerReadings
      delete routeInput.helpers
      delete routeInput.rescuers
      delete routeInput.replaceByStandbyDA
      delete routeInput.replaceWithDA
      delete routeInput.replacedByStandbyByRoute
      delete routeInput.createCounseling
      delete routeInput.createDaIssue
      delete routeInput.vehicleDamage
      delete routeInput.status
      return routeInput
    },

    cleanReplaceByRouteInput: function (routeInput) {
      delete routeInput.device
      delete routeInput.vehicle
      delete routeInput.dailyRoster
      delete routeInput.staff
      delete routeInput.route
      delete routeInput.accidentReport
      delete routeInput.document
      delete routeInput.injuryReport
      delete routeInput.messages
      delete routeInput.odometerReadings
      delete routeInput.helper
      delete routeInput.parkingSpace
      delete routeInput.status
      delete routeInput.replaceByRouteStatusHistory
      return routeInput
    },

    /**
     * Cleans GraphQL input for updating a staff member
     * @param {staffInput} string 
     * */
    cleanStaffInput: function (staffInput) {
      delete staffInput.onBoarding
      delete staffInput.accidents
      delete staffInput.counselings
      delete staffInput.defaultVehicle
      delete staffInput.defaultVehicle2
      delete staffInput.defaultVehicle3
      delete staffInput.drugTests
      delete staffInput.injuries
      delete staffInput.physicals
      delete staffInput.route
      delete staffInput.routeHelper
      delete staffInput.routeRescuer
      delete staffInput.scoreCards
      delete staffInput.messages
      delete staffInput.documents
      delete staffInput.cxFeedback
      delete staffInput.podQualities
      delete staffInput.mentionedInNotes
      delete staffInput.staffStatusHistory
      delete staffInput.mentor
      delete staffInput.accidents
      delete staffInput.uniforms
      delete staffInput.infractions
      delete staffInput.netrdadyneAlerts
      delete staffInput.kudos
      delete staffInput.routeRescuerStaff
      delete staffInput.replacedByStandbyByRoute
      delete staffInput.authorizedToDrive
      delete staffInput.labels
      delete staffInput.eocScores
      delete staffInput.messageReadStatus
      delete staffInput.rosteredAssociate
      delete staffInput.messagePreferencesHistory
      delete staffInput.properParkingSequences
      if (!staffInput.phone) delete staffInput.phone
      if (!staffInput.transporterId) delete staffInput.transporterId
      
      
      return staffInput
    },

    /**
     * Cleans GraphQL input for updating a tenant
     * @param {rescuer} string 
     * */
     cleanRescuerInput: function (rescuer) {
      delete rescuer.staff
      delete rescuer.createdAt
      delete rescuer.route
      delete rescuer.updated
      delete rescuer.updatedAt
      return rescuer
    },

    /**
     * Cleans GraphQL input for updating a tenant
     * @param {helper} string 
     * */
     cleanHelperInput: function (helper) {
      delete helper.staff
      delete helper.createdAt
      delete helper.route
      delete helper.updated
      delete helper.updatedAt
      delete helper.createDaIssue
      delete helper.createCounseling
      return helper
    },

    /**
     * Cleans GraphQL input for updating a tenant
     * @param {tenantInput} string 
     * */
    cleanTenantInput: function (tenantInput) {
      delete tenantInput.users
      delete tenantInput.valueLists
      delete tenantInput.textractjobs
      delete tenantInput.dailyRoster
      delete tenantInput.companyScoreCardsByYearWeek
      delete tenantInput.messages
      delete tenantInput.waves
      return tenantInput
    },

    /**
     * Cleans GraphQL input for updating a user
     * @param {userInput} string 
     * */
     cleanUserInput: function (userInput) {
      delete userInput.permissionAccidentsString
      delete userInput.permissionCounselingsString
      delete userInput.permissionDocumentsString
      delete userInput.permissionDrugTestsString
      delete userInput.permissionFullAccessString
      delete userInput.permissionInjuriesString
      delete userInput.permissionLoginString
      delete userInput.cognitoUserStatus
      return userInput
    },

    /**
     * Cleans GraphQL input for updating a vehicle
     * @param {vehicleInput} string 
     * */
    cleanVehicleInput: function (vehicleInput) {
      delete vehicleInput.device
      delete vehicleInput.device2
      delete vehicleInput.maintenance
      delete vehicleInput.defaultStaff
      delete vehicleInput.defaultStaff2
      delete vehicleInput.defaultStaff3
      delete vehicleInput.route
      delete vehicleInput.replaceByRoute
      delete vehicleInput.documents
      delete vehicleInput.accidents
      delete vehicleInput.reminders
      delete vehicleInput.accidents
      delete vehicleInput.mentionedInNotes
      delete vehicleInput.maintenanceRecords
      delete vehicleInput.vehicleDamage
      delete vehicleInput.odometerReadings
      delete vehicleInput.upcomingMaintenance
      delete vehicleInput.lastMileageReading
      delete vehicleInput.replacedRoutes
      delete vehicleInput.vehicleStatusHistory
      delete vehicleInput.rosteredVehicleList
      delete vehicleInput.vehicleType
      delete vehicleInput.parkingSpace
      delete vehicleInput.labels
      delete vehicleInput.dailyLogs
      delete vehicleInput.company
      delete vehicleInput.label
      delete vehicleInput.lid
      delete vehicleInput.vehicleDevice2Id
      delete vehicleInput.description
      return vehicleInput
    },

    /**
     * Cleans any input from empties or null fields 
     * */
    cleanImportInput: function (Input) {
      Object.keys(Input).forEach((k) => !Input[k] && delete Input[k]);
    },

    cleanOptionCustomListInput: function (optionCustomListInput) {
      delete optionCustomListInput.edited
      delete optionCustomListInput.tempAdded
      delete optionCustomListInput.customLists
      delete optionCustomListInput.vehicles
      delete optionCustomListInput.associates
      delete optionCustomListInput.accidents
      delete optionCustomListInput.parkingSpace
      delete optionCustomListInput.routeParkingSpace
      delete optionCustomListInput.replaceByRouteParkingSpace
      delete optionCustomListInput.rosteredVehicleParkingSpace
      delete optionCustomListInput.counselings
      delete optionCustomListInput.issues
      delete optionCustomListInput.kudos
      delete optionCustomListInput.companies
      delete optionCustomListInput.routes
      delete optionCustomListInput.routeHelperStatus
      delete optionCustomListInput.replaceByRoutes
      delete optionCustomListInput.createdAt
      delete optionCustomListInput.updatedAt
      return optionCustomListInput
    },

    /**
     * Cleans GraphQL input for updating a Daily Roster
     * @param {dailyRosterInput} string 
     * */
    cleanDailyRosterInput: function (dailyRosterInput) {
      delete dailyRosterInput.notes
      delete dailyRosterInput.dailyLogs
      delete dailyRosterInput.attachment
      delete dailyRosterInput.tenant
      delete dailyRosterInput.route
      delete dailyRosterInput.replacedByStandbyByRoute
      delete dailyRosterInput.replaceByRoute
      return dailyRosterInput
    },

    sleepTimeOut(ms) {
      return new Promise((resolve) => {
          setTimeout(resolve, ms);
      });
    },
    
    cleanInput(str){
      if(!str){
        return null
      }
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
      
      // remove accents, swap ñ for n, etc
      const from = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
      const to = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
      for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
      
      str = str.replace(/[^a-z0-9 - #]/g, '') // remove invalid chars
      .replace(/\s+/g, '_') // collapse whitespace and replace by _
      .replace(/-+/g, '_'); // collapse dashes
      
      return str;
    },

    cleanName(str){
      console.log({"entered string": str})
      if(!str){
        return null
      }
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
      
      // remove accents, swap ñ for n, etc
      const from = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
      const to = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
      for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      console.log({"after loop string": str})
      
      str = str.replace(/[^a-z0-9 - #]/g, '') // remove invalid chars
      
      console.log({"returned string": str})
      return str;
    },
    convertUtcToLocal(timestamp){
      var timezone = this.getTenantTimeZone()
      var date = timestamp.split("T")[0]
      var localDate = moment(date).tz(timezone).format()
      return localDate
    },
    convertUtcToLocalFormat(timestamp){
      const timezone = this.getTenantTimeZone()
      const convertTimestamp = moment(timestamp).format("YYYY-MM-DD HH:mm")
      const localdateConvert = moment.tz(convertTimestamp, timezone).utc().format()
      const convertValue = new Date(localdateConvert)
      return convertValue
    },
    getTenantTimeCustomFormat(formatTime = "MM/DD/YYYY hh:mm:ss A"){
      const tenantTime = moment().tz(this.getTenantTimeZone());
      const convertValue= tenantTime.format(formatTime);
      return convertValue
    },
    getTenantTimeZone() {
      let tenantTimeZone = store.state.userInfo.tenant.timeZone
      if(!tenantTimeZone) {
        return 'America/Los_Angeles'
      }
      return tenantTimeZone
    },
    getDateDifferenceMinutesTenantTimezone(date, timeZone){
      if(!date) return
      const date_ = moment.tz(date, timeZone || this.getTenantTimeZone());
      const newDate = moment(date)
      const displacementSchedule = date_.utcOffset();
      const displacementScheduleHours = displacementSchedule / 60;
      const gmt = "GMT" + (displacementScheduleHours >= 0 ? "+" : "-") + ("0" + Math.abs(displacementScheduleHours)).slice(-2) + "00";
      const dateModified = newDate.format("ddd MMM DD YYYY HH:mm:ss") + " " + gmt;
      const dateFormat = new Date(dateModified).toISOString();
      return dateFormat
    },
    convertTimeTenantTimezone(time,format, notTimezone = null){
      const newTime = moment(time, format)
      const routeTime = (notTimezone) ? newTime : newTime.tz(this.getTenantTimeZone());
      return routeTime.format(format);
    },

    /**
     * Retrieves S3 URL of object key and downloads the linked file
     * @param {string} key S3 object key of file to download
     */
    downloadFile: async function(key){
      var documentUrl = await Storage.get(key)
      window.open(documentUrl, "_blank")
    },

    downloadFileWithContentType: async function(key){

      let contentType = ''

      if(key.includes('pdf')) {
        contentType = 'application/pdf'
      } else if(key.includes('png')) {
        contentType = 'image/png'
      } else if(key.includes('jpg')) {
        contentType = 'image/jpeg'
      } else if(key.includes('jpeg')) {
        contentType = 'image/jpeg'
      } else {
        contentType = 'text/html'
      }

      var documentUrl = await Storage.get(key, {
        contentType: contentType
      })
      window.open(documentUrl, "_blank")
    },

    async downloadBlob(s3Key, fileName=null) {
      const response = await Storage.get(s3Key, { download: true });

      const blob = response.Body;
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;

      if (fileName) {
        a.download = fileName;
      }

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();

      return a;
    },

    clearObjectURLs(objectURLs=[]) { 
      //IMPORTANT: Revokes all previously created URLs
      objectURLs.forEach(url => URL.revokeObjectURL(url))
      objectURLs = []
    },

    async loadCounselingTemplates() {
      try {
        let types = await this.api(getCounselingType, {input: {}})
        let enumValues = types.data.__type.enumValues
        let counselingTypes = enumValues.map(type => type.name)

        this.$store.commit("setCounselingTypes", counselingTypes) 
      } catch (error) {
        this.displayUserError(error)
      }
    },
    
    /**
     * Takes a array and search term and filters the arrays object values 
     * @param {array} array 
     * @param {search} string 
     * */
    filterArray: function(array = [], search = null, filters = [], deep = 2){
      if (array.length === 0 ) return array
      if( !search && filters.length === 0 ) return array 

      //Filter Array first
      var filteredArray = array
      filters.forEach((filter)=>{
        filteredArray = filteredArray.filter( data => {
          if(filter.key == 'vehicleType') return data[filter.key]?.id == filter.value
          if(filter.key == 'company') return data[filter.key].id == filter.value
          if(filter.key == 'labels') return data[filter.key].items.some(labelSub => labelSub.label.id === filter.value);
          return data[filter.key].toLowerCase() === filter.value.toLowerCase()
        })
      })

      const regexStr = this.ConvertAccentedCharToRegularChar(search).toLowerCase().replace(/['‘’"“”]/g, '')
        .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
        .replace(/\s+/g, "\\s*")
      const regex = new RegExp(regexStr, 'i');
      const filteredData = filteredArray.filter(item => {
        const flatObject = flattenObject(item,deep)
        for(const key in flatObject){
          if(typeof flatObject[key] === 'string'){
            const propValue = this.ConvertAccentedCharToRegularChar(flatObject[key]).replace(/['‘’"“”]/g, '')
            .replace(/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/g, "")
            .replace(/https:\/\/.*getobject/g, "")
            if(regex.test(propValue)){
              return true
            }
          }
        }
        return false
      })
      return filteredData;
    },

    ConvertAccentedCharToRegularChar(string){
      return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    /**
     * Converts a phone number to the format (xxx) xxx-xxxx
     * @param {phoneNumber} string 
     * */
    formatPhoneNumber: function (phoneNumber) {
      var cleaned = ('' + phoneNumber).replace(/\D/g, '')
      if(cleaned.length == 10){
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
      }
      else if(cleaned.length == 11){
        var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return '+' + match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4]
        }
      }
      return cleaned
    },

    formatTextColor(status){
      if(typeof status !== 'string'){
        return ''
      }
      return 'text-' + status.toLowerCase()
    },

     /**
     * Global function for loading array of data until next tokens are exhausted
     * @param {string} query GraphQL query to invoke
     * @param {{input: {group}}} input input variables for GraphQL query
     * @param {string} queryName name of GraphQL query being invoked
     */
    gLoadListAll: async (query, input, queryName) => {
      var nextToken = null
      var list = []
      query = setLimit(query, input)
      do{
          input.nextToken = nextToken
          var response
          try{
            response = await safeFunction(API.graphql)(graphqlOperation(query, cleanVars(input, query)))
          }
          catch(err){
            response = errorCatch(err)
            break
          }

          list = list.concat(response.data[queryName].items)
          nextToken = response.data[queryName].nextToken
      }while(nextToken)
      return list
    },

    recordCounter: async (query, input, queryName) => {
      let nextToken = null
      let counter = 0
      do {
        input.nextToken = nextToken
        //max limit
        input.limit = 1000
        let response
        try{
          response = await safeFunction(API.graphql)(graphqlOperation(query, input))
        }catch(err){
          response = errorCatch(err)
          break
        }
        counter = counter + response.data[queryName].scannedCount || 0
        nextToken = response.data[queryName].nextToken
      } while(nextToken)
      return counter
    },

    toTitleCase(value){
      return value.split(' ').map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()).join(' ')
    },
    toCamelCase(value){
      return value.split(' ').map(word => word.charAt(0).toUpperCase() + word.substr(1)).join(' ')
    },
    splitCamelCase(word) {
      const index = Array.from(word).findIndex((char, i) => i > 0 && char === char.toUpperCase());
      if (index !== -1) {
        const firstPart = word.substring(0, index);
        const secondPart = word.substring(index).toLowerCase();
        return `${firstPart} ${secondPart}`;
      }
      return word;
    },

    cleanStatus(status, type){
      if(status == '' || status == null || status == undefined || !status) return ''
      var lower = status.trim().toLowerCase()

      if(lower.includes("grounded")) return "Inactive - Grounded"
      else if(lower.includes("maintenance")) return "Inactive - Maintenance"
      else if(lower.includes("misc")) return "Inactive - Misc"
      else if(lower.includes("terminated")) return "Inactive - Terminated"
      else if(lower.includes("personal time/vacation")) return "Inactive - Personal Time/Vacation"
      else if (lower.includes("medical leave")) return "Inactive - Medical Leave"
      else if(lower.includes("inactive") && type =='da') return "Inactive - Misc"
      else if(lower.includes("inactive") && type !='da') return "Inactive"
      else if(lower.includes("active")) return "Active"
      else if(lower.includes("onboarding")) return "Onboarding"
      else return ""
    },

    /**
     * returns list of value list values that are currently active for provided list
     * @param {list} string list to filter
     * */
    valueListActivesOnly(list = []) {
      return list.filter(item=>!item.deleted && !item.hidden)
    },

    valueListActivesAndHiddenOnly(list) {
      return list.filter(item=>!item.deleted)
    },

    pluralize(value, text, pluralText){
      if(value == 1) return text
      return pluralText
    },

    printUserError,
    displayUserError(e, prefix = ''){
      var message = ''
      var title = "Error"
      var duration = 7000
      try{
        if(e.errors) message = e.errors[0].message
        else if(e.message) message = e.message
        else if(e.data) message = e.data.message
        else message = e
      }
      catch(syntaxError){
        message = e
      }
      if(message.includes("Record was updated by another user.")){
        var title = "Update Conflict"
        var duration = 0
      }
      printUserError(e)
      this.displayUserNotification({
        title: title,
        type: "error",
        message: prefix + message,
        duration: duration,
        customClass:'uil uil-exclamation-triangle'
      })
    },

    async displayUserNotification(opts){
      let response;
      if(opts?.type === "error"){
        response = await Auth.currentAuthenticatedUser().catch(error => {
            if(error === "not authenticated" || error === "No current user"){
              store.commit("setSessionInfo", null)
              store.commit("setUserInfo", null)
            }
        })
      }

      const updatedOptions = {
        ...opts,
        message: `<div style="overflow-wrap: anywhere" >${opts.message}</div>`,
        dangerouslyUseHTMLString: true,
      }
      const notif = this.$notify(updatedOptions)
      // fix Element UI z-index for intercom banner
      document.querySelector('div[role="alert"].el-notification').style.zIndex = 9999999999;
      return notif
    },

    /**
 * 
 * @param {json} opts : opts of message, type, title, duration, customClass
 * @param {string} viewName : name of the view where the notification is being displayed
 * @param {function} callback : function to be called when the button is clicked
 * @returns 
 */
async displayUserNotificationWithButton(opts, viewName, callback, must = false) {
  if (!this.currentButtonNotification) {
    this.currentButtonNotification = {};
  }else if (!must && this.currentButtonNotification && this.currentButtonNotification[viewName] == true) {
    return;
  }
  this.currentButtonNotification[viewName] = true;
  this.currentButtonNotification[`${viewName}-not-updated`] = true;
  let response;
  if (opts?.type === "error") {
    response = await Auth.currentAuthenticatedUser().catch(error => {
      if (error === "not authenticated" || error === "No current user") {
        store.commit("setSessionInfo", null);
        store.commit("setUserInfo", null);
        return { authError: true };
      }
    });
  }

  if (response?.authError) {
    return;
  }

  // Generate a unique ID for the button
  const uniqueButtonId = `clickNotificationButton-${this.generateUUID()}`;

  let updatedOptions = {
    ...opts,
    dangerouslyUseHTMLString: true,
    onClose: () => {
      this.currentButtonNotification[viewName] = false;
    }
  };
  updatedOptions["message"] = `<div style="overflow-wrap: anywhere">${opts.message} <button id="${uniqueButtonId}" style="color: #2b6cb0; text-decoration: underline;">Click here</button></div>`;
  this.$notify(updatedOptions);
  // fix Element UI z-index for intercom banner
  document.querySelector('div[role="alert"].el-notification').style.zIndex = 9999999999;

  this.$nextTick(() => {
    const button = document.getElementById(uniqueButtonId);
    if (button) {
      button.addEventListener('click', () => {
        if (typeof callback === 'function') {
          callback();
        } else {
          console.error("callback is not a function");
        }
        this.currentButtonNotification[viewName] = false;
        this.currentButtonNotification[`${viewName}-not-updated`] = false;
        this.$notify.closeAll();
      }, false);
    }
  });
},
    
    
    generateUUID() {
      return 'xxxxxxxx-xxxx-xx4x-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    },

    isUniqueInArray(value, key, array){
      var indexOf
      if(!key){
        indexOf = array.findIndex(arrayValue => arrayValue.toLowerCase() == value.toLowerCase())
      }
      else{
        indexOf = array.findIndex(arrayValue => arrayValue[key].toLowerCase() == value.toLowerCase())
      }

      if(indexOf == -1){
        return true
      }
      else{
        return false
      }
    },

    formatColumnsForExport(value){
      return value? `"${value.replaceAll('"',"'")}"` : ""
    },

    errorCloseModal(value){
      const errors = ['close','cancel']
      return errors.includes(value)
    },

    excludeSupportAdminFromArray(users, isSupport = false) {
      if(isSupport) return users
      return users.filter(user => (
        user.firstName !== "Support" &&
        user.lastName !== "Administrator")
      );
    },

    calculateCustomerStatus(accountPremiumStatus, trialExpDate){
      return setTenantStatus(accountPremiumStatus, trialExpDate) 
    },

    cleanQueryPayload(str) {
      return str.replace(/\u00a0|\t/g, ' ').replace(/  +/g, ' ').trim()
    },

    removeTZfromDate(date){
      return date.split('T')[0]
    },

    parsingFormatDate(date){
      const isValid = date ? moment(date).isValid() : null;
      if(!isValid) return null;
      const dateString = new Date(date).toISOString();
      const onlyDate = this.removeTZfromDate(dateString);
      return onlyDate;
    },

    multiParsingFormatDate(object, properties, propertiesGSI = []){
      if(propertiesGSI.length > 0){
        propertiesGSI.map((property) => {
          const isValid = object[property] ? moment(object[property]).isValid() : null;
          if(isValid){
            object[property] =  this.parsingFormatDate(object[property])
          }else{
            delete object[property];
          }
        }) 
      }

      if(properties.length > 0){
        properties.map((property) => {
          object[property] = this.parsingFormatDate(object[property]);
        }) 
      }

      return object
    },
    
    async loadCustomList() {
      try {
        let input = {
          group: this.$store.state.userInfo.tenant.group
        }
        const customLists = await this.gLoadListAll(customListsByGroup, input, 'customListsByGroup')
        customLists.map(item => {
          const items = item.options.items.sort((a,b) => parseInt(a.order) - parseInt(b.order))
          delete item.options.items
          item.options = items
          return item
        })
        this.$store.commit("setCustomList", customLists) 
      } catch (e) {
        this.displayUserError(e)
      }
    },

    customListEnabled(list=[], isCreationForm=true, showOptionId=null, multiSelect=[], hiddenSection=null){
      let filteredList = list.filter(item => item.isEnabled);
      if(hiddenSection){
        filteredList = filteredList.filter(item => !item.isHiddenForSections?.includes(hiddenSection));
      }
      if(isCreationForm) return filteredList;

      if(multiSelect.length){
        const findOptions = list.filter(item => multiSelect.includes(item.id));
        filteredList = filteredList.concat(findOptions.filter(option => !filteredList.some(item => item.id === option.id)));
        return filteredList.sort((a, b) => a.order - b.order);
      }

      const findOption = showOptionId ? list.find(item => item.id === showOptionId) : null;
      if (findOption && !filteredList.some(item => item.id === findOption.id)) {
        filteredList.push(findOption);
        filteredList.sort((a, b) => a.order - b.order);
      }
      return filteredList
    },

    optionDisabledMessage(option=''){
      return `The "${option}" option is disabled, please choose another option.`
    },

    intlFormatNumbers(number, options) {
      return new Intl.NumberFormat(userLocale, {...options}).format(number)
    },

    startSendingMessages() {
      this.displayUserNotification({
        title: 'Processing...',
        type: "info",
        duration: 5000,
        message: 'Hera is currently queuing your messages to be sent.'
      })
    },

    setPillCollor(color) {
      if (!color) return 'bg-blue-pill'
      return `bg-${color}-pill`
    },

    capitalizeText(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    commaSeperationNumber(value){
      if(!value) return '—'
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
    },

    getTimeDisplayFormat() {
      const formatter = new Intl.DateTimeFormat(undefined,{ hour: 'numeric' });
      const options = formatter.resolvedOptions();
      const is12Hour = options.hour12;
    
      return is12Hour ? 'hh:mm a' : 'HH:mm';
      },
    
    getFullPath(path) {
      return `${window.location.origin}${path}`
        }
}
Vue.mixin({
  methods: mixinMethods
})
function cleanVars(variables, query){
  //Remove created/updated timestamps from mutations.
  if( variables.input ){
    if(variables.input.createdAt && !query.includes('mutation UpdateMessage')){
      delete variables.input.createdAt
    }
    if(variables.input.updatedAt){
      delete variables.input.updatedAt
    }
    if(!variables.input.group && !query.includes("$input: Delete")){
      variables.input.group = store.state.userInfo.tenant.group
    }

    //Trim fields   
    const keys = Object.keys(variables.input);
    for (const key of keys) {
      //Added conditional key !== 'key' to avoid changing the s3 key
      if (typeof variables.input[key] === 'string' && key !== 'key') {
        variables.input[key] = variables.input[key].replace(/\u00a0|\t/g, ' ');
        variables.input[key] = variables.input[key].replace(/  +/g, ' ');
        variables.input[key] = variables.input[key].trim()
      }
    }
  }

  return variables
}

function flattenObject(ob,count = 1,deep) {
  var toReturn = {};
  if(count !== deep){
    for (var i in ob) {
        if (ob.firstName && ob.lastName) {
          ob.fullName = `${ob.firstName} ${ob.lastName}` 
        }
        if (!ob.hasOwnProperty(i)) continue;
        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i],count,deep);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;
                if(i === 'staff' && !flatObject['fullName']){
                  toReturn[i + '.' + 'fullName'] = `${flatObject['firstName']} ${flatObject['lastName']}`;
                }
                toReturn[i + '.' + x] = flatObject[x];
            }
            count++
        } else {
            toReturn[i] = ob[i];
        }
    }
  }
  return toReturn;
}

Date.prototype.getWeekNumber = function(){
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
};

export function getDateForWeekYear(week, year){
  // Use moment to calculate the start of the ISO week
  const ISOweekStart = moment().isoWeekYear(year).isoWeek(week).startOf('isoWeek');
  // Return the date in ISO format
  return ISOweekStart.toDate();
}

export function getMaxWeeksInYear(year) {
  const lastDay = new Date(year, 11, 31);
  const week = lastDay.getWeekNumber();
  return week === 1 ? 52 : week;
}

export function validateLastWeekYear(startDate){
  // Convert the start date into a moment object
  const startOfWeek = moment(startDate);
  // Add 7 days to the start of the week
  const endOfWeek = startOfWeek.clone().add(6, 'days');
  // Get the year of the end of the week
  const yearAtEndOfWeek = endOfWeek.isoWeekYear();
  // Return the year
  return yearAtEndOfWeek;
}

// Filters
Vue.filter('date', function (value) {
  if (!value) return '—'
  value = value.toString()
  var splitValue = value.split("T")
  splitValue = splitValue[0].split("-")
  return `${splitValue[1]}/${splitValue[2]}/${splitValue[0]}`
})

Vue.filter('date_timezone', function (date) {
  if (!date) return '—'
  const dateUtcTz = moment.utc(date).tz(mixinMethods.getTenantTimeZone())
  const dateFormat = dateUtcTz.format('MM/DD/YYYY')
  return dateFormat
})

Vue.filter('netradyneTimestampToDate', function (value) {
  if (!value) return '—'
  value = value.toString()
  var splitValue = value.split(" ")
  splitValue = splitValue[0].split("-")
  return `${splitValue[1]}/${splitValue[2]}/${splitValue[0]}`
})

Vue.filter('humanDate', function (value) {
  if (!value) return ''
  value = value.toString()
  var splitValue = value.split("T")
  splitValue = splitValue[0].split("-")
  return `${months[parseInt(splitValue[1]) - 1]} ${splitValue[2]}, ${splitValue[0]}`
})

Vue.filter('dateDay', function (value) {
  if (!value) return ''
  value = value.toString()
  var splitValue = value.split("T")
  splitValue = splitValue[0].split("-")
  if(splitValue[1] == undefined || splitValue[1] == null) return value
  return `${splitValue[1]}/${splitValue[2]}`
})

Vue.filter('importDate', function (value) {
  if (!value) return ''
  return moment(value).format('MM/DD/YYYY')
})

Vue.filter('importDateNoTZ', function (value) {
  if (!value) return ''
  value = value.split('T')[0]
  return moment(value).format('MM/DD/YYYY')
})

Vue.filter('dateTime', function (value) {
  if (!value) return '--:--'
  value = value.toString()
  var splitValue = value.split("T")
  splitValue = splitValue[1].split(".")
  return splitValue[0]
})

Vue.filter('timeZone', function (value) {
  if (!value) return ''
  return value.replace(/_/g, ' ')
})

Vue.filter('time', function (value) {
  if (!value) return '--:--'
  var date = new Date(value * 1000);
  var hours = date.getHours();
  var minutes = "0" + date.getMinutes();
  var formattedTime = hours + ':' + minutes.substr(-2)
  return formattedTime
})


Vue.filter('commaSepNum', function (value) {
  if(!value) return '—'
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
})

Vue.filter('from24HoursToTimeAMPM', function (value){
  if (!value) {
    return '—'; //  if time is null or empty
  }

  if (/am|pm/i.test(value)) {
    return value
  }

  let [hours, minutes] = value.split(':');
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  let period;
  if (hours === 0) {
      hours = '00'; // Fix 00 to 12
      period = 'AM';
  } else if (hours === 12) {
      period = 'PM';
  } else {
      if (hours > 12) {
          hours = (hours % 12).toString().padStart(2, '0'); // Convert format to 12 hours and add 0 lead is less than 10
          period = 'PM';
      } else {
          period = 'AM';
      }
  }

  return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;

})



Vue.filter('timeAMPM', function (value) {
  if (!value) return '--:--'
  var timeValue = null;
  var date = new Date(value);
  var hours = date.getHours();
  if (hours > 0 && hours <= 12) {
    timeValue= "" + hours;
  } else if (hours > 12) {
    timeValue= "" + (hours - 12);
  } else if (hours == 0) {
    timeValue= "12";
  }
  if(hours > 12){
    var abbrev = "PM"
  }
  else{
    var abbrev = "AM"
  }
  
  var minutes = "0" + date.getMinutes();
  var formattedTime = `${timeValue}:${minutes.substr(-2)} ${abbrev}`
  return formattedTime
})

Vue.filter('netradyneTimeAMPM', function (value) {
  if (!value) return '--:--'
  value = value.toString()
  var splitValue = value.split(" ")
  var splitTime = splitValue[1].split(":")
  var hours = splitTime[0]
  var minutes = splitValue[1]
  var timeValue = null;

  if (hours > 0 && hours <= 12) {
    timeValue= "" + hours;
  } else if (hours > 12) {
    timeValue= "" + (hours - 12);
  } else if (hours == 0) {
    timeValue= "12";
  }
  if(hours > 12){
    var abbrev = "PM"
  }
  else{
    var abbrev = "AM"
  }

  var formattedTime = `${timeValue}:${minutes.substr(-2)} ${abbrev}`
  return formattedTime
})

Vue.filter('phone', function (value) {



  if (!value) return '—'

  var cleaned = ('' + value).replace(/\D/g, '')
  if(cleaned.length == 10){
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
  }
  else if(cleaned.length == 11){
    var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '+' + match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4]
    }
  }
  return cleaned

  // return value.replace(/[^0-9]/g, '')
  //             .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.filter('text', function (value) {
  if (!value && value !== 0) return '—'
  if (!value && value !== 0 || value === "C0ming S00n") return '—'
  return value
});

Vue.filter('textractText', function (value) {
  if (!value || value === "C0ming S00n" || value === "S00n") return 'Not Provided'
  return value
});

Vue.filter('textractNum', function (value) {
  if (!value) return '—'
  return value.replace(/(o|O)/g, 0)
});


Vue.filter('condensedPercent', function (value) {
  if (!value) return '—'
  value = String(value)
  var split = value.split(".")
  var whole = split[0]
  var decimal = split[1]
  if(!decimal) return value
  var zeroDec = decimal.replace(/[0]/g, '') === "%"
  return zeroDec ? `${whole}%` : value
});

Vue.filter('commaSeparated', function(array) {
  if(!array || array.length == 0) return '—'
  return array.join(', ')
})

Vue.filter('mileage', function(value){
  if(value == 0) return 0
  if(!value) return '—'
  if(value < 0 ){
    var negativeValue = true
  }
  var cleaned = ('' + value).replace(/\D/g, '')
  var mileage = cleaned.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  if(negativeValue){
    var mileage = "-" + mileage
  }

  return mileage
})

Vue.filter('currencyUS', function (value) {
  if (value == NaN || value == "NaN") return '—'
  if (value == null || value == undefined) return value
  value = parseFloat(value).toFixed(2)
  var split = String(value).split(".")
  var whole = split[0]
  var decimal = split[1]
  if(decimal == null) decimal = '00'
  return `$${whole}.${decimal}`
});

Vue.filter('month', function (value) {
  if (!value) return value
  return months[value]
});


Vue.filter('text-color', function (value) {  
  if (!value) return ''
  return 'text-' + value.toLowerCase()
})

Vue.filter('float', function (value, params) {
  if(value === null || value === undefined) return '—';
  const kind = params?.kind ?? "float";
  const decimals = params?.decimals ?? 2;

  if(kind === "percent") return `${parseFloat(value).toFixed(decimals)}%`;
  if(kind === "float") return `${parseFloat(value).toFixed(decimals)}`;
  
  return "incompatible kind";
})

Vue.filter('formatArray', function(value) {
  if (!Array.isArray(value)) {
    return value;
  }
  if (value.length === 0) {
    return '—'
  }
  return value.join(', ');
})
/*
  [DEVELOPER] Only use in template of vue files
  "value" param require de type key and date key
  
  Views in which it is used: 
  - Work Anniversaries in Tasks & Reports
  - In Company Info Card of Associate Management
  
  @returns {string} DD/MM/YYYY/ (x years)
*/
Vue.filter('anniversaryDateFormat', function (value) {
  if (typeof value.type == 'undefined') return '—'
  if (typeof value.date == 'undefined') return '—'
  return `${Vue.options.filters.intlFormatDate(value.date)} (${value.type})`
})

/*
  [DEVELOPER] Only use in template of vue files

  Formats a date value according to the user's localization settings (userLocale).
  If no value is provided, it returns a dash ('-').

  @param {string|number|Date} value - The date value to be formatted. It can be a string, timestamp, or a Date object.
  @param {Object} [options] - Optional. Object with options to customize the date format. 
                            - Example of options: { year: 'numeric', month: 'long', day: 'numeric' } 
  
  @returns {string} - The formatted date (DD/MM/YYYY) according to the user's localization and the provided options,
                      or '-' if there is no valid value.

  Note: If options are provided, the tenant's timezone from store.state.userInfo.tenant.timeZone will also be applied.
*/
Vue.filter('intlFormatDate', function(value, options) {
  if (!value) return '-'

  if (options !== undefined) {
    return new Intl.DateTimeFormat(
      userLocale, 
      { 
        timeZone: store.state.userInfo.tenant.timeZone, 
        ...options
      }
    )
    .format( new Date(value) )
  }

  return new Intl.DateTimeFormat(userLocale).format( new Date(value) )
});

/**
 * Formats a date value into the "MM/DD/YYYY h:mm A" format, with an option to customize the separator 
 * between the date and time.
 * 
 * @param {string|number|Date} date - The date value to be formatted. It can be a string, timestamp, or a Date object.
 * @param {string} [customSeparator=', '] - Optional. Defines the text that separates the date and time in the output.
 *     - Default: ', ' (e.g., "11/26/2024, 7:47 AM").
 *     - Custom: Replace with any desired text (e.g., "11/26/2024 - 7:47 AM" if customSeparator is ' - ').
 *     - Empty string: Removes the separator entirely (e.g., "11/26/20247:47 AM").
 * 
 * @returns {string} - The formatted date (MM/DD/YYYY h:mm A) with the custom separator, or '-' if the date value is invalid.
 * 
 * Note: 
 * - Relies on the tenant's timezone from `store.state.userInfo.tenant.timeZone`.
 * - Uses the `intlFormatDate` filter for base formatting.
 * - The default behavior mimics the previous functionality (uses a comma by default).
 * 
 * Example usage:
 * Vue.filter('intlFormatDate_MMDDYYYY_hmmA')(new Date(), ', ');  // "11/26/2024, 7:47 AM" (Default)
 * Vue.filter('intlFormatDate_MMDDYYYY_hmmA')(new Date(), ' - '); // "11/26/2024 - 7:47 AM"
 * Vue.filter('intlFormatDate_MMDDYYYY_hmmA')(new Date(), ' at ');    // "11/26/2024 at 7:47 AM"
 */
Vue.filter('intlFormatDate_MMDDYYYY_hmmA', function(date, customSeparator) {
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }
  const value = Vue.options.filters.intlFormatDate(date, options)
  if(customSeparator){
    return value.replace(', ', customSeparator)
  }
  return value
});

//Setup papa parse (this.$papa)
import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)

//Setup vue mask
import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.config.productionTip = false

export const i18n = new VueI18n({
  locale: "en", // set locale
  messages: { en }, // set locale messages
});

Vue.use(breakpointsPlugin)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
