import {
  vehicleFragment,
  associateFragment,
  deviceFragment,
  userFragment,
  userNotificationFragment,
  optionsCustomListsStaffFragment,
  infractionFragment,
  kudoFragment,
  tenantFragment,
  routeFragment,
  replaceByRouteFragment,
  dailyRosterFragment,
  dailyLogFragment,
  accidentFragment,
  messageFragment,
  messageReadStatusFragment,
  noteFragment,
  resourceUsageFragment,
  companyNotificationFragment,
  rosterChecklistSubjectItemFragment
} from './fragments'
// Vehicle Subscription Events
export const onCreateVehicleByGroup = vehicleFragment + /* GraphQL */ `
  subscription OnCreateVehicleByGroup($group: String!) {
    onCreateVehicleByGroup(group: $group) {
      ...vehicleFragment
    }
  }
`;

export const onUpdateVehicleByGroup = vehicleFragment + /* GraphQL */ `
  subscription OnUpdateVehicleByGroup($group: String!) {
    onUpdateVehicleByGroup(group: $group) {
      ...vehicleFragment
    }
  }
`;

export const onDeleteVehicleByGroup = vehicleFragment + /* GraphQL */ `
  subscription OnDeleteVehicleByGroup($group: String!) {
    onDeleteVehicleByGroup(group: $group) {
      ...vehicleFragment
    }
  }
`;

// Staff Subscription Events
export const onCreateStaffByGroup = associateFragment + /* GraphQL */ `
  subscription OnCreateStaffByGroup($group: String!) {
    onCreateStaffByGroup(group: $group) {
      ...associateFragment
    }
  }
`;

export const onUpdateStaffByGroup = associateFragment + /* GraphQL */ `
  subscription OnUpdateStaffByGroup($group: String!) {
    onUpdateStaffByGroup(group: $group) {
      ...associateFragment
    }
  }
`;

export const onDeleteStaffByGroup = associateFragment + /* GraphQL */ `
  subscription OnDeleteStaffByGroup($group: String!) {
    onDeleteStaffByGroup(group: $group) {
      ...associateFragment
    }
  }
`;

// Device Subscription Events
export const onCreateDeviceByGroup = deviceFragment + /* GraphQL */ `
  subscription OnCreateDeviceByGroup($group: String!) {
    onCreateDeviceByGroup(group: $group) {
      ...deviceFragment
    }
  }
`;

export const onUpdateDeviceByGroup = deviceFragment + /* GraphQL */ `
  subscription OnUpdateDeviceByGroup($group: String!) {
    onUpdateDeviceByGroup(group: $group) {
      ...deviceFragment
    }
  }
`;

export const onDeleteDeviceByGroup = deviceFragment + /* GraphQL */ `
  subscription OnDeleteDeviceByGroup($group: String!) {
    onDeleteDeviceByGroup(group: $group) {
      ...deviceFragment
    }
  }
`;

// User Subscription Events
export const onCreateUserByTenant = userFragment + /* GraphQL */ `
  subscription OnCreateUserByTenant($userTenantId: ID!) {
    onCreateUserByTenant(userTenantId: $userTenantId) {
      ...userFragment
    }
  }
`;

export const onUpdateUserByTenant = userFragment + /* GraphQL */ `
  subscription OnUpdateUserByTenant($userTenantId: ID!) {
    onUpdateUserByTenant(userTenantId: $userTenantId) {
      ...userFragment
    }
  }
`;

export const onDeleteUserByTenant = userFragment + /* GraphQL */ `
  subscription OnDeleteUserByTenant($userTenantId: ID!) {
    onDeleteUserByTenant(userTenantId: $userTenantId) {
      ...userFragment
    }
  }
`;


//UserNotification subscriptions
export const onCreateUserNotificationByUserId = userNotificationFragment + /* GraphQL */ `
  subscription OnCreateUserNotificationByUserId($userId: String!) {
    onCreateUserNotificationByUserId(userId: $userId) {
      ...userNotificationFragment
    }
  }
`;

// OptionsCustomListsStaff Subscription Events
export const onCreateOptionsCustomListsStaffByGroup = optionsCustomListsStaffFragment + /* GraphQL */ `
  subscription OnCreateOptionsCustomListsStaffByGroup($group: String!) {
    onCreateOptionsCustomListsStaffByGroup(group: $group) {
      ...optionsCustomListsStaffFragment
    }
  }
`;

export const onDeleteOptionsCustomListsStaffByGroup = optionsCustomListsStaffFragment + /* GraphQL */ `
  subscription OnDeleteOptionsCustomListsStaffByGroup($group: String!) {
    onDeleteOptionsCustomListsStaffByGroup(group: $group) {
      ...optionsCustomListsStaffFragment
    }
  }
`;

export const onUpdateTenantById = tenantFragment + /* GraphQL */ `
  subscription OnUpdateTenantById($id: ID!) {
    onUpdateTenantById(id: $id) {
      ...tenantFragment
    }
  }
`;

export const onCreateInfractionByGroup = infractionFragment + /* GraphQL */ `
  subscription OnCreateInfractionByGroup($group: String!) {
    onCreateInfractionByGroup(group: $group) {
      ...infractionFragment
    }
  }
`;

export const onUpdateInfractionByGroup = infractionFragment + /* GraphQL */ `
  subscription OnUpdateInfractionByGroup($group: String!) {
    onUpdateInfractionByGroup(group: $group) {
      ...infractionFragment
    }
  }
`;

export const onDeleteInfractionByGroup = infractionFragment + /* GraphQL */ `
  subscription OnDeleteInfractionByGroup($group: String!) {
    onDeleteInfractionByGroup(group: $group) {
      ...infractionFragment
    }
  }
`;

export const onCreateKudoByGroup = kudoFragment + /* GraphQL */ `
  subscription OnCreateKudoByGroup($group: String!) {
    onCreateKudoByGroup(group: $group) {
      ...kudoFragment
    }
  }
`;

export const onUpdateKudoByGroup = kudoFragment + /* GraphQL */ `
  subscription OnUpdateKudoByGroup($group: String!) {
    onUpdateKudoByGroup(group: $group) {
      ...kudoFragment
    }
  }
`;

export const onDeleteKudoByGroup = kudoFragment + /* GraphQL */ `
  subscription OnDeleteKudoByGroup($group: String!) {
    onDeleteKudoByGroup(group: $group) {
      ...kudoFragment
    }
  }
`;



// DailyRoster Subscription Events
export const onCreateDailyRosterByGroup = dailyRosterFragment + /* GraphQL */ `
  subscription OnCreateDailyRosterByGroup($group: String!) {
    onCreateDailyRosterByGroup(group: $group) {
      ...dailyRosterFragment
    }
  }
`;

export const onUpdateDailyRosterByGroup = dailyRosterFragment + /* GraphQL */ `
  subscription OnUpdateDailyRosterByGroup($group: String!) {
    onUpdateDailyRosterByGroup(group: $group) {
      ...dailyRosterFragment
    }
  }
`;

// Route Subscription Events
export const onCreateRouteByGroup = routeFragment + /* GraphQL */ `
  subscription OnCreateRouteByGroup($group: String!) {
    onCreateRouteByGroup(group: $group) {
      ...routeFragment
    }
  }
`;

export const onUpdateRouteByGroup = routeFragment + /* GraphQL */ `
  subscription OnUpdateRouteByGroup($group: String!) {
    onUpdateRouteByGroup(group: $group) {
      ...routeFragment
    }
  }
`;

export const onDeleteRouteByGroup = routeFragment + /* GraphQL */ `
  subscription OnDeleteRouteByGroup($group: String!) {
    onDeleteRouteByGroup(group: $group) {
      ...routeFragment
    }
  }
`;

// ReplaceByRoute Subscription Events
export const onCreateReplaceByRouteByGroup = replaceByRouteFragment + /* GraphQL */ `
  subscription OnCreateReplaceByRouteByGroup($group: String!) {
    onCreateReplaceByRouteByGroup(group: $group) {
      ...replaceByRouteFragment
    }
  }
`;

export const onUpdateReplaceByRouteByGroup = replaceByRouteFragment + /* GraphQL */ `
  subscription OnUpdateReplaceByRouteByGroup($group: String!) {
    onUpdateReplaceByRouteByGroup(group: $group) {
      ...replaceByRouteFragment
    }
  }
`;

export const onDeleteReplaceByRouteByGroup = replaceByRouteFragment + /* GraphQL */ `
  subscription OnDeleteReplaceByRouteByGroup($group: String!) {
    onDeleteReplaceByRouteByGroup(group: $group) {
      ...replaceByRouteFragment
    }
  }
`;

// DailyLog Subscription Events
export const onCreateDailyLogByGroup = dailyLogFragment + /* GraphQL */ `
  subscription OnCreateDailyLogByGroup($group: String!) {
    onCreateDailyLogByGroup(group: $group) {
      ...dailyLogFragment
    }
  }
`;

export const onUpdateDailyLogByGroup = dailyLogFragment + /* GraphQL */ `
  subscription OnUpdateDailyLogByGroup($group: String!) {
    onUpdateDailyLogByGroup(group: $group) {
      ...dailyLogFragment
    }
  }
`;

export const onDeleteDailyLogByGroup = dailyLogFragment + /* GraphQL */ `
  subscription OnDeleteDailyLogByGroup($group: String!) {
    onDeleteDailyLogByGroup(group: $group) {
      ...dailyLogFragment
    }
  }
`;

// Accident Subscription Events
export const onCreateAccidentByGroup = accidentFragment + /* GraphQL */ `
  subscription OnCreateAccidentByGroup($group: String!) {
    onCreateAccidentByGroup(group: $group) {
      ...accidentFragment
    }
  }
`;

export const onUpdateAccidentByGroup = accidentFragment + /* GraphQL */ `
  subscription OnUpdateAccidentByGroup($group: String!) {
    onUpdateAccidentByGroup(group: $group) {
      ...accidentFragment
    }
  }
`;

export const onDeleteAccidentByGroup = accidentFragment + /* GraphQL */ `
  subscription OnDeleteAccidentByGroup($group: String!) {
    onDeleteAccidentByGroup(group: $group) {
      ...accidentFragment
    }
  }
`;

//Message subscriptions
export const onCreateMessageByGroup = messageFragment + /* GraphQL */ `
  subscription OnCreateMessageByGroup($group: String!) {
    onCreateMessageByGroup(group: $group) {
      ...messageFragment
    }
  }
`;
export const onUpdateMessageByGroup = messageFragment + /* GraphQL */ `
  subscription OnUpdateMessageByGroup($group: String!) {
    onUpdateMessageByGroup(group: $group) {
      ...messageFragment
    }
  }
`;

//MessageReadStatus subscriptions
export const onCreateMessageReadStatusByGroup = messageReadStatusFragment + /* GraphQL */ `
  subscription OnCreateMessageReadStatusByGroup($group: String!) {
    onCreateMessageReadStatusByGroup(group: $group) {
      ...messageReadStatusFragment
    }
  }
`;
export const onUpdateMessageReadStatusByGroup = messageReadStatusFragment + /* GraphQL */ `
  subscription OnUpdateMessageReadStatusByGroup($group: String!) {
    onUpdateMessageReadStatusByGroup(group: $group) {
      ...messageReadStatusFragment
    }
  }
`;

// Note Subscription Events
export const onCreateNoteByGroup = noteFragment + /* GraphQL */ `
  subscription onCreateNoteByGroup($group: String!) {
    onCreateNoteByGroup(group: $group) {
      ...noteFragment
    }
  }
`;

export const onUpdateNoteByGroup = noteFragment + /* GraphQL */ `
  subscription onUpdateNoteByGroup($group: String!) {
    onUpdateNoteByGroup(group: $group) {
      ...noteFragment
    }
  }
`;

// Resource Usage Subscription Events
export const onCreateResourceUsageByGroup = resourceUsageFragment + /* GraphQL */ `
  subscription onCreateResourceUsageByGroup($group: String!) {
    onCreateResourceUsageByGroup(group: $group) {
      ...resourceUsageFragment
    }
  }
`;

export const onUpdateResourceUsageByGroup = resourceUsageFragment + /* GraphQL */ `
  subscription onUpdateResourceUsageByGroup($group: String!) {
    onUpdateResourceUsageByGroup(group: $group) {
      ...resourceUsageFragment
    }
  }
`;

export const onDeleteResourceUsageByGroup = resourceUsageFragment + /* GraphQL */ `
  subscription onDeleteResourceUsageByGroup($group: String!) {
    onDeleteResourceUsageByGroup(group: $group) {
      ...resourceUsageFragment
    }
  }
`;
export const onCreateCompanyNotificationByGroup =companyNotificationFragment + /* GraphQL */ `
subscription OnCreateCompanyNotificationByGroup($group: String!) {
  onCreateCompanyNotificationByGroup(group: $group) {
    ...companyNotificationFragment
  }
}
`;

// RosterChecklistSubjectItem Subscription Events
export const onCreateRosterChecklistSubjectItemByGroup = rosterChecklistSubjectItemFragment + /* GraphQL */ `
  subscription OnCreateRosterChecklistSubjectItemByGroup($group: String!) {
    onCreateRosterChecklistSubjectItemByGroup(group: $group) {
      ...rosterChecklistSubjectItemFragment
    }
  }
`;

export const onUpdateRosterChecklistSubjectItemByGroup = rosterChecklistSubjectItemFragment + /* GraphQL */ `
  subscription OnUpdateRosterChecklistSubjectItemByGroup($group: String!) {
    onUpdateRosterChecklistSubjectItemByGroup(group: $group) {
      ...rosterChecklistSubjectItemFragment
    }
  }
`;

export const onDeleteRosterChecklistSubjectItemByGroup = rosterChecklistSubjectItemFragment + /* GraphQL */ `
  subscription OnDeleteRosterChecklistSubjectItemByGroup($group: String!) {
    onDeleteRosterChecklistSubjectItemByGroup(group: $group) {
      ...rosterChecklistSubjectItemFragment
    }
  }
`;