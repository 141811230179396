
export const accidentByGroup = /* GraphQL */ `
query AccidentByGroup(
  $group: String
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAccidentFilterInput
  $limit: Int
  $nextToken: String
) {
  accidentByGroup(
    group: $group
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      notes
      policeDepartment
      policeOfficerName
      policeReportNumber
      vehicleHistoryType
      staffId
      vehicleId
      createdAt
      updatedAt
      verifiedBy {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        defaultDevice
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        photo
        onboardingPinned
        receiveTextMessages
        receiveEmailMessages
        createdAt
        updatedAt
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        photo
        onboardingPinned
        receiveTextMessages
        receiveEmailMessages
        createdAt
        updatedAt
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        company {
          id
          option
        }
        parkingSpace
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;
export const getAccident = /* GraphQL */ `
  query GetAccident($id: ID!) {
    getAccident(id: $id) {
      id
      group
      atFault
      drugTestDate
      drugTestResult
      accidentDate
      accidentType
      associateStatement
      witnessName
      witnessStatement
      address
      addressCity
      addressState
      addressZip
      fileName
      verifiedDate
      optionCustomList {
        id
        option
      }
      notes
      insuranceClaimNumber
      policeDepartment
      policeOfficerName
      policeReportNumber
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveEmailMessages
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        preferredDaysOff
        createdAt
        updatedAt
      }
      verifiedBy {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
      }
      vehicle {
        id
        group
        name
        vehicle
        type
        state
        licensePlateExp
        dateStart
        dateEnd
        ownership
        provider
        otherProvider
        category
        vin
        make
        model
        year
        rentalAgreementNumber
        images
        rentalContracts
        accidentReports
        licensePlate
        mileage
        gasCard
        status
        company {
          id
          option
        }
        notes
        lastOdometerReadingDate
        overTenThousandPounds
        customDeliveryVan
        tollPassId
        createdAt
        updatedAt
      }
    }
  }
`;

export const listAccidents = /* GraphQL */ `
  query ListAccidents(
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        address
        addressCity
        addressState
        addressZip
        verifiedBy
        notes
        updatedAt
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          company {
            id
            option
          }
          parkingSpace
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          phone
          email
          keyFocusArea
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          photo
          onboardingPinned
          receiveTextMessages
          receiveEmailMessages
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const staffsByGroup = /* GraphQL */ `
query StaffsByGroup(
  $group: String
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroup(
    group: $group
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      transporterId
      firstName
      lastName
      phone
      keyFocusArea
      status
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const vehiclesByGroup = /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        defaultStaff {
          nextToken
        }
        defaultStaff2 {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const accidentsByStaffAndHistoryType = /* GraphQL */ `
  query AccidentsByStaffAndHistoryType(
    $staffId: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByStaffByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByStaffAndHistoryType(
      staffId: $staffId
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        group
        id
        accidentDate
        accidentType
        drugTestDate
        drugTestResult
        vehicleHistoryType
        damage
        damageSeverity
        notes
        updatedAt
        staff {
          id
          firstName
          lastName
        }
        verifiedBy {
          id
          firstName
          lastName
        }
        vehicle {
          id
          name
        }
        optionCustomList {
          id
          option
        }
      }
      nextToken
    }
  }
`;

export const accidentsByGroupAndHistoryType = /* GraphQL */ `
  query AccidentsByGroupAndHistoryType(
    $group: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByGroupByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByGroupAndHistoryType(
      group: $group
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        accidentDate
        accidentType
        notes
        staffId
        vehicleId
        vehicleHistoryType
        damage
        damageSeverity
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
        }
        verifiedBy {
          id
          group
          cognitoSub
          firstName
          lastName
        }
        vehicle {
          id
          group
          name
          vehicle
        }
        optionCustomList {
          id
          option
        }
        incidentDocuments {
          items {
            id
            key
            name
          }
          nextToken
        }
        damageVehicle {
          id
          group
          name
        }
        vehicleDamageImages {
          items {
            id
            key
            name
          }
          nextToken
        }
        maintenanceDocuments {
          items {
            id
            key
            name
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const accidentsByStaff = /* GraphQL */ `
  query AccidentsByStaff(
    $staffId: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByStaff(
      staffId: $staffId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        group
        id
        accidentDate
        accidentType
        drugTestDate
        drugTestResult
        vehicleHistoryType
        updatedAt
        staff {
          id
          firstName
          lastName
        }
        verifiedBy {
          id
          firstName
          lastName
        }
        vehicle {
          id
          name
        }
      }
      nextToken
    }
  }
`;


export const accidentsByVehicle = /* GraphQL */ `
  query AccidentsByVehicle(
    $vehicleId: String
    $accidentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByVehicle(
      vehicleId: $vehicleId
      accidentDate: $accidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        atFault
        drugTestDate
        drugTestResult
        accidentDate
        accidentType
        address
        addressCity
        addressState
        addressZip
        fileName
        verifiedDate
        optionCustomList {
          id
        }
        notes
        insuranceClaimNumber
        policeDepartment
        policeOfficerName
        policeReportNumber
        staffId
        vehicleId
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        staff {
          firstName
          lastName
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          company {
            id
            option
          }
          notes
          lastOdometerReadingDate
          overTenThousandPounds
          customDeliveryVan
          tollPassId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      createdAt
      companyName
      nameLog
      numberOfSeats
      logo
      accidentReport
      workmansComp
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      group
      originationNumber
      timeZone
      trialExpDate
      accountPremiumStatus
      accountCanceledReason
      notes
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      payOutstandingByDate
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      automatedCoachingSendTime
      coachingCustomMessage
      messageServiceProvider
      OriginationNumberOrderId
      OriginationNumberStatus
      customerStatus
      coachingFicoThresholdIssue
      coachingFicoThreshold
      coachingFicoThresholdPR
      coachingFicoThresholdKudo
      coachingFicoIssue
      coachingFicoCO
      coachingFicoPR
      coachingFicoKudo
      coachingDcrThresholdIssue
      coachingDcrThreshold
      coachingDcrThresholdPR
      coachingDcrThresholdKudo
      coachingDcrIssue
      coachingDcrCO
      coachingDcrPR
      coachingDcrKudo
      coachingDarThresholdIssue
      coachingDarThreshold
      coachingDarThresholdPR
      coachingDarThresholdKudo
      coachingDarIssue
      coachingDarCO
      coachingDarPR
      coachingDarKudo
      coachingDnrThresholdIssue
      coachingDnrThreshold
      coachingDnrThresholdPR
      coachingDnrThresholdKudo
      coachingDnrIssue
      coachingDnrCO
      coachingDnrPR
      coachingDnrKudo
      coachingSwcPodThresholdIssue
      coachingSwcPodThreshold
      coachingSwcPodThresholdKudo
      coachingSwcPodThresholdPR
      coachingSwcPodIssue
      coachingSwcPodCO
      coachingSwcPodPR
      coachingSwcPodKudo
      coachingSwcCcThresholdIssue
      coachingSwcCcThreshold
      coachingSwcCcThresholdKudo
      coachingSwcCcThresholdPR
      coachingSwcCcIssue
      coachingSwcCcCO
      coachingSwcCcPR
      coachingSwcCcKudo
      coachingSwcScThresholdIssue
      coachingSwcScThreshold
      coachingSwcScThresholdKudo
      coachingSwcScThresholdPR
      coachingSwcScIssue
      coachingSwcScCO
      coachingSwcScPR
      coachingSwcScKudo
      coachingSwcAdThresholdIssue
      coachingSwcAdThreshold
      coachingSwcAdThresholdKudo
      coachingSwcAdThresholdPR
      coachingSwcAdIssue
      coachingSwcAdCO
      coachingSwcAdPR
      coachingSwcAdKudo
      coachingSeatbeltOffThresholdIssue
      coachingSeatbeltOffThreshold
      coachingSeatbeltOffThresholdPR
      coachingSeatbeltOffThresholdKudo
      coachingSeatbeltOffIssue
      coachingSeatbeltOffCO
      coachingSeatbeltOffPR
      coachingSeatbeltOffKudo
      coachingSpeedingEventThresholdIssue
      coachingSpeedingEventThreshold
      coachingSpeedingEventThresholdPR
      coachingSpeedingEventThresholdKudo
      coachingSpeedingEventIssue
      coachingSpeedingEventCO
      coachingSpeedingEventPR
      coachingSpeedingEventKudo
      coachingDistractionsRateThresholdIssue
      coachingDistractionsRateThreshold
      coachingDistractionsRateThresholdPR
      coachingDistractionsRateThresholdKudo
      coachingDistractionsRateIssue
      coachingDistractionsRateCO
      coachingDistractionsRatePR
      coachingDistractionsRateKudo
      coachingFollowingDistanceRateThresholdIssue
      coachingFollowingDistanceRateThreshold
      coachingFollowingDistanceRateThresholdPR
      coachingFollowingDistanceRateThresholdKudo
      coachingFollowingDistanceRateIssue
      coachingFollowingDistanceRateCO
      coachingFollowingDistanceRatePR
      coachingFollowingDistanceRateKudo
      coachingSignSignalViolationsRateThresholdIssue
      coachingSignSignalViolationsRateThreshold
      coachingSignSignalViolationsRateThresholdPR
      coachingSignSignalViolationsRateThresholdKudo
      coachingSignSignalViolationsRateIssue
      coachingSignSignalViolationsRateCO
      coachingSignSignalViolationsRatePR
      coachingSignSignalViolationsRateKudo
      coachingOverallTierThresholdIssue
      coachingOverallTierThresholdKudo
      coachingOverallTierIssue
      coachingOverallTierKudo
      coachingOverallTierRatingIssue
      coachingOverallTierRatingKudo
      coachingConsecutiveTierThresholdIssue
      coachingConsecutiveTierThresholdKudo
      coachingConsecutiveTierIssue
      coachingConsecutiveTierKudo
      coachingConsecutiveTierRatingIssue
      coachingConsecutiveTierRatingKudo
      coachingPositiveFeedbackThresholdIssue
      coachingPositiveFeedbackThreshold
      coachingPositiveFeedbackThresholdPR
      coachingPositiveFeedbackThresholdKudo
      coachingPositiveFeedbackIssue
      coachingPositiveFeedbackCO
      coachingPositiveFeedbackPR
      coachingPositiveFeedbackKudo
      coachingDailyFicoThresholdIssue
      coachingDailyFicoThreshold
      coachingDailyFicoThresholdPR
      coachingDailyFicoThresholdKudo
      coachingDailyFicoIssue
      coachingDailyFicoCO
      coachingDailyFicoPR
      coachingDailyFicoKudo
      coachingSeatbeltThresholdIssue
      coachingSeatbeltThreshold
      coachingSeatbeltThresholdPR
      coachingSeatbeltThresholdKudo
      coachingSeatbeltIssue
      coachingSeatbeltCO
      coachingSeatbeltPR
      coachingSeatbeltKudo
      coachingSseIssue
      coachingSseCO
      coachingSsePR
      coachingSseKudo
      coachingSseThresholdIssue
      coachingSseThreshold
      coachingSseThresholdPR
      coachingSseThresholdKudo
      coachingDvcrsIssue
      coachingDvcrsCO
      coachingDvcrsPR
      coachingHarshBrakingRateThresholdIssue
      coachingHarshBrakingRateThreshold
      coachingHarshBrakingRateThresholdPR
      coachingHarshBrakingRateThresholdKudo
      coachingHarshBrakingRateIssue
      coachingHarshBrakingRateCO
      coachingHarshBrakingRatePR
      coachingHarshBrakingRateKudo
      coachingDaTierThresholdIssue
      coachingDaTierThresholdKudo
      coachingDaTierIssue
      coachingDaTierKudo
      coachingDaTierPR
      coachingDaTierCO
      coachingDaTierRatingIssue
      coachingDaTierRatingCO
      coachingDaTierRatingPR
      coachingDaTierRatingKudo
      coachingCdfScoreThresholdIssue
      coachingCdfScoreThreshold
      coachingCdfScoreThresholdPR
      coachingCdfScoreThresholdKudo
      coachingCdfScoreIssue
      coachingCdfScoreKudo
      coachingCdfScoreRatingIssue
      coachingCdfScoreCO
      coachingCdfScorePR
      coachingCdfScoreRatingKudo
      coachingHarshCorneringRateThresholdIssue
      coachingHarshCorneringRateThreshold
      coachingHarshCorneringRateThresholdPR
      coachingHarshCorneringRateThresholdKudo
      coachingHarshCorneringRateIssue
      coachingHarshCorneringRateCO
      coachingHarshCorneringRatePR
      coachingHarshCorneringRateKudo
      coachingDailyComplianceRateThresholdIssue
      coachingDailyComplianceRateThreshold
      coachingDailyComplianceRateThresholdPR
      coachingDailyComplianceRateThresholdKudo
      coachingDailyComplianceRateIssue
      coachingDailyComplianceRateCO
      coachingDailyComplianceRatePR
      coachingDailyComplianceRateKudo
      coachingTraningRemainingIssue
      coachingTraningRemainingCO
      coachingTraningRemainingPR
      coachingCameraObstructionIssue
      coachingCameraObstructionCO
      coachingCameraObstructionThresholdIssue
      coachingCameraObstructionThreshold
      coachingDriverDistractionIssue
      coachingDriverDistractionCO
      coachingDriverDistractionThresholdIssue
      coachingDriverDistractionThreshold
      coachingDriverDrowsinessIssue
      coachingDriverDrowsinessCO
      coachingDriverDrowsinessThresholdIssue
      coachingDriverDrowsinessThreshold
      coachingDriverInitiatedIssue
      coachingDriverInitiatedCO
      coachingDriverInitiatedThresholdIssue
      coachingDriverInitiatedThreshold
      coachingDriverStarPR
      coachingDriverStarKudo
      coachingDriverStarThresholdPR
      coachingDriverStarThresholdKudo
      coachingFaceMaskComplianceIssue
      coachingFaceMaskComplianceCO
      coachingFaceMaskComplianceThresholdIssue
      coachingFaceMaskComplianceThreshold
      coachingFollowingDistanceIssue
      coachingFollowingDistanceCO
      coachingFollowingDistanceThresholdIssue
      coachingFollowingDistanceThreshold
      coachingHardAccelerationIssue
      coachingHardAccelerationCO
      coachingHardAccelerationThresholdIssue
      coachingHardAccelerationThreshold
      coachingHardBrakingIssue
      coachingHardBrakingCO
      coachingHardBrakingThresholdIssue
      coachingHardBrakingThreshold
      coachingHardTurnIssue
      coachingHardTurnCO
      coachingHardTurnThresholdIssue
      coachingHardTurnThreshold
      coachingHighGIssue
      coachingHighGCO
      coachingHighGThresholdIssue
      coachingHighGThreshold
      coachingLowImpactIssue
      coachingLowImpactCO
      coachingLowImpactThresholdIssue
      coachingLowImpactThreshold
      coachingSeatbeltComplianceIssue
      coachingSeatbeltComplianceCO
      coachingSeatbeltComplianceThresholdIssue
      coachingSeatbeltComplianceThreshold
      coachingSignViolationsIssue
      coachingSignViolationsCO
      coachingSignViolationsThresholdIssue
      coachingSignViolationsThreshold
      coachingSpeedingViolationsIssue
      coachingSpeedingViolationsCO
      coachingSpeedingViolationsThresholdIssue
      coachingSpeedingViolationsThreshold
      coachingTrafficLightViolationIssue
      coachingTrafficLightViolationCO
      coachingTrafficLightViolationThresholdIssue
      coachingTrafficLightViolationThreshold
      coachingUTurnIssue
      coachingUTurnCO
      coachingUTurnThresholdIssue
      coachingUTurnThreshold
      coachingWeavingIssue
      coachingWeavingCO
      coachingWeavingThresholdIssue
      coachingWeavingThreshold
      coachingDriverRankRange
      customerSubType
      shortCode
      growSurfParticipantId
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfParticipantReferralComplete
      stripeCustomerId
      stripeBillingEmail
      zohoCrmAccountRecordId
      driverReportDayRange
      accountType
      allowParentData
      parentAccountId
      updatedAt
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          emailAddedToSuppressionListDateTime
          emailVerified
          role
          isOwner
          isCreator
          receiveSmsTaskReminders
          receiveEmailTaskReminders
          receiveSmsTaskAssignments
          receiveEmailTaskAssignments
          permissionLogin
          permissionFullAccess
          permissionDocuments
          permissionCounselings
          permissionAccidents
          permissionInjuries
          permissionDrugTests
          permissionDailyRostering
          permissionMessenger
          permissionPerformanceCoaching
          permissionDAManagement
          permissionCustomLists
          permissionManageLabels
          permissionVehicleManagement
          permissionTasksReports
          permissionMessageTemplate
          permissionVehiclePhotoLogs
          lastLogin
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const usersByTenant = /* GraphQL */ `
  query UsersByTenant(
    $userTenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTenant(
      userTenantId: $userTenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        firstName
        lastName
        phone
        email
      }
      nextToken
    }
  }
`;
