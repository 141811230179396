<template>
  <div>
    <!--DELETE DIALOG-->
    <el-dialog
    title="Are you sure?"
    :visible.sync="deleteAccountModalOpen"
    width="30%"
    >
      <DeleteDialog 
      :deletionType="deletionType" 
      :deletionMessage="deletionMessage"
      :group="tenantData.group"
      :tenantID="tenantData.id"
      :stripeCustomerId="tenantData.stripeCustomerId"
      :zohoAccountID="tenantData.zohoCrmAccountRecordId"
      @close-deletion-modal="closeModal"
      @show-loading-screen="startLoading"
      @stop-loading="stopLoading"
      @finish-deletion="finishDeletion"/>
    </el-dialog>

    <!-- DEACTIVATE MODAL -->
    <DeactivateTenantModal 
      @close="deactivateModalOpen=false"
      :deactivateModalOpen="deactivateModalOpen" :deactivateTenantLoading="deactivateTenantLoading" :deactivateTenantMessage="deactivateTenantMessage">
    </DeactivateTenantModal>
    
    <div class="flex items-center justify-between">
      <div>
        <el-breadcrumb separator-class="uil uil-angle-right-b m-breadcrum">
          <el-breadcrumb-item :to="{ name: 'Dashboard' }"><i class="uil uil-home"></i></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'SystemNoSelection'}">System Admin</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'SystemTenantIndex'}">Tenants</el-breadcrumb-item>
          <el-breadcrumb-item >{{tenantData.companyName}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div>
        <el-dropdown @command="handleCommand">
          <ContextMenuHeaderIcon class="context-menu-header-hover"/>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item command="login">
               <i class="uil uil-users-alt text-base"></i>Log into this DSP as Support Administrator
            </el-dropdown-item>
            <el-dropdown-item v-if="accountTypeProps.menuOption" divided command="accountTypeMenuAction">
              <i class="uil uil-sitemap text-base"></i>{{ accountTypeProps.menuOption.label }}
            </el-dropdown-item>
            <el-dropdown-item v-if="!showCanceledNotes" :divided="!accountTypeProps.menuOption" command="changePlanToNone">
              <i class="uil uil-bill text-base"></i>Change Plan to None
            </el-dropdown-item>
            <el-dropdown-item command="deleteAccount">
              <i class="uil uil-trash text-base"></i>Delete Entire Account
            </el-dropdown-item>            
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>


    <div class="mt-4">            
      
      <div class="flex">
        <h1>{{tenantData.companyName}}</h1>  
        <el-tooltip class="item flex" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
          <div slot="content" class="custom-tooltip-content-overflow">
            <p class="font-bold mb-1">Company Name Change Log</p>
            <p v-html="tenantData.nameLog"></p>
          </div>
          <p class="text-gray-600"><i class="uil uil-question-circle text-base"></i></p>
        </el-tooltip>

        <span class="self-center px-3 ml-5 font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial"
                  :class="setClassStatus(tenantData.customerStatus)">
           {{tenantData.customerStatus}}
        </span>

        <span
          style="padding-top: 1px; padding-bottom: 1px"
          class="self-center px-3 ml-5 font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial" :class="accountTypeProps.pill.class">
            {{ accountTypeProps.pill.label }}
        </span>
      </div>
      <el-row class="pt-3 notes-container" :gutter="10">
        <el-col v-for="(card, index) in cards" :key="index" :span="24" :md="24">
          <CardView 
            :title="card.title" 
            :idx="index"
            :formData="tenantData" 
            :formRules="rules" 
            :isLoading="isLoading && cardToEditIdx == index"
            @cardEditIdx="(idx) => cardToEditIdx = idx"
            @cancel="handleCancel"
            @save="updateTenant"
          >
            <template v-slot="{ isEditing }">
              <el-row :gutter="20">
                <el-col v-for="(field, fieldIndex) in card.fields" :key="fieldIndex" 
                :span="field.colspan ? field.colspan * 8 : 8"
                :md="field.colspan ? field.colspan * 8 : 8"
                :sm="field.colspan ? field.colspan * 12 : 12"
                :xs="24"
                >
                  <el-form-item v-if="evaluateCondition(field.condition)" :label="field.label" :prop="field.model"> <!-- Use :prop to bind validation rules -->
                    <component
                      :is="getComponentType(field)"
                      :clearable="field.isClearable"
                      :placeholder="field.placeholder || ''"
                      v-model="tenantData[field.model]"
                      v-bind="componentProps(field, isEditing)"
                    >
                      <!-- Render el-option inside the select component -->
                      <template v-if="field.type === 'select' || field.type === 'multiselect'">
                        <el-option
                          v-for="option in getOptions(field)"
                          :key="option.key"
                          :label="option.label"
                          :value="option.value"
                        ></el-option>
                      </template>
                    </component>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </CardView>
        </el-col>
      </el-row>
      <!-- TAB CONTROL -->
      <div class="flex">

        <el-radio-group v-model="tenantDetailsTab" size="small" class="flex-none border-gray-600" >
          <el-radio-button class="w-35" label="Users" ></el-radio-button>
          <el-radio-button class="w-35" label="Messages"></el-radio-button>
          <el-radio-button class="w-35" label="Invoices"></el-radio-button>
        </el-radio-group>
      </div>
      <UserList v-if="tenantData.users && tenantDetailsTab === 'Users'" class="mt-4 p-4 border rounded-md shadow"  :users="tenantData.users.items" :tenantId="tenantData.id" :group="tenantData.group" :companyName="tenantData.companyName" @loadData="loadData"/>

      <div v-if="tenantData.group && tenantDetailsTab === 'Messages'" class="mt-4 p-4 border rounded-md shadow">
        <Message :group="tenantData.group"/>
      </div>

      <ActiveStaff v-if="tenantData.invoices && tenantDetailsTab === 'Invoices'" class="mt-4 p-4 border rounded-md shadow" :tenantId="tenantData.id" :group="tenantData.group" :invoices="tenantData.invoices.items" />

    </div>

    <!-- CHANGE PLAN MODAL -->
    <ChangePlanModal :title="titleChangePlan" :tenant="tenantChangePlan" :show="showChangePlan" @close="showChangePlan = false" @resultChangePlan="resultChangePlan"/>

  </div>
</template>

<script>
//import ContextMenuSelect from "@/components/TableCard/ContextMenuSelect";
import TimeZoneSelect from '@/views/Settings/CompanyDetails/components/TimeZoneSelect.vue';
import Datepicker from '@/components/Datepicker.vue';
import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon';
import TenantForm from './components/TenantForm'
import DeactivateTenantModal from './components/DeactivateTenantModal'
import Message from '../../Settings/Messages/Messages'
import ActiveStaff from './components/ActiveStaff'
import { updateZohoAccountTenant } from '../../../components/Zoho/zoho';
// import UserList from '../Users/components/UserList'
import { getTenant, getTenantAccountData, tenantsByAccountType, updateTenantAccountType } from './queries'
import UserList from '@/views/Settings/Users/components/UserList'
import CardView from '@/views/Settings/Users/components/CardView.vue'
import { mapFeatureTenant} from './helper'
import { updateTenantDetail } from '@/store/mutations'
import { getTenantUpdatedTimestamp } from '@/utilities/timestampQueries'
import DeleteDialog from '@/components/DeleteDialog'
import { updateUser } from '@/graphql/mutations'
import { telnyxAddNumber, telnyxRemoveNumber} from './telnyx'
import { Auth, API } from 'aws-amplify';
import { setGroup, changePlanToNone } from './tenant'
import ChangePlanModal from "./components/ChangePlanModal"
import { CUSTOMER_SUB_TYPE_XL } from '@/views/System/Tenants/constants'
import { getTenantData } from '@/utilities/getTenantData';

import {
  REGULAR_ACCOUNT,
  PARENT_ACCOUNT,
  CHILD_ACCOUNT,
  CUSTOMER_TYPE,
  CUSTOMER_SUBTYPE,
  CUSTOMER_TYPE_SUBTYPE_AMAZON_DSP,
  CUSTOMER_TYPE_SUBTYPE_FEDEX_ISP,
  CUSTOMER_TYPE_AMAZON_DSP,
  ACCOUNT_COUNTRY,
  ACCOUNT_CANCELED_REASON,
  FEATURE_ACCESS_PERMISSION_FILL
} from './constants';

  export default {
    name: 'TenantDetail',
    components: { TenantForm, DeactivateTenantModal, UserList, CardView, TimeZoneSelect, ActiveStaff, DeleteDialog, Message, ContextMenuHeaderIcon, ChangePlanModal },
    props: ['id'],
    data() {
      return {
        modalOpen: false,
        isLoading: false,
        cardToEditIdx: null,  // Tracks the index of the card being edited to display the loading state only for that card
        deactivateModalOpen: false,
        deactivateTenantLoading: false,
        deactivateTenantMessage: '',
        search: '',
        allUsers: [],
        isDataLoaded: false,
        tenantData:{
          featureAccess: [], // Initialize as empty array
          featureEnabled: [],
          customerType: null,     // Selected Customer Type
          customerSubType: null, 
          flatMonthlyBillingAmount: null,
        },
        originalBillingValue: null,
        originalMessageServiceProvider: null,
        originalCompanyName: null,
        isBillingWarningDialogOpen: false,
        listCustomerSubType: CUSTOMER_SUBTYPE || [],
        premiumStatusHistory: [],
        servicesMessage: [
          'None',
          'Telnyx'
        ],
        featureAccess:[],
        featureEnabled:[],
        averageActiveStaffTotal: null,
        deletionType: '',
        deletionMessage: '',
        deleteAccountModalOpen: false,
        showChangePlan: false,
        titleChangePlan: '',
        tenantChangePlan: {},
        tenantDetailsTab: 'Users', //default Users Tab will be open
        parentAccountList: [],
        rules: {
          companyName: [
            {required: true, message: "Please introduce a company name", trigger: 'blur'}
          ],
          timeZone: [
            {required: true, message: "Please select a timezone", trigger: 'change'}
          ],
          trialExpirationDate: [
            {required: true, message: "Please select a trial expiration date", trigger: 'change'}
          ],
          customerType: [
            {required: true, message: "Please select a customer type", trigger: 'change'}
          ],
          customerSubType: [
            {required: true, message: "Please select a customer sub type", trigger: 'change'}
          ],
          flatMonthlyBillingAmount: [
            { 
              validator: (rule, value, callback) => {
                if (value === null || value === undefined || value === '') {
                  callback(); // No validation if the value is null or empty
                } else if (value < 0) {
                  callback(new Error('Please enter a positive number greater than 0'));
                } else {
                  callback(); // Pass validation if value is greater than 0
                }
              },
              trigger: 'blur'
            }
          ],
          accountCountry: [
            {required: true, message: "Please select an account country", trigger: 'change'}
          ],
          zohoCrmAccountRecordId: [
            {required: true, message: "Please introduce a ZOHO account id", trigger: 'blur'}
          ]
        }
      }
    },
    watch: {
      'tenantData.featureAccess'(newValues) {
        if (!newValues.includes('Vehicle Management: Vehicle Photo Logs Hera AI')) {
            this.tenantData.featureEnabled = this.tenantData.featureEnabled.filter(item => item !== 'Vehicle Management: Vehicle Photo Logs Hera AI');
        }
        if (!newValues.includes('Inventory Management')) {
            this.tenantData.featureEnabled = this.tenantData.featureEnabled.filter(item => item !== 'Inventory Management');
        }
      },
      'tenantData.customerType'(newCustomerType) {
        this.changeCustomerType(newCustomerType);
      },
      'tenantData.flatMonthlyBillingAmount': function (newVal, oldVal) {
        if(!this.isDataLoaded) return;
        //If the change is from null/0 to >0, show flat-rate warning
        if ((this.originalBillingValue === null || this.originalBillingValue === 0 || this.originalBillingValue === undefined) && newVal > 0) {
          this.isBillingWarningDialogOpen = true;
          this.showBillingWarningDialog(
            'Warning: This tenant already has line items for this month which will be zeroed out if you change this Tenant to flat-rate billing. (But we will still track the number of active Associates each day.) Are you sure you want to save your changes?',
            'flat-rate'
          );
        }

        // If the change is from >0 to null, empty, or 0, show variable-rate warning
        if (this.originalBillingValue > 0 && (newVal === null || newVal === '' || newVal === 0  || newVal === undefined)) {
          this.isBillingWarningDialogOpen = true;
          this.showBillingWarningDialog(
            `Warning: This tenant already has line items for this month which will be reclaculated based on the Tenant's current plan if you change this Tenant to variable-rate billing. Are you sure you want to save your changes?`,
            'variable-rate'
          );
        }
      },
    },
    mounted(){
      this.loadParentAccountList();
      (async () => {
        await this.loadData();
        this.updateOrfanChildToRegularAccount();
        this.isDataLoaded = true;
      })();
    },
    computed:{
        cards() {
          return [
            {
              title: 'Company Info',
              fields: [
                { label: 'Company Name', model: 'companyName', type: 'input' },
                { label: 'Short Code', model: 'shortCode', type: 'input' },
                {
                  label: 'Timezone',
                  model: 'timeZone',
                  type: 'custom',
                  component: 'TimeZoneSelect',
                }
              ]
            },
            {
              title: 'Account Settings',
              fields: [
                { label: 'Customer Type', model: 'customerType', type: 'select', optionsKey: 'getListCustomerType' },
                { label: 'Customer Sub-Type', model: 'customerSubType', type: 'select', optionsKey: 'getListCustomerSubType' },
                { label: 'Parent Account', model: 'parentAccountId', type: 'select', optionsKey: 'getParentAccountList', condition: this.isRegularAccount || this.isChildAccount, isClearable: true, placeholder: 'Select Parent Account' },
                { label: 'Allow Parent Data', model: 'allowParentData', type: 'checkbox', condition: this.isParentAccount },
                { label: 'Account Country', model: 'accountCountry', type: 'select', optionsKey: 'getListAccountCountry' },
                { label: 'Temporary Account', model: 'isTemporaryAccount', type: 'switch' },
                { label: 'Testing Account', model: 'isTestingAccount', type: 'switch' },
                { label: 'First Interest Date', model: 'firstInterestDateTime', type: 'date', placeholder: 'Select a date' },
                { label: 'Trial Expiration Date', model: 'trialExpDate', type: 'date', placeholder: 'Select a date' },
                { label: 'Cancelation Notes', model: 'accountCanceledNotes', type: 'input', condition: this.showCanceledNotes, isClearable: true },
                { label: 'Cancelation Reason', model: 'accountCanceledReason', type: 'select', optionsKey: 'getListCancelationReason', condition: this.showCanceledNotes, isClearable: true, placeholder: 'Select a reason' }
              ]
            },
            {
              title: 'Billing Settings',
              fields: [
                { label: 'Flat Monthly Billing $', model: 'flatMonthlyBillingAmount', type: 'number', min: 0 },
                { label: 'Flat Monthly Billing Label', model: 'flatMonthlyBillingLabel', type: 'input', colspan: 2 },
                { label: 'Discount %', model: 'discountPercent', type: 'number', max: 100, step: 0.1 },
                { label: 'Discount % Label', model: 'discountPercentLabel', type: 'input', colspan: 2 },
                { label: 'Discount $', model: 'discountFixed', type: 'number' },
                { label: 'Discount $ Label', model: 'discountFixedLabel', type: 'input', colspan: 2 }
              ]
            },
            {
              title: 'Features',
              fields: [
                { label: 'Feature Access', model: 'featureAccess', type: 'multiselect', optionsKey: 'getListFeatureAccess', placeholder: "feature access", colspan: 3 },
                { label: 'Features Enabled', model: 'featureEnabled', type: 'multiselect', optionsKey: 'getListFeatureEnabled', placeholder: "feature enabled", colspan: 3 },
                { label: 'Messaging Service', model: 'messageServiceProvider', type: 'select', optionsKey: 'getServicesMessage' },
                {
                  label: 'Origination Number',
                  model: 'originationNumber',
                  type: 'input',
                  condition: () => this.tenantData.originationNumber,
                },
              ]
            },
            {
              title: 'Third-Party Services',
              fields: [
                { label: 'Zoho CRM Account ID', model: 'zohoCrmAccountRecordId', type: 'input', condition: !this.$store.getters?.isZohoCrmDisabled },
                { label: 'GrowSurf Participant ID', model: 'growSurfParticipantId', type: 'input' }
              ]
            },
            {
              title: 'Notes',
              fields: [
                { label: 'Notes', model: 'notes', type: 'input', colspan: 3, inputType: 'textarea' }
              ]
            }
          ]
        },
        showCanceledNotes(){
            return this.tenantData.accountPremiumStatus?.includes('None') ||  this.tenantData.customerStatus === 'Churned'
        },
        isRegularAccount() { // By default the accounts are treated as REGULAR
          return (
            !this.tenantData.accountType ||
            this.tenantData.accountType === REGULAR_ACCOUNT);
        },
        isParentAccount() {
          return this.tenantData.accountType === PARENT_ACCOUNT;
        },
        isChildAccount() {
          return this.tenantData.accountType === CHILD_ACCOUNT;
        },
        accountTypeProps() {
          if (this.isRegularAccount) {
            return this.getRegularAccountProps();
          }
          if (this.isParentAccount) {
            return this.getParentAccountProps();
          }
          if (this.isChildAccount) {
            return this.getChildAccountProps();
          }

          return this.getEmptyAccountProps();
        },
        componentProps() {
          // Compute and cache the props for each field based on its state and isEditing
          return (field, isEditing) => {
            const commonProps = {
              disabled: field.disabledKey ? !this.$store.getters[field.disabledKey] : !isEditing,
              placeholder: field.placeholder || "",
              style: "width: 100%"
            };
            if (field.type === 'multiselect') {
              commonProps.multiple = true;
            }
            if (field.type === "date") {
              return {
                ...commonProps,
                type: "date",
                format: "MM/dd/yyyy",
                style: "width: 100%",
              };
            }
            // Handle textarea with autosize
            if (field.type === "input" && field.inputType === "textarea") {
              return {
                ...commonProps,
                type: "textarea",
                autosize: { minRows: 4 },
              };
            }
            if (field.type === "number") {
            return {
              ...commonProps,
              min: field.min !== undefined ? field.min : 0,  // Use provided min, default to 0
              max: field.max !== undefined ? field.max : undefined,  // Only set max if provided
              step: field.step !== undefined ? field.step : 1, 
              controls: true
            };
          }
            return commonProps;
          };
        }
    },
    methods: {

      /**
       * Close modal and clear out deletionConfirmation
       */
      closeModal(){
        this.deleteAccountModalOpen = false;
      },

      setClassStatus(status) {
       return {
        'text-yellow-600 bg-yellow-500': status && (status.toLowerCase() == 'trial' || status.toLowerCase() == 'trial (plan selected)'),
        'text-red-600 bg-red-500': status && status.toLowerCase() == 'lapsed trial' || status && status.toLowerCase() == 'churned',
        'text-orange-600 bg-orange-500': status && status.toLowerCase() == 'unknown',
        'text-green-600 bg-green-500': status && status.toLowerCase().includes('active'),
       }
      },
      /**
       * Return the the tenant list after deletion complete
       */
      finishDeletion(){
        this.$router.push({name: 'SystemTenantIndex'})
      },


      /**
       * Handles commands passed from the dropdown
       * @param {string} command - The name of the route you want to go to
       */
      async handleCommand(command){
        if(command == "deleteAccount"){
          this.openDeletionModal('Account', `Are you sure you want to delete this tenant's account and all associated data?`)
          return
        }else if(command == 'changePlanToNone'){
          let message = `Would you like to continue?`;
          if(!!this.tenantData.bundleDeactivationAllowedAt) {
            let deactivationDate = this.$moment(this.tenantData.bundleDeactivationAllowedAt).format('YYYY-MM-DD');
            message = `The tenant's bundle expires on ${deactivationDate}; would you like to continue?`
          } 
          this.titleChangePlan = message
          this.tenantChangePlan = { ...this.tenantData }            
          this.showChangePlan = true
        }else if(command == 'login'){
          let tenantWithAllUsers = this.tenantData 
          tenantWithAllUsers.users = {items: this.allUsers }
          console.log('tenant with all usres', tenantWithAllUsers)
          await setGroup(tenantWithAllUsers, this.api)
        } else if (command === 'accountTypeMenuAction') {
          this.accountTypeProps.menuOption.action();
        }
      },

      async resultChangePlan(result){
        if(result) this.deactivateTenantMessage = result.message
        this.deactivateTenantLoading = false
        if(result.status=='Success'){
          this.loadData()
        }
      },

      /**
       * Load data using the getTenant custom GraphQL query
       */
       async loadData(){
        try{
          this.featureAccess = []
          this.featureEnabled = []
          let tenant = await this.api(getTenant, { id: this.id })
          this.allUsers = [...tenant.data.getTenant.users.items];
          
          tenant.data.getTenant.users.items = tenant.data.getTenant.users.items.filter(user => !(user.email.startsWith('sysadmin-') && user.email.endsWith('@herasolutions.info')))
          console.log('tenants no sysadmin',tenant)
          let hasCreator = tenant.data.getTenant.users.items.find(user => user.isCreator)
          let hasOwner = tenant.data.getTenant.users.items.find(user => user.isOwner)
          if(tenant.data.getTenant.users.items.length > 0) {
            if (!hasCreator || !hasOwner) {
              let dates = tenant.data.getTenant.users.items.map((user) => {
                return new Date(user.createdAt)
              })
              let oldDate = new Date(Math.min(...dates))
              let firstUser = tenant.data.getTenant.users.items.find((user) => oldDate.getTime() === new Date(user.createdAt).getTime())
              firstUser.isCreator = hasCreator ? false : true
              firstUser.isOwner = hasOwner ? false : true
              await this.api(updateUser, {input: firstUser})
            }
          }

          let tenantGroup = tenant.data.getTenant.group

          for (const user of tenant.data.getTenant.users.items) {
            let newUserLastLogin = user.lastLogin
            if (!user.lastLogin) {
              const cognitoUser = await this.getCognitoUser(user.email)
              if (cognitoUser.UserStatus === 'FORCE_CHANGE_PASSWORD') {
                newUserLastLogin = 'NOT_YET_LOGGED'
              } else {
                newUserLastLogin = new Date('1970/01/01').toISOString()
              }
              await this.api(updateUser, {input: { id: user.id, group: tenantGroup, lastLogin: newUserLastLogin }})
              user.lastLogin = newUserLastLogin
            }
          }
          
          this.tenantData = tenant.data.getTenant         
          this.updateFeaturesInTenantData();

          this.premiumStatusHistory = [...this.tenantData.premiumStatusHistory.items]
            .sort((a,b)=>a.createdAt > b.createdAt ? -1 : 1)
          
          
          // this.tenantData.users.items
          var invoices = this.tenantData.invoices.items;
          var invoiceCount = invoices.length;
          var activeStaffTotal = 0;
          invoices.forEach(invoice =>{
            activeStaffTotal = activeStaffTotal + invoice.averageActiveDriverCount;
          });

          var averageActiveStaff = activeStaffTotal / invoiceCount;
          console.log(Number.isNaN(averageActiveStaff))
          if(Number.isNaN(averageActiveStaff) == true){
            this.averageActiveStaffTotal = ""
          }
          else{
            this.averageActiveStaffTotal = averageActiveStaff.toFixed(2);
          }
          this.originalBillingValue = this.tenantData?.flatMonthlyBillingAmount;
          this.originalMessageServiceProvider = this.tenantData?.messageServiceProvider;
          this.originalCompanyName = this.tenantData?.companyName;
        }catch(e){
          console.error(e)
          this.displayUserError(e)
        }
      },


      /**
       * Opens deletion dialog and sets parameters up for deletion functions
       */
      openDeletionModal: function(type, message){
        this.deletionType = type;
        this.deletionMessage = message;
        this.deleteAccountModalOpen = true;
      },

      async updateTenantNameInCognitoUserAttributes(group, tenantName){
        let apiName = 'AdminQueries';
        let path = '/updateTenantName';
        let post = {
          body: {
            "group": group,
            "tenantName": tenantName 
          }, 
          headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          } 
        }
        safeFunction(API.post)(apiName, path, post);
      },

      validateForm(){
      if (!this.editTenantData.companyName) throw 'Please fill company name'
      return true
      },

      async updateStripeCustomer(tenant){
      try{
          let apiName = 'stripeSetup'
          let path = '/customer/' + tenant.stripeCustomerId
          let post = {
            body: {
              name: tenant.companyName,
              email: tenant.stripeBillingEmail,
              address:{
                city: tenant.addressCity,
                line1: tenant.addressLine1,
                line2: tenant.addressLine2,
                postal_code: tenant.addressZip,
                state: tenant.addressState
              }
            }
          }
          var result = await safeFunction(API.put)(apiName, path, post)
          return result.customer
      }catch(e){
          console.error(e)
          this.displayUserError(e)
      }
    },
      /**
       * Set loading to true
       */
      startLoading(){
        this.loading = true;
      },


      /**
       * Set loading to false
       */
      stopLoading(){
        this.loading = false;
      },

      /** 
     * Gets a single cognito user
     * @param {string} username 
     * */
      async getCognitoUser(username){
        let apiName = 'AdminQueries';
        let path = '/getUser';
        let myInit = {
          queryStringParameters: {
            "username": username,
          }, 
          headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          } 
        }
        var result = await API.get(apiName, path, myInit);
        return result
      },

      /** ACCOUNT TYPE HELPERS */
      getRegularAccountProps() {
        return {
          pill: {
            label: 'Regular Account',
            class: 'text-green-600 bg-green-500',
          },
          parentAccountValue: '—',
          menuOption: {
            icon: 'sitemap',
            label: 'Upgrade to Parent Account',
            action: this.upgradeToParentAccount,
          }
        };
      },
      getParentAccountProps() {
        return {
          pill: {
            label: 'Parent Account',
            class: 'text-red-600 bg-red-500',
          },
          allowParentDataValue: this.tenantData.allowParentData,
          menuOption: {
            icon: 'sitemap',
            label: 'Downgrade to Regular Account',
            action: this.confirmAndDowngradeToRegularAccount,
          }
        };
      },
      getChildAccountProps() {
        return {
          pill: {
            label: 'Child Account',
            class: 'text-yellow-600 bg-yellow-500',
          },
          parentAccountId: this.tenantData.parentAccountId,
          parentAccountValue: this.tenantData.parentAccount?.companyName || '—',
          menuOption: false
        };
      },

      getEmptyAccountProps() {
        return {
          pill: { label: '', class: '', },
          parentAccountValue: '—',
          menuOption: false
        };
      },

      async updateTenantAccountFields(message, variables) {
        try {
          await this.api(updateTenantAccountType, variables);
          await this.loadData();

          if (message) {
            this.displayUserNotification({
              title: 'Success',
              type: "success",
              message,
            });
          }
        } catch(err) {
          this.displayUserError(err);
        }
      },

      async upgradeToParentAccount() {
        const input = {
          id: this.tenantData.id,
          group: this.tenantData.group,
          allowParentData: true,
          parentAccountId: null,
          accountType: PARENT_ACCOUNT,
        };

        const today = new Date();
        today.setHours(0,0,0,0);
        const exDate = new Date(this.tenantData.trialExpDate);

        if(exDate>=today){
          this.tenantData.customerStatus = 'Trial (Plan Selected)'
          input.customerStatus = 'Trial (Plan Selected)'
        }

        const msg = 'Successfully upgraded to Parent Account';
        await this.updateTenantAccountFields(msg, { input });
      },

      async downgradeToRegularAccount() {
        const input = {
          id: this.tenantData.id,
          group: this.tenantData.group,
          allowParentData: false,
          parentAccountId: null,
          accountType: REGULAR_ACCOUNT,
        };

        const today = new Date();
        today.setHours(0,0,0,0);
        const exDate = new Date(this.tenantData.trialExpDate);

        if(exDate>=today){
          this.tenantData.customerStatus = 'Trial (Plan Selected)'
          input.customerStatus = 'Trial (Plan Selected)'
        }

        const msg = 'Successfully downgraded to Regular Account';
        await this.updateTenantAccountFields(msg, { input });

        await this.updateOrfanChildrenToRegularAccount(this.tenantData.id);
      },

      async updateOrfanChildrenToRegularAccount(parentAccountId) {
        try {
          const accountType = CHILD_ACCOUNT;
          const filter = {
            parentAccountId: { eq: parentAccountId },
          };
          const {data:{tenantsByAccountType:{items:orfanChildren}}} = await this.api(tenantsByAccountType, {accountType, filter}, 'tenantsByAccountType');

          const input = {
            id: undefined,
            allowParentData: false,
            parentAccountId: null,
            accountType: REGULAR_ACCOUNT,
          };

          const inProgress = orfanChildren.map(async (orfan) => {
            const update = { ...input, id: orfan.id };
            await this.api(updateTenantAccountType, { input: update });
          });

          await Promise.all(inProgress);
        } catch (err) {
          this.displayUserError(err);
        }
      },
      
      confirmAndDowngradeToRegularAccount() {
        const msg = "Warning: Downgrading will cause this account to no longer show any of its child accounts' data, and any parent data will no longer appear on its child accounts. All of this account's child accounts will be converted to regular accounts. Are you sure you want to downgrade?";

        this.$confirm(msg, 'Warning', {
          confirmButtonText: 'Downgrade',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        .then(this.downgradeToRegularAccount)
        .catch(() => {});
      },

      async confirmUpgradeFromChildToRegularAccount() {
        const msg = "Warning: Removing this parent account will also remove any of its data from this tenant, and data from this tenant will no longer appear on the parent account. Are you sure you want to continue?";

        await this.$confirm(msg, 'Warning', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        });
      },

      getChildAccountValues(parentAccountId) {
        return {
          allowParentData: false,
          parentAccountId: parentAccountId,
          accountType: CHILD_ACCOUNT,
        }
      },

      getRegularAccountValues() {
        return {
          allowParentData: false,
          parentAccountId: null,
          accountType: REGULAR_ACCOUNT,
        };
      },

      async updateTenantToRegularAccount() {
        const input = {
          id: this.tenantData.id,
          group: this.tenantData.group,
          allowParentData: false,
          parentAccountId: null,
          accountType: REGULAR_ACCOUNT,
        };

        try {
          let message = 'We noticed that this child account has left orfan, ' +
            'and need to be upgraded to REGULAR account, ' +
            'please wait until this process ends.';
          this.displayUserNotification({
            title: 'Success',
            type: "info",
            message,
          });

          await this.api(updateTenantAccountType, { input });
          await this.loadData();

          message = 'Successfully upgraded to Regular account';
          this.displayUserNotification({
            title: 'Success',
            type: "success",
            message,
          });
        } catch(err) {
          this.displayUserError(err);
        }
      },

      async updateOrfanChildToRegularAccount() {
        if (!this.isChildAccount) return;
        if (!this.tenantData.parentAccountId) {
          await this.updateTenantToRegularAccount();
          return;
        }

        const variables = { id: this.tenantData.parentAccountId };
        const {data:{getTenant:parentAccount}} = await this.api(getTenantAccountData, variables);
        if (!parentAccount) {
          await this.updateTenantToRegularAccount();
          return;
        }

        if (parentAccount.accountType === PARENT_ACCOUNT) return;

        await this.updateTenantToRegularAccount();
      },

      getParsingDataGrowSurf(data){
        if(!data) return {}
        const { firstName, lastName, email, referrer } = data
        const parseData = {
          growSurfReferredByFullNameBackup: (referrer) ? `${referrer.firstName} ${referrer.lastName}` : null,
          growSurfReferredByEmailBackup: (referrer) ? `${referrer.email}` : null,
          growSurfParticipantFullName: (firstName) ? `${firstName} ${lastName}` : null,
          growSurfParticipantEmail: email ?? null
        }
        return parseData
      },

      async getDataByApiGrowSurf(participantId){
        if(!participantId) return 
        try{
          const apiName = 'tenant'
          const path = '/growSurf/' + participantId
          const {dataGrowSurf} = await API.get(apiName, path)
          return dataGrowSurf
        }catch(e){
            console.error(e)
        }
      },
      handleCancel(originalData) {
        this.tenantData = originalData
      },
      /** Update the tenant in the DB */
      async updateTenant(editTenantData){ 
        this.isLoading = true
        try {
          if (this.isBillingWarningDialogOpen) {
            return;
        }
          editTenantData = await mapFeatureTenant(editTenantData)
          
          let tenantUpdatedAt = editTenantData.updatedAt
          if (this.originalMessageServiceProvider != editTenantData.messageServiceProvider){
            if (this.originalMessageServiceProviderr == 'None' && editTenantData.messageServiceProvider == 'Telnyx') {
              const updatedTenant = await telnyxAddNumber(this.tenantData)
              tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
            }

            if (this.originalMessageServiceProviderr == 'Telnyx' && editTenantData.messageServiceProvider == 'None') {
              const updatedTenant = await telnyxRemoveNumber(this.tenantData)
              tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
            }

            if (this.originalMessageServiceProviderr == 'Bandwidth' && editTenantData.messageServiceProvider == 'Telnyx') {
              const updatedTenant = await telnyxAddNumber(this.tenantData)
              tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
            }

            if (this.originalMessageServiceProviderr == 'Bandwidth' && editTenantData.messageServiceProvider == 'None') {
              const updatedTenant = await telnyxRemoveNumber(this.tenantData)
              tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
            }
          }

          //Get Data By GrowSurf
          const growSurfData = await this.getDataByApiGrowSurf(editTenantData?.growSurfParticipantId)
          const parseGrowSurfData = this.getParsingDataGrowSurf(growSurfData)

          let input = {
            ...parseGrowSurfData,
            id: editTenantData.id,
            companyName: editTenantData.companyName,
            shortCode: editTenantData.shortCode,
            accountCanceledReason: editTenantData.accountCanceledReason,
            accountCanceledNotes: editTenantData.accountCanceledNotes,
            growSurfParticipantId: editTenantData.growSurfParticipantId,
            trialExpDate: editTenantData.trialExpDate,
            firstInterestDateTime: editTenantData.firstInterestDateTime,
            group: editTenantData.group,
            nameLog: this.tenantData.nameLog,
            notes: editTenantData.notes,
            discountPercent: editTenantData.discountPercent ?? 0,
            discountFixed: editTenantData.discountFixed ?? 0,
            discountPercentLabel: editTenantData.discountPercentLabel,
            discountFixedLabel: editTenantData.discountFixedLabel,
            flatMonthlyBillingAmount: editTenantData.flatMonthlyBillingAmount ?? 0,
            flatMonthlyBillingLabel: editTenantData.flatMonthlyBillingLabel, 
            customerSubType: editTenantData.customerSubType,
            updatedAt: tenantUpdatedAt,
            timeZone: editTenantData.timeZone,
            // Account TYPE fields
            allowParentData: editTenantData.allowParentData,
            parentAccountId: editTenantData.parentAccountId,
            accountType: editTenantData.accountType,
            customerType: editTenantData.customerType,
            customerSubType: editTenantData.customerSubType,
            accountCountry: editTenantData.accountCountry,
            isTemporaryAccount: editTenantData.isTemporaryAccount,
            isTestingAccount: editTenantData.isTestingAccount,
            //ZOHO
            zohoCrmAccountRecordId: editTenantData.zohoCrmAccountRecordId,
            //Features
            featureAccessVehiclePhotoLogsHeraAi: editTenantData.featureAccessVehiclePhotoLogsHeraAi,
            featureAccessInventoryManagement: editTenantData.featureAccessInventoryManagement,
            featureEnabledVehiclePhotoLogsHeraAi: editTenantData.featureEnabledVehiclePhotoLogsHeraAi,
            featureEnabledInventoryManagement: editTenantData.featureEnabledInventoryManagement,
            originationNumber: editTenantData.originationNumber
          }

          if (editTenantData.parentAccountId) { // Setup account to be CHILD ACCOUNT
            input = {
              ...input,
              ...this.getChildAccountValues(editTenantData.parentAccountId),
            };
          } else if (this.isChildAccount) { // User wants to pass from CHILD to REGULAR account
            try {
              await this.confirmUpgradeFromChildToRegularAccount();
              
              input = {
                ...input,
                ...this.getRegularAccountValues(),
              };
            } catch { // On cancel
              return;
            }
          }

          if (this.originalCompanyName != editTenantData.companyName){
            //name changed
            var timestamp = this.getTenantTimeCustomFormat();
            var oldLog = this.tenantData.nameLog ? this.tenantData.nameLog : ''
            input.nameLog = oldLog + 'Name changed from "' + this.originalCompanyName + '" to "' + editTenantData.companyName + '" at ' + timestamp + '<br/>'
            //Update Tenant Name in Cognito user attributes
            await this.updateTenantNameInCognitoUserAttributes(editTenantData.group, editTenantData.companyName)
          }

          // set customer status      
          input.customerStatus = this.calculateCustomerStatus(editTenantData.accountPremiumStatus, editTenantData.trialExpDate)
      
          let updatedTenant = await this.api(updateTenantDetail, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
          updatedTenant = updatedTenant.data.updateTenant
          if(updatedTenant.stripeCustomerId) await this.updateStripeCustomer(updatedTenant)

          //update invoice line items if billing type changed
          const newBillingValue = updatedTenant?.flatMonthlyBillingAmount;
          var shouldCallUpdateAPI = false;
          // Check if originalBillingValue was null, undefined, or 0 and new value is greater than 0
          let billingType;
          if ((this.originalBillingValue === null || this.originalBillingValue === undefined || this.originalBillingValue === 0) && newBillingValue > 0) {
            shouldCallUpdateAPI = true;
            billingType = "flat"
          }
          // Check if originalBillingValue was greater than 0 and new value is null, undefined, or 0
          else if (this.originalBillingValue > 0 && (newBillingValue === null || newBillingValue === undefined || newBillingValue === 0)) {
            shouldCallUpdateAPI = true;
            billingType = "variable"
          }
          if(shouldCallUpdateAPI){
            let input = {
              tenantId: editTenantData.id,
              billingType: billingType
            };
            console.log(input);
            let apiName = 'invoice';
            let path = '/reset-invoice-line-items';
            let post = {
              body: input
            };
            await safeFunction(API.post)(apiName, path, post);
          }

          this.loadData()
          this.$message({
            type: 'success',
            message: 'Tenant Updated'
          });
          
          // update intercom, only if there is at least one user. 
          // if(updatedTenant.users.items.length > 0){                     
          //   await updateIntercom(updatedTenant, this.$store)
          // }
          // update zoho account tenant
          let _payload = {
                    id: updatedTenant.zohoCrmAccountRecordId,
                    Tenant_ID: updatedTenant.id,
                    Account_Name: updatedTenant.companyName,
                    Short_Code:updatedTenant.shortCode,
                    Uses_XL_Coaching:updatedTenant.customerSubType === CUSTOMER_SUB_TYPE_XL ? "Yes" : "No",
                    Origination_Number:updatedTenant.originationNumber,
                    Customer_Status:updatedTenant.customerStatus,
                    Cancellation_Reason:updatedTenant.accountCanceledReason,
                    Cancellation_Notes:updatedTenant.accountCanceledNotes,
                    Discount_Percent:updatedTenant.discountPercent ? updatedTenant.discountPercent.toString() : "",
                    Discount_Percent_Label:updatedTenant.discountPercentLabel,
                    Discount_Fixed:updatedTenant.discountFixed ? updatedTenant.discountFixed.toString() : "",
                    Discount_Fixed_Labeld:updatedTenant.discountFixedLabel,
                    Trial_Expiration_Date1: updatedTenant.trialExpDate.split('T')[0],
                    Timezone:updatedTenant.timeZone,
                    Group: updatedTenant.group
                  }
          if(updatedTenant.parentAccount && updatedTenant.parentAccount.zohoCrmAccountRecordId){
            _payload.Parent_Account = {id: updatedTenant.parentAccount.zohoCrmAccountRecordId}
          }
          try{
            if(!this.$store.getters?.isZohoCrmDisabled){
              const resp = await updateZohoAccountTenant('update', _payload, this.displayUserError)
            }
            
          }catch(e){
            this.displayUserNotification({
                title: "Error",
                type: "error",
                message: e
            })
          }
        } 
        catch(e) {
          console.error(e)
          this.displayUserError(e)     
        } finally {
          this.loading = false
          this.isLoading = false
        }
      },
      getComponentType(field) {
        const typeToComponentMap = {
          input: "el-input",
          select: "el-select",
          multiselect: "el-select",
          checkbox: "el-checkbox",
          date: "el-date-picker",
          textarea: "el-input",
          number: "el-input-number",
          switch: "el-switch",
          custom: field.component, // for custom components like TimeZoneSelect or DatePicker
        };
        return typeToComponentMap[field.type] || "el-input"; // default to el-input if type is not specified
      },
      getListCustomerType() {
        return (CUSTOMER_TYPE || []).map((type, index) => ({
          key: index,            // Assign a unique key or id
          label: type,          // Assign a readable label
          value: type           // Assign the value to be selected
        }));
      },
      getListCustomerSubType() {
        return (this.listCustomerSubType || []).map((type, index) => ({
          key: index,
          label: type,
          value: type,
        }));
      },
      getListAccountCountry() {
        // Assuming CUSTOMER_SUBTYPE is an array of strings like ['Type A', 'Type B']
        return (ACCOUNT_COUNTRY || []).map((type, index) => ({
          key: index,
          label: type,
          value: type
        }));
      },
      getListCancelationReason(){
        return (ACCOUNT_CANCELED_REASON || []).map((type, index) => ({
          key: index,
          label: type,
          value: type
        }));
      },
      getListFeatureAccess(){
        return Object.entries(FEATURE_ACCESS_PERMISSION_FILL).map(([key, label], index) => ({
          key: index,
          label: label,
          value: label
      }));  
      },
      getListFeatureEnabled(){
        this.featureEnabledList = []
      
        if (this.tenantData.featureAccess.includes('Vehicle Management: Vehicle Photo Logs Hera AI')) {
            this.featureEnabledList.push('Vehicle Management: Vehicle Photo Logs Hera AI');
        }

        if (this.tenantData.featureAccess.includes('Inventory Management')) {
            this.featureEnabledList.push('Inventory Management');
        }
        return (this.featureEnabledList || []).map((type, index) => ({
          key: index,
          label: type,
          value: type
        }));
      },
      async loadParentAccountList() {
            if (this.isParentAccount) return;

            const accountType = PARENT_ACCOUNT;
            this.parentAccountList = await this.gLoadListAll(tenantsByAccountType, { accountType }, 'tenantsByAccountType');
      },
      getParentAccountList() {
        let options =  this.parentAccountList.map(account => ({
          key: account.id,
          label: account.companyName,
          value: account.id
        }));
        return options;
      },
      getServicesMessage() {
        return (this.servicesMessage || []).map((type, index) => ({
          key: index,
          label: type,
          value: type
        }));
      },
      evaluateCondition(condition) {
        if (typeof condition === 'function') {
          return condition(); // Execute the function to evaluate the condition
        }
        return condition ?? true; // return the condition if provided; otherwise, return  true to display the field by default.
      },
      getOptions(field) {
        // Check if optionsKey is a method that needs to be awaited
        if (field.optionsKey === 'getParentAccountList') {
          return this.getParentAccountList(); // Call the async options method
        }
        // For synchronous methods, call them directly
        return this[field.optionsKey] ? this[field.optionsKey]() : [];
      },
      updateFeaturesInTenantData(){
        if(this.tenantData.featureAccessVehiclePhotoLogsHeraAi) this.featureAccess.push('Vehicle Management: Vehicle Photo Logs Hera AI')
        if(this.tenantData.featureAccessInventoryManagement) this.featureAccess.push('Inventory Management')

        if(this.tenantData.featureEnabledVehiclePhotoLogsHeraAi && this.tenantData.featureAccessVehiclePhotoLogsHeraAi) this.featureEnabled.push('Vehicle Management: Vehicle Photo Logs Hera AI')
        if(this.tenantData.featureEnabledInventoryManagement && this.tenantData.featureAccessInventoryManagement) this.featureEnabled.push('Inventory Management')
        this.tenantData = {
          ...this.tenantData,
          featureAccess: this.featureAccess|| [],
          featureEnabled: this.featureEnabled|| []
        };
      },
      changeCustomerType(selectedCustomerType) {
        this.listCustomerSubType = (selectedCustomerType === CUSTOMER_TYPE_AMAZON_DSP) ? CUSTOMER_TYPE_SUBTYPE_AMAZON_DSP : CUSTOMER_TYPE_SUBTYPE_FEDEX_ISP
        // Dynamically update the list of Customer Sub-Types based on the selected type
        if (selectedCustomerType === 'Amazon DSP') {
          this.listCustomerSubType = CUSTOMER_TYPE_SUBTYPE_AMAZON_DSP;
        } else if (selectedCustomerType === 'FedEx ISP') {
          this.listCustomerSubType = CUSTOMER_TYPE_SUBTYPE_FEDEX_ISP;
        } else {
          this.listCustomerSubType = [];
        }

        // Reset customerSubType if it doesn't match new options
        if (!this.listCustomerSubType.includes(this.tenantData.customerSubType)) {
          this.tenantData.customerSubType = null; 
        }
      },
      showBillingWarningDialog(message, billingType) {
        this.$confirm(message, 'Warning', {
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.isBillingWarningDialogOpen = false;
        }).catch(() => {
          this.isBillingWarningDialogOpen = false;
          // If user cancels, reset the value back to its previous state
          this.tenantData.flatMonthlyBillingAmount = billingType === 'flat-rate' ? 0 : this.originalBillingValue;
        });
      },
    },
  }
</script>

