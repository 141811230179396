export const injuriesByGroup = /* GraphQL */ `
query InjuriesByGroup(
  $group: String
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInjuryFilterInput
  $limit: Int
  $nextToken: String
) {
  injuriesByGroup(
    group: $group
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByTitle
      completedByPhone
      driverHireDate
      driverDOB
      driverGender
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
        }
      }
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        photo
        onboardingPinned
        receiveTextMessages
        receiveEmailMessages
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;

export const staffsByGroup = /* GraphQL */ `
query StaffsByGroup(
  $group: String
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroup(
    group: $group
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      vehicleType
      transporterId
      firstName
      lastName
      keyFocusArea
      dob
      gender
      hireDate
      phone
      status
      createdAt
      email
      dlExpiration
      vehicleReport
      gasCardPin
      hireDate
      updatedAt
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          createdAt
          updatedAt
        }
        nextToken
      }
      pronouns
      isALead
      assignedLead
      hourlyStatus
      hireDate
      finalCheckIssueDate
    }
    nextToken
  }
}
`;

export const staffsByGroupDetails = /* GraphQL */ `
query StaffsByGroup(
  $group: String
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroup(
    group: $group
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      status
      assignedLead
    }
    nextToken
  }
}
`;

export const staffsDlExpirationDate = /* GraphQL */ `
query StaffsByGroup(
  $group: String
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroup(
    group: $group
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      dlExpiration
      dob
      gender
      hireDate
      updatedAt
    }
    nextToken
  }
}
`;

export const staffsDlExpirationDateByGroupAndStatus = /* GraphQL */ `
query StaffsByGroupStatus(
  $group: String
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroupStatus(
    group: $group
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      dlExpiration
      dob
      gender
      hireDate
      updatedAt
    }
    nextToken
  }
}
`;

export const staffsExpirationVehicleReport = /* GraphQL */ `
query StaffsByGroupStatus(
  $group: String
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroupStatus(
    group: $group
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      vehicleReport
    }
    nextToken
  }
}
`;

export const physicalExpirationDate = /* GraphQL */ `
  query PhysicalsByGroup(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhysicalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    physicalsByGroup(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        date
        expirationDate
        staff {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const vehiclesByGroup = /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        updatedAt
        defaultStaff {
          nextToken       
        }
        defaultStaff2 {
          nextToken
        }
        defaultStaff3 {
          nextToken
        }
        vehicleType {
          id
          group
          option
        }
      }
      nextToken
    }
  }
`;

export const getInjury = /* GraphQL */ `
  query GetInjury($id: ID!) {
    getInjury(id: $id) {
      id
      group
      caseNumber
      injuryDate
      injuryTime
      injuryTimeIsUnknown
      timeStaffStartedWork
      completedBy
      completedByPhone
      completedByTitle
      driverGender
      driverDOB
      driverHireDate
      driverAddress
      driverCity
      driverState
      driverZip
      physicianName
      physicianFacility
      physicianAddress
      physicianCity
      physicianState
      physicianZip
      wasTreatedInER
      wasHospitalizedOvernight
      descriptionBeforeAccident
      descriptionInjury
      descriptionIncident
      descriptionDirectHarmCause
      injuryType
      notes
      dateOfDeath
      caseNumberFromLog
      staffId
      createdAt
      updatedAt
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        photo
        onboardingPinned
        receiveTextMessages
        receiveEmailMessages
        createdAt
        updatedAt
        accidents {
          nextToken
        }
        counselings {
          nextToken
        }
        documents {
          nextToken
        }
        drugTests {
          nextToken
        }
        infractions {
          nextToken
        }
        injuries {
          nextToken
        }
        messages {
          nextToken
        }
        onBoarding {
          nextToken
        }
        physicals {
          nextToken
        }
        podQualities {
          nextToken
        }
        route {
          nextToken
        }
        routeHelper {
          nextToken
        }
        routeRescuer {
          nextToken
        }
        cxFeedback {
          nextToken
        }
        mentor {
          nextToken
        }
        scoreCards {
          nextToken
        }
        staffStatusHistory {
          nextToken
        }
        uniforms {
          nextToken
        }
        defaultVehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          company {
            id
            option
          }
          parkingSpace {
            id
            group
            order
            option
          }
          createdAt
          updatedAt
        }
        defaultVehicle2 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          company {
            id
            option
          }
          parkingSpace {
            id
            group
            order
            option
          }
          createdAt
          updatedAt
        }
        defaultVehicle3 {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          ownership
          provider
          otherProvider
          category
          vin
          make
          model
          year
          rentalAgreementNumber
          images
          rentalContracts
          accidentReports
          licensePlate
          mileage
          gasCard
          status
          company {
            id
            option
          }
          parkingSpace {
            id
            group
            order
            option
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      phone
      email
      emailAddedToSuppressionListDateTime
      coachingOpportunity
      status
      dlExpiration
      vehicleReport
      gasCardPin
      dob
      gender
      pronouns
      assignedLead
      isALead
      hourlyStatus
      hireDate
      terminationDate
      finalCheckIssueDate
      returnedUniform
      latestScorecard
      notes
      vehicleType
      defaultVehicle {
        id
        group
        name
        status
        updatedAt
      }
      defaultVehicle2 {
        id
        group
        name
        status
        updatedAt
      }
      defaultVehicle3 {
        id
        group
        name
        status
      }
      mentionedInNotes {
        items {
          id
          group
          date
          note {
            id
            type
            text
            dailyRoster {
              id
              notesDate
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      photo
      onboardingPinned
      accidents {
        items {
          group
          id
          accidentDate
          accidentType
          drugTestDate
          drugTestResult
          updatedAt
          verifiedBy{
            id
            firstName
            lastName
          }
          staff{
            id
            firstName
            lastName
          }
          vehicle{
            id
            name
          }
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      counselings{
        items{
          id
          date
          dateSent
          severity {
            id
            option
          }
          refusedToSign
          counselingNotes
          priorDiscussionSummary
          correctiveActionSummary
          consequencesOfFailure
          employeeNotes
          status
          signature
          dateSigned
          updatedAt
          staff{
            id
            firstName
            lastName
          }
          user{
            id
            firstName
            lastName
          }
          infractions{
            items{
              infractionType
              date
              time
              comment
            }
          }
          images {
            items {
              id
              key
              type
              name
            }
          }
        }
      }
      drugTests {
        items {
          group
          id
          date
          location
          results
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      injuries {
        items {
          group
          id
          injuryDate
          injuryType
          physicianName
          notes
          updatedAt
        }
        nextToken
      }
      infractions{
        items{
          id
          updatedAt
        }
        nextToken
      }
      messages{
        items{
          id
        }
        nextToken
      }
      onBoarding {
        items {
          id
          group
          name
          isComplete
          status
          dateComplete
          dateStart
          date3
          date4
          date5
          trainer
          createdAt
          updatedAt
        }
        nextToken
      }
      physicals {
        items {
          id
          group
          date
          expirationDate
          file
          fileName
          createdAt
          updatedAt
        }
        nextToken
      }
      route{
        items{
          id
          rescued
          updatedAt
          dailyRoster{
            notesDate
          }
          rescuer{
            id
            firstName
            lastName
          }
          rescuers {
            items {
              id
              staff {
                firstName
                lastName
              }
            }
          }
        }
      }
      routeHelper{
        items{
          id
        }
        nextToken
      }
      routeRescuer{
        items{
          id
          dailyRoster{
            notesDate
          }
          staff{
            id
            firstName
            lastName
          }
        }
      }
      routeRescuerStaff{
        items{
          id
          staff{
            id
            firstName
            lastName
          }
          route {
            id
            dailyRoster{
              notesDate
            }
            staff {
              id
              firstName
              lastName
            }
          }
        }
      }
      uniforms {
        items {
          id
          group
          qty
          issueDate
          returnedDate
          createdAt
          updatedAt
          size{
            id
            value
          }
          uniformType{
            id
            value
          }
        }
        nextToken
      }
      scoreCards {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          transporterId
          overallTier
          delivered
          keyFocusArea
          ficoScore
          seatbeltOffRate
          ced
          cdfDpmo
          dcr
          dar
          dsb
          swcPod
          swcCc
          swcSc
          swcAd
          dnrs
          podOpps
          ccOpps
          speedingEventRate
          distractionsRate
          followingDistanceRate
          signSignalViolationsRate
          createdAt
          updatedAt
        }
        nextToken
      }
      mentor {
        items {
          id
          group
          name
          date
          station
          trips
          miles
          time
          fico
          accel
          braking
          cornering
          speeding
          distraction
          seatbelt
          backUp
          sse
          mpg
          idling
          engineOff
          preDvcr
          postDvcr
          trainingAssigned
          trainingCompleted
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          group
          labelId
          label {
            id
            name
            status
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cxFeedback {
        items {
          id
          group
          matched
          matchedS
          week
          year
          name
          transporterId
          positiveFeedback
          negativeFeedback
          deliveryWasGreat
          deliveryWasntGreat
          totalDeliveries
          respectfulOfProperty
          followedInstructions
          friendly
          aboveAndBeyond
          deliveredWithCare
          careForOthers
          mishandledPackage
          drivingUnsafely
          driverUnprofessional
          notDeliveredToPreferredLocation
          createdAt
          updatedAt
        }
        nextToken
      }
      staffStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      receiveTextMessages
      receiveEmailMessages
      messagePreferencesHistory {
        items {
          id
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
        }
        nextToken
      }
      podQualities {
        items {
          id
          group
          matched
          matchedS
          week
          year
          employeeName
          transporterId
          blurry
          explicit
          mailSlot
          noPackage
          other
          opportunities
          success
          bypass
          packageInHand
          notClearlyVisible
          packageTooClose
          personInPhoto
          photoTooDark
          takenFromCar
          grandTotal
          createdAt
          updatedAt
        }
        nextToken
      }
      properParkingSequences {
        items {
          id
          week
          year
          date
          employeeName
          transporterId
          evaluatedStops
          compliance
          percentCompliance
          invalid
          percentInvalid
          missingGearInPark
          percentMissingGearInPark
          missingParkingBrake
          percentMissingParkingBrake
          totalBreaches
          percentTotalBreaches
        }
        nextToken
      }
      eocScores {
        items {
          id
          group
          date
          average
          averageDailyCompliance
          level
          textractJobId
          createdAt
          updatedAt
        }
        nextToken
      }
      authorizedToDrive {
        items {
          id
          group
          staff {
            id
          }
          optionCustomList {
            id
            option
          }
        }
        nextToken
      }
      preferredDaysOff
      createdAt
      updatedAt
      onboardingNotes
    }
  }
`;

export const getStaffPreferences = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      firstName
      lastName
      emailAddedToSuppressionListDateTime
      receiveTextMessages
      receiveEmailMessages
      alternateNames
      updatedAt
      messagePreferencesHistory {
        items {
          id
          messagePreferenceType
          description
          datetime
          messagePreferencesHistoryStaffId
        }
        nextToken
      }
    }
  }
`;

export const staffScoreCardsByGroup = /* GraphQL */ `
  query StaffScoreCardsByGroup(
    $group: String
    $yearWeek: ModelStaffScoreCardByGroupCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScoreCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffScoreCardsByGroup(
      group: $group
      yearWeek: $yearWeek
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        week
        year
        overallTier
        updatedAt
      }
      nextToken
    }
  }
`;

export const messagesByStaffAndMessageType = /* GraphQL */ `
  query MessagesByStaffAndMessageType(
    $staffId: ID
    $messageTypeCreatedAt: ModelMessageByStaffAndMessageTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByStaffAndMessageType(
      staffId: $staffId
      messageTypeCreatedAt: $messageTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        staffId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        updatedAt
        
      }
      nextToken
    }
  }
`;

export const staffsByEmail = /* GraphQL */ `
  query StaffsByEmail(
    $email: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByEmail(
      email: $email
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
      }
      nextToken
    }
  }
`;

export const staffsByPhone = /* GraphQL */ `
  query StaffsByPhone(
    $phone: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByPhone(
      phone: $phone
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
      }
      nextToken
    }
  }
`;

export const staffsByTransporterId = /* GraphQL */ `
  query StaffsByTransporterId(
    $transporterId: String
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByTransporterId(
      transporterId: $transporterId
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
      }
      nextToken
    }
  }
`;


export const accidentsByStaffAndHistoryType = /* GraphQL */ `
  query AccidentsByStaffAndHistoryType(
    $staffId: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByStaffByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByStaffAndHistoryType(
      staffId: $staffId
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        group
        id
        accidentDate
        accidentType
        drugTestDate
        drugTestResult
        updatedAt
        staff {
          id
          firstName
          lastName
        }
        verifiedBy {
          id
          firstName
          lastName
        }
        vehicle {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const staffsTransporterIdAndEmail = /* GraphQL */ `
  query StaffsByGroup(
    $group: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroup(
      group: $group
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transporterId
        email
        authorizedToDrive {
          items {
            id
            optionCustomList {
              id
              option
            }
          }
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const staffNetradyneAlertsByStaff = /* GraphQL */ `
  query StaffNetradyneAlertsByStaff(
    $staffId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffNetradyneAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffNetradyneAlertsByStaff(
      staffId: $staffId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        staffId
        matched
        matchedS
        driverName
        driverId
        groupName
        vehicleNumber
        alertId
        timestamp
        alertType
        alertSeverity
        description
        alertVideoStatus
        duration
        startLatLong
        endLatLong
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const staffNetradyneAlertsByAlertsInDateRange = /* GraphQL */ `
  query StaffNetradyneAlertsByAlertsInDateRange(
    $group: String
    $staffIdAlertTypeAlertSeverityTimestamp: ModelStaffNetradyneAlertByAlertsInDateRangeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffNetradyneAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffNetradyneAlertsByAlertsInDateRange(
      group: $group
      staffIdAlertTypeAlertSeverityTimestamp: $staffIdAlertTypeAlertSeverityTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        staffId
        matched
        matchedS
        driverName
        driverId
        groupName
        vehicleNumber
        alertId
        timestamp
        alertType
        alertSeverity
        description
        alertVideoStatus
        duration
        startLatLong
        endLatLong
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
        }
      }
      nextToken
    }
  }
`;


export const staffNetradyneAlertsByAlertsByGroupInDateRange = /* GraphQL */ `
  query StaffNetradyneAlertsByAlertsByGroupInDateRange(
    $group: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffNetradyneAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffNetradyneAlertsByAlertsByGroupInDateRange(
      group: $group
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        staffId
        alertSeverity
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
        }
      }
      nextToken
    }
  }
`;

export const staffsByGroupStatus = /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        status
        alternateNames
        phone
        email
        receiveTextMessages
        receiveEmailMessages
        updatedAt
        group
        messagePreferencesHistory {
          items {
            id
            messagePreferenceType
            description
            datetime
            messagePreferencesHistoryStaffId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const dailyRostersByGroupAndNotesDate = /* GraphQL */ `
  query DailyRostersByGroupAndNotesDate(
    $group: String
    $notesDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyRostersByGroupAndNotesDate(
      group: $group
      notesDate: $notesDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        route {
          items {
            id
            time
            staff {
              id
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const pendingMessagesByGroup = /* GraphQL */ `
  query PendingMessagesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPendingMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pendingMessagesByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isRead
        staff {
          id
          firstName
        }
      }
      nextToken
    }
  }
`;

export const getOptionsCustomListsStaff = /* GraphQL */ `
  query GetOptionsCustomListsStaff($id: ID!) {
    getOptionsCustomListsStaff(id: $id) {
      id
      group
      optionCustomList {
        id
        group
        order
        option
      }
    }
  }
`;

export const infractionsByStaff = /* GraphQL */ `
  query InfractionsByStaff(
    $staffId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByStaff(
      staffId: $staffId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        infractionType
        comment
        date
        time
        staffId
        station
        trackingNumber
        infractionTier
        infractionDescription
        appealDate
        resolvedDate
        appealStatus
        appealNotes
        miscNotes
        createdAt
        updatedAt
        staff {
          id
          firstName
          lastName
          alternateNames
          phone
          email
          status
          createdAt
          updatedAt
        }
        type {
          id
          option
        }
      }
      nextToken
    }
  }
`;


export const infractionsByGroupAndDate = /* GraphQL */ `
  query InfractionsByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        staffId
        date
        infractionType
        staff {
          id
          firstName
          lastName
          status
        }
        type {
          id
          option
        }
      }
      nextToken
    }
  }
`;