<template>
    <router-link 
    v-if="hasStaffManagement"
    class="nav-button h-12 md:h-30 rounded-none md:pt-3 md:pb-3 relative" 
    :to="{name: messengerLink}"
    >
        <i class="py-0 text-3xl md:text-3md block h-5 md:h-10 mb-2 uil uil-comment-alt-dots"></i>
        <span v-if="notificationCount" class="text-white notification-bubble">{{notificationCount}}</span>
        <span class="text-2sm leading-1 hidden md:block nav-title leading-5"><slot>Hera <br/>Messenger</slot></span>
    </router-link>
    <el-popover 
        v-else       
        class="opacity-50 nav-button h-12 md:h-30 rounded-none md:pt-3 md:pb-3 relative"
        placement="bottom"
        title="Premium Required"
        width="215"
        trigger="hover"
        content=""
        >
        <div slot="reference">
            <i class="py-0 text-3xl md:text-3md block h-5 md:h-10 mb-2 uil uil-comment-alt-dots"></i>
            <span class="text-2sm leading-1 hidden md:block nav-title leading-5"><slot>Hera <br/>Messenger</slot></span>
        </div> 
        <PremiumWarning
            action="use Hera messenger"
            premiumFeature="Associate Management">
        </PremiumWarning>
    </el-popover>
</template>


<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import PremiumWarning from '@/components/PremiumWarning'
import { API } from 'aws-amplify'

export default {
    components: {
        PremiumWarning
    },
    computed:{
        ...mapState(['messengerNotifications', 'userInfo']),
        ...mapGetters(['hasStaffManagement']),
        notificationCount(){
            return this.messengerNotifications
        },
        messengerLink(){
            return window.innerWidth <= 768 ? 'MobileMessengerList' : 'Messenger';
        }
    },
    async created() {
        await this.fetchMessengerNotifications();
    },
    methods: {
        ...mapMutations(['setMessengerNotifications']),
        async fetchMessengerNotifications() {
            if (this.hasStaffManagement) {
                try {
                    const resp = await API.get('messages', `/get-staffs-unread-messages/${this.userInfo?.tenant?.group}`);
                    this.setMessengerNotifications(resp.data.length);
                } catch (error) {
                    console.error('Error loading unread messages count:', error);
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.main-nav .notification-bubble {
    margin-top: -37px;
    margin-left: calc(50% + 6px);
    
    
}
@media only screen and (max-width: 768px) {
    .main-nav .notification-bubble {
        margin-top: -15px;
    }
}
[class*=" el-icon-"], [class^=el-icon-] {
    line-height: inherit !important;
    display: block !important;
}

</style>