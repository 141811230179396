<template>
  <div>
    <UserList v-loading="loading" :users="users" :tenantId="userInfo.tenant.id" :group="userInfo.tenant.group" :companyName="userInfo.tenant.companyName" @loadData="loadData"/>
  </div>
</template>

<script>
import UserList from './components/UserList'
import { mapState } from 'vuex'
import { getTenant } from '@/views/Accident/queries.js'
import { Auth, API } from 'aws-amplify';
import { updateUser } from '@/graphql/mutations'

export default {
  name: "UserIndex",
  components:{ UserList },
  data(){
    return{
    USER_PERMISSIONS: process.env.VUE_APP_USER_PERMISSIONS === "1",
      users:[],
      loading: false
    }
  },

  mounted(){
    this.loadData()
  },

  computed:{
    ...mapState(['userInfo'])
  },

  methods:{
    /** Load user data */
    async loadData(){
      this.loading = true
      try{
        var tenantId = this.userInfo.tenant.id;
        let tenant = await this.api(getTenant, { id: tenantId })
        tenant.data.getTenant.users.items = tenant.data.getTenant.users.items.filter(user => !(user.email.startsWith('sysadmin-') && user.email.endsWith('@herasolutions.info')))
        let hasCreator = tenant.data.getTenant.users.items.find(user => user.isCreator)
        let hasOwner = tenant.data.getTenant.users.items.find(user => user.isOwner)
        if(tenant.data.getTenant.users.items.length > 0) {
          if (!hasCreator || !hasOwner) {
            let dates = tenant.data.getTenant.users.items.map((user) => {
              return new Date(user.createdAt)
            })
            let oldDate = new Date(Math.min(...dates))
            let firstUser = tenant.data.getTenant.users.items.find((user) => oldDate.getTime() === new Date(user.createdAt).getTime())
            firstUser.isCreator = hasCreator ? false : true
            firstUser.isOwner = hasOwner ? false : true
            await this.api(updateUser, {input: firstUser})
          }
        }
        if(this.USER_PERMISSIONS){
            this.users = await Promise.all(tenant.data.getTenant.users.items.map(async (user) => {
                let newUserLastLogin = user.lastLogin
                if (!user.lastLogin) {
                  const cognitoUser = await this.getCognitoUser(user.email)
                  if (cognitoUser.UserStatus === 'FORCE_CHANGE_PASSWORD') {
                    newUserLastLogin = 'NOT_YET_LOGGED'
                  } else {
                    newUserLastLogin = new Date('1970/01/01').toISOString()
                  }
                  await this.api(updateUser, {input: { id: user.id, lastLogin: newUserLastLogin }})
                }
                return {
                    ...user,
                    permissionAccidentsString: user.permissionAccidents ? 'Accident Records' : '' ,
                    permissionCounselingsString: user.permissionCounselings ? 'Counselings' : '' ,
                    permissionDocumentsString: user.permissionDocuments ? 'DA Documents' : '' ,
                    permissionDrugTestsString: user.permissionDrugTests ? 'Drug Tests' : '' ,
                    permissionFullAccessString: user.permissionFullAccess ? 'Full Access' : '' ,
                    permissionInjuriesString: user.permissionInjuries ? 'Injury Records' : '' ,
                    permissionLoginString: user.permissionLogin ? 'Login' : '',
                    lastLogin: newUserLastLogin
                }
            }))
        }
        else{
            this.users = tenant.data.getTenant.users.items
        }
        this.loading = false
      }catch(e){
        console.error(e)
        this.loading = false
        this.displayUserError(e)
      }
    },

    /** 
     * Gets a single cognito user
     * @param {string} username 
     * */
    async getCognitoUser(username){
        let apiName = 'AdminQueries';
        let path = '/getUser';
        let myInit = {
            queryStringParameters: {
            "username": username,
            }, 
            headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        var result = await API.get(apiName, path, myInit);
        return result
    },
  }
  
}
</script>