import { render, staticRenderFns } from "./OrderSelect.vue?vue&type=template&id=2788039a&scoped=true&"
import script from "./OrderSelect.vue?vue&type=script&lang=js&"
export * from "./OrderSelect.vue?vue&type=script&lang=js&"
import style0 from "./OrderSelect.vue?vue&type=style&index=0&id=2788039a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2788039a",
  null
  
)

export default component.exports