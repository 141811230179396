<template>
    <span>
        <el-dropdown class="hidden md:block">
            <button class="text-center text-gray-600 hover:text-blue-500 pr-1 hidden md:block" :class="{'text-brand': notificationCount}">
                    <i class="uil uil-bell text-2xl"></i>
                    <span v-if="notificationCount" class="notification-bubble">{{notificationCount}}</span>
                    <!-- <span v-if="notificationCount" class="animate-ping absolute -mt-8 ml-5 rounded-full bg-pink-400 opacity-75 w-4 h-4 block text-xs text-white"></span>
                    <span v-if="notificationCount" class="absolute -mt-8 ml-5 rounded-full px-1 bg-pink-500 w-auto h-4 block text-xs text-white">{{notificationCount}}</span> -->
            </button>  
            <el-dropdown-menu slot="dropdown" class="mt-0 w-120 hidden md:block pt-0">
                <div class="flex justify-center mt-0 space-x-14 px-2 md:px-0 w-full">
                    <el-radio-group v-model="toggleNotifications" size="small" class="w-full inline-block">
                        <el-radio-button class="focus:outline-none w-1/2" label="Notifications" style="display: inline-grid !important">{{notificationRegularCount > 0 ? 'Notifications (' + notificationRegularCount +')' : 'Notifications'}}</el-radio-button>
                        <el-radio-button class="focus:outline-none w-1/2" label="Hera Version History" style="display: inline-grid !important">{{notificationNewVersionCount > 0 ? 'Hera Version History (' + notificationNewVersionCount + ')' : 'Hera Version History'}}</el-radio-button>
                    </el-radio-group>
                </div>
                <template v-if="toggleNotifications === 'Notifications' ? 'Notifications' : 'Hera Version History'">
                    <div v-if="notificationCount" class="divide-y overflow-y-auto" style="max-height: 400px">
                        <div data-cy="notification-row" class="flex items-center justify-between px-3 py-2" v-for="notification in (toggleNotifications === 'Notifications' ? displayRegularNotifications : displayNewVersionNotifications)" :key="notification.id">
                            <button data-cy="n-perform-action-btn" @click="performAction(notification)" class="text-left hover:text-brand">
                                <p data-cy="n-title" class="text-sm">{{notification.title}}</p>
                                <p data-cy="n-description" class="text-xs text-gray-600 preline" v-html="notification.description"></p>                    
                                <div data-cy="n-release-notes" v-if="notification.releaseNotes" class="text-xs text-gray-600 whitespace-pre-line">{{notification.releaseNotes}}</div>                    
                            </button>
                            <button data-cy="n-dismiss-btn" @click="dismiss(notification)" class="px-2">
                                <i class="uil uil-multiply ml-2 text-gray-600 hover:text-red-600"></i>
                            </button>
                        </div>
                    </div>
                    <div v-else class="p-3 text-center w-full">
                        <i class="text-6xl mb-4 text-gray-600 uil uil-bell"/>
                        <p class="text-sm text-gray-700">No Unread Notifications</p>
                    </div>
                    <p class=" px-3 pt-3 pb-1 text-center border-t flex justify-around">
                        <button data-cy="n-dismiss-all-btn" v-if="notificationCount" @click="dismissAll()" class="text-xs  text-gray-600 hover:text-red-600" href="#">{{toggleNotifications === 'Notifications' ? 'Dismiss All Notifications' : 'Dismiss All Version History'}}</button>
                        <a data-cy="n-view-all-btn" class="text-xs link"  @click="$router.push({name: 'NotificationIndex', params: {optionID: filteredOptionID}})">{{toggleNotifications === 'Notifications' ? 'View All Notifications' : 'View All Version History'}}</a>
                    </p>
                </template>                
            </el-dropdown-menu>
            
        </el-dropdown>
        <span class="rounded-full bg-pink-500 h-4 py-0.5  px-1 text-xs text-white mr-2 md:hidden">{{notificationCount}}</span>
    </span>
</template>

<script>
import {mapState} from 'vuex'
import EventBus from '@/eventBus'
import { HIDDEN } from '@/utilities/constants/notificationTypes'
import { sortByDateProp } from '@/utilities/sortHelpers'
import { updateUserNotification, updateNotification } from '@/store/mutations'

export default {

    data(){
        return{
            USER_PERMISSIONS: process.env.VUE_APP_USER_PERMISSIONS === "1",
            tabs: [
                {name: 'notifications', table: [], title: 'Notifications'},
                {name: 'heraVersionHistory', table: [], title: 'Hera Version History'}
            ],
            toggleNotifications: 'Notifications',
        }
    },

    mounted(){
        EventBus.$off('missing-phone-number');
        EventBus.$on('missing-phone-number', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "warning",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('sent-messages-successfully');
        EventBus.$on('sent-messages-successfully', async (payload) => {
            await this.displayUserNotification({
                title: payload.title,
                type: "success",
                duration: 0,
                message: payload.description
            })
            
            const notificationPayload = JSON.parse(payload.payload)
            const staffsWithoutPhone = notificationPayload.staffsWithoutPhone || []

            if(staffsWithoutPhone.length) {
                for (const staff of staffsWithoutPhone) {
                    const destinationName = `${staff.firstName} ${staff.lastName}`
                    const title = "Associate does not have a phone number"
                    const description = `${destinationName} does not have a phone number in their Associate profile. ${destinationName} did not receive this message. Please add their phone number to Hera then send this message again.`

                    await this.displayUserNotification({
                        title: title,
                        type: "warning",
                        duration: 0,
                        message: description
                    })
                }
            }
        })

        EventBus.$off('remove-all-associates');
        EventBus.$on('remove-all-associates', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "success",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('no-associates-to-remove');
        EventBus.$on('no-associates-to-remove', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "info",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('customlists-created-successfully')
        EventBus.$on('customlists-created-successfully', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "success",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('customlists-updates-successfully')
        EventBus.$on('customlists-updates-successfully', async (payload) => {
            
            this.loadCustomList()

            await this.displayUserNotification({
              title: payload.title,
              type: "success",
              duration: 0,
              message: payload.description
            })

            const notificationPayload = JSON.parse(payload.payload)

            const optionsCannotBeRemoved = notificationPayload.optionsCannotBeRemoved

            if (optionsCannotBeRemoved.length) {
                optionsCannotBeRemoved.forEach(async option => {
                    await this.displayUserNotification({
                        title: 'Warning',
                        type: "warning",
                        duration: 0,
                        message: `The ${option} option you are trying to remove is in use.`
                    })
                })
            }
        })

        EventBus.$off('customlists-created-error')
        EventBus.$on('customlists-created-error', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "warning",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('customlists-updates-error')
        EventBus.$on('customlists-updates-error', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "warning",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('override-resource-usage')
        EventBus.$on('override-resource-usage', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "info",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('note-updates-error')
        EventBus.$on('note-updates-error', async (payload) => {

            const notificationPayload = JSON.parse(payload.payload)
            EventBus.$emit('update-note', notificationPayload.note)

            await this.displayUserNotification({
              title: payload.title,
              type: "warning",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('counseling-table-updates')
        EventBus.$on('counseling-table-updates', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "success",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('counseling-table-updates-error')
        EventBus.$on('counseling-table-updates-error', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "warning",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('tenant-table-updates')
        EventBus.$on('tenant-table-updates', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "success",
              duration: 0,
              message: payload.description
            })
        })

        EventBus.$off('tenant-table-updates-error')
        EventBus.$on('tenant-table-updates-error', async (payload) => {
            await this.displayUserNotification({
              title: payload.title,
              type: "warning",
              duration: 0,
              message: payload.description
            })
        })
    },

    beforeDestroy(){
        EventBus.$off('missing-phone-number')
        EventBus.$off('sent-messages-successfully')
        EventBus.$off('remove-all-associates')
        EventBus.$off('no-associates-to-remove')
        EventBus.$off('customlists-created-successfully')
        EventBus.$off('customlists-updates-successfully')
        EventBus.$off('customlists-created-error')
        EventBus.$off('customlists-updates-error')
        EventBus.$off('override-resource-usage')
        EventBus.$off('note-updates-error')
        EventBus.$off('counseling-table-updates')
        EventBus.$off('counseling-table-updates-error')
        EventBus.$off('tenant-table-updates')
        EventBus.$off('tenant-table-updates-error')
    },

    watch: {
        notificationCount(newVal, oldVal){
            if(newVal > 0 && this.$store.state.notifications){
                // trigger reload of performance screen
                 EventBus.$emit('load-performance')
            }
        }
    },

    computed:{
        ...mapState(['notifications' , 'userNotifications']),
        filteredOptionID(){
            if(this.toggleNotifications === "Hera Version History"){
                return 'heraVersion'
            }
            return 'general'
        },
        notificationCount(){
            return this.displayNotifications.length
        },

        notificationRegularCount() {
            return this.displayRegularNotifications.length
        },

        notificationNewVersionCount() {
            return this.displayNewVersionNotifications.length
        },

        displayNotifications(){
            // filter out invoie and permissions notifications
            const userNotifications = this.userNotifications.filter(item => item.notificationType != HIDDEN)
            if((!this.USER_PERMISSIONS && !this.$store.getters.isAdmin) || (this.USER_PERMISSIONS && !this.$store.state.userInfo.permissionFullAccess)){
                const notificationFiletered = this.notifications.filter((notification) => { return !notification.description?.includes('invoice') && !notification.description?.includes('user permissions')})
                return userNotifications.concat(notificationFiletered).sort(sortByDateProp('createdAt', 'descending'))
            }
            const notificationFiletered = this.notifications.filter((notification) => { return !notification.description?.includes('user permissions')})
                .filter(notification => notification.isReadS == "false")

            const sortedNotifications = userNotifications.concat(notificationFiletered).sort(sortByDateProp('createdAt', 'descending'))
            return sortedNotifications
        },

        displayRegularNotifications() {
            return this.displayNotifications.filter((notification) => notification.title !== 'NEW VERSION')
        },

        displayNewVersionNotifications() {
            return this.displayNotifications.filter((notification) => notification.title === 'NEW VERSION')
        }
    },
    methods:{
        performAction(notification){
            const payload = JSON.parse(notification.payload)
            if(notification.clickAction == "navigate"){
                if(payload?.name === 'AmazonImport') payload.name = 'DailyAmazonImport'
                this.dismiss(notification)
                if (this.$route.name !== payload.name) this.$router.push(payload)
            }
            else if(notification.clickAction == "dismiss" || notification.clickAction == 'LOGOUT' || notification.clickAction == 'RELOAD'){
                this.dismiss(notification)
            }
            else if (notification.clickAction == "redirect") {
                //const payload = JSON.parse(notification.payload);
                window.open(payload.redirectLink, '_blank');
            }
            else if(payload.name && payload.params){
                this.$router.push({name:payload.name, params: payload.params })
            }
            else if(payload.redirectLink){
                window.open(payload.redirectLink, '_blank');
            }
            else{
                //unknown action

            }
        },
        async dismissAll(){
            let displayNotificationOption = this.toggleNotifications === 'Notifications' ? this.displayRegularNotifications : this.displayNewVersionNotifications
            // return console.log('version data', d)
            var notifications = displayNotificationOption
            try{
                await this.$confirm(`Are you sure you want to dismiss these ${notifications.length} notifications?`, 'Warning',
                    {
                        confirmButtonText: 'Dismiss',
                        type: 'warning'
                    }
                )
                notifications.forEach(async(notification)=>{
                    await this.dismiss(notification)
                })
            }catch(e){
                if(e != 'cancel'){
                    this.displayUserError("Could not dismiss all notifications")
                }
            }
        },
        async dismiss(notification){
            if(notification.userId){
                //user notification
                let input = {
                    isRead: "true",
                    id: notification.id
                }
                await this.api(updateUserNotification, {input})
                this.$store.commit('removeUserNotification', notification.id)
            }else{
                let input = {
                    isReadS: "true",
                    id: notification.id
                }
                await this.api(updateNotification, {input})
                this.$store.commit('removeNotification', notification.id)
            }
        }
    }  
}
</script>
<style scoped>
    .mt-0{
        margin-top: 0rem !important;
    }
    .pt-0{
        padding-top: 0rem !important;
    }
    .preline {
        white-space: pre-line;
    }
</style>