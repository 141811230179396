<template>
  <div data-cy="tenants-container">
    <div class="flex items-center justify-between mb-4">
      <h1>Tenants</h1>
      <div class="flex space-x-2">
          <!-- <el-button size="small" type="primary" @click="listCognitoUsers">List Users</el-button> -->
          <!-- <el-button size="small" type="primary" @click="originationModalOpen = true">Update Tenants' Phone #</el-button> -->
          <div class="flex flex-wrap gap-1 justify-end sm:justify-center">
              <el-button size="small" type="primary" @click="openForceRefresh">Force Client Refresh</el-button>
              <el-button size="small" type="primary" @click="modalOpen = true">Add Tenant</el-button>
              <!-- <el-button size="small" type="primary" @click="dataFixModalOpen = true">Data Fixes</el-button> -->
            </div>
          <!-- <el-button size="small" type="primary" @click="modalOpen = true">Add Tenant</el-button> -->
          
        <div>


            <!-- DEACTIVATE MODAL -->
            <DeactivateTenantModal 
              @close="deactivateModalOpen=false"
              :deactivateModalOpen="deactivateModalOpen" :deactivateTenantLoading="deactivateTenantLoading" :deactivateTenantMessage="deactivateTenantMessage">
            </DeactivateTenantModal>

            <!-- ORIGINATION MODAL -->
            <el-dialog
              :close-on-click-modal="false"
              title="Update All Tenants' Origination Numbers"
              :visible.sync="originationModalOpen"
              width="30%"
              >

              <el-form label-position="top">
                <el-form-item label="Origination Number">
                  <el-input v-model="originationNumberTemp" placeholder="+13342928628"></el-input>
                </el-form-item>
              </el-form>


              <span slot="footer" class="dialog-footer">
                <el-button @click="originationModalOpen = false">Cancel</el-button>
                <el-button type="primary" @click="updateTenantsOrigination" :disabled="loading">Confirm</el-button>
              </span>
            </el-dialog>

            <!-- DATA FIX -->
            <el-dialog
              :close-on-click-modal="false"
              title="Data Fixes"
              :visible.sync="dataFixModalOpen"
              width="30%"
              >

              <el-form label-position="top" class="flex-wrap space-y-2 space-x-2">
                <el-button v-if="true" size="small" type="primary" @click="staffStatusConfirmation">Value List</el-button>
                <el-button v-if="true" size="small" type="primary" @click="confirmUserNotificationUpate">Update User Notifications</el-button>
                <el-button v-if="true" size="small" type="primary" @click="staffVacationStatusConfirmation">Vacation Value List</el-button>
                <el-button v-if="true" size="small" type="primary" @click="staffPendingMessageConfirmation">Pending Message Staff ID Index</el-button>
                <el-button v-if="true" size="small" type="primary" @click="confirmDefaultCoaching">Set Default coaching</el-button>
                <el-button v-if="true" size="small" type="primary" @click="confirmSetPermissions">Set Permissions from Roles</el-button>
                <el-button v-if="true" size="small" type="primary" @click="daStatusConfirmation">Set {{$t('label.associates_')}} status</el-button>
                <el-button v-if="true" size="small" type="primary" @click="deleteI9">Update documents type</el-button>

                <el-button v-if="true" size="small" type="primary" @click="generateReport()">Report {{$t('label.associate')}} Status</el-button>
                <el-button v-if="true" size="small" type="primary" @click="setMessageService">Set message service type</el-button>
                <el-button size="small" type="primary" @click="setDaTierCdfScore">Add DaTier and CdfScore to Coaching</el-button>
              </el-form>

              <span slot="footer" class="dialog-footer">
                <el-button @click="dataFixModalOpen = false">Cancel</el-button>
              </span>
            </el-dialog>

            <!-- CLIENT REFRESH MODAL -->
            <el-dialog
              :close-on-click-modal="false"
              title="Force Client Refresh"
              :visible.sync="refreshModalOpen"
              width="30%"
              >

              <el-form label-position="top">
                <el-form-item label="New Version Number">
                  <el-input v-model="newVersionNumber" placeholder="0.8.1"></el-input>
                </el-form-item>
                <p class="-mt-4 mb-2 text-xs italic text-gray-600">*Reload will only affect users who are not on the version specified.</p>

                <el-form-item label="Refresh Type">
                  <el-select v-model="refreshType" placeholder="Select" class="w-full">
                    <el-option
                      value="RELOAD"
                      label="Reload Users Browser">
                    </el-option>
                    <el-option
                      value="LOGOUT"
                      label="Log User Out and Reload Browser">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="Release Notes" >
                  <div class="w-full flex space-x-4">
                    <el-input-number class="flex-1" :min="1" v-model="numberOfBulletsToAdd"></el-input-number>
                    <el-button  class="flex-1" @click="addBullets">Add Bullets</el-button>
                  </div>
                  <el-input class="mt-4" type="textarea" :autosize="{ minRows: 4}" v-model="releaseNotes"></el-input>
                </el-form-item>
              </el-form>

              <span slot="footer" class="dialog-footer">
                <el-button @click="refreshModalOpen = false">Cancel</el-button>
                <el-button type="primary" @click="refreshClients" :disabled="loading">Confirm</el-button>
              </span>
            </el-dialog>


            <!-- NEW TENANT MODAL -->
            <el-dialog
              :close-on-click-modal="false"
              title="New Tenant"
              :visible.sync="modalOpen"
              width="30%"
              >

              <TenantForm  v-loading="loading" :formFields="newTenantForm" ref="tenantForm"/>

              <span slot="footer" class="dialog-footer">
                <el-button @click="modalOpen = false">Cancel</el-button>
                <el-button data-cy="confirm-tenant-action-btn" type="primary" @click="createTenant" :disabled="loading">Confirm</el-button>
              </span>
            </el-dialog>
        </div>
      </div>
    </div>

    <div class="grid grid-flow-row grid-cols-12 w-full py-2 gap-4">
      <div class="col-span-12 md:col-span-12 lg:col-span-12">
          <p :class="trialClassColorConflict">Dynamo - Trial Tenants without a Expired Trial Date: {{ trialWithoutExpDate.length }}</p>
          <ul class="list-disc list-inside mt-2">
            <li v-for="tenant in trialWithoutExpDate" class="text-red-500" :key="tenant.id">
              Company Name: "{{ tenant.companyName }}" (group: {{ tenant.group }}) (trialExpDate: "{{ tenant.trialExpDate }}")
            </li>
          </ul>
        </div>
    </div>
    <div class="grid grid-flow-row grid-cols-12 w-full py-2 gap-4">
      <div class="col-span-12 md:col-span-12 lg:col-span-12">
          <p :class="statusClassColorConflict">Dynamo - Tenants without Customer Status: {{ unknowTenants.length }}</p>
          <ul class="list-disc list-inside mt-2">
            <li v-for="tenant in unknowTenants" class="text-red-500" :key="tenant.id">
              Company Name: "{{ tenant.companyName }}" (group: {{ tenant.group }})
            </li>
          </ul>
        </div>
    </div>
    <div class="grid grid-flow-row grid-cols-12 w-full py-2 gap-4">
      <div class="col-span-12 md:col-span-12 lg:col-span-12">
        <p :class="totalClassColorConflict">Dynamo - Total of Tenants : {{ dynamoTenantLength }}</p>
      </div>
    </div>
    <br/>

    <!--TABLE CARD COMPONENT-->
    <table-card tablecard-class="rounded-lg">
      <template v-slot:table-filters>
          <div>
            <container-filter inTable>
              <template v-slot:title>
                <div class="text-center">
                    <span class="font-app uppercase font-title">Tenant List</span>
                  </div>
              </template>
              <template v-slot:filter-count>
                <counter-filter :counter="getResumeFilters" />
              </template>
              <template v-slot:filters>
                <MultiSelectFilter
                  type="status"
                  title="Customer Status"
                  filterAllTitle="Customer Statuses"
                  :options="filterStatus"
                  :selected="filterStatusChecked"
                  @update-filters="updateFilters"
                />  
                <MultiSelectFilter
                  type="type"
                  title="Type"
                  filterAllTitle="Customer Types"
                  :options="filterType"
                  :selected="filterTypeChecked"
                  @update-filters="updateFilters"
                />
                <MultiSelectFilter
                  type="subtype"
                  title="Sub-Type"
                  filterAllTitle="Customer Sub-Types"
                  :options="filterSubType"
                  :selected="filterSubTypeChecked"
                  @update-filters="updateFilters"
                />
                <MultiSelectFilter
                  type="time"
                  title="Permanence"
                  filterAllTitle="Account Permanence Options"
                  :options="filterTime"
                  :selected="filterTimeChecked"
                  @update-filters="updateFilters"
                />
                <MultiSelectFilter
                  type="country"
                  title="Country"
                  filterAllTitle="Countries"
                  :options="filterCountry"
                  :selected="filterCountryChecked"
                  @update-filters="updateFilters"
                />
                <MultiSelectFilter
                  type="testing"
                  title="Testing"
                  filterAllTitle="Account Testing Options"
                  :options="filterTesting"
                  :selected="filterTestingChecked"
                  @update-filters="updateFilters"
                />
                <SingleSelectFilter
                  type="balanceDueFilter"
                  title="Balance Due"
                  :options="balanceDueOptions"
                  :currentFilter="balanceDueSelected"
                  @update-options="updateOptions"
                />
                <MultiSelectFilter
                  type="featureAccess"
                  title="Feature Access"
                  filterAllTitle="Feature Access"
                  filterWordBeforeTitle="Any"
                  :options="filterFeatureAccess"
                  :selected="filterFeatureAccessChecked"
                  @update-filters="updateFilters"
                />
                <MultiSelectFilter
                  type="featureEnabled"
                  title="Features Enabled"
                  filterAllTitle="Features Enabled"
                  filterWordBeforeTitle="Any"
                  :options="filterFeatureEnabled"
                  :selected="filterFeatureEnabledChecked"
                  @update-filters="updateFilters"
                />            
              </template>
                  
            </container-filter>
          </div>
      </template>
      <template v-slot:table-searchbox>
        <search-box data-cy="filter_tenant" inTable @search-data="searchData"></search-box>
      </template>
      <custom-table
        inTable
        ref="table"
        v-loading="loading"
        :columns="cols"
        :data="tableData"
        emptyText="No Data"
        footerTable="total Tenants"
        :showFooterText="false"
        withTableMenu
        @click-menu="handleCommand" 
        :optionsHeader="optionsContextHeader"
        :filtersTotal="getResumeFilters"
        :totalRecords="allRecordsCount"   
        :totalFilteredRecords="totalTenants"
        :optionsRows="optionsContextRow"
        @sort-change="sortColumn"
        @export-data="handleExport"
        showDefaultExportOptions
        pagination
        :paginationCurrentPage="currentPage"
        :paginationPageSize="pageSize"
        @pagination-page-size-change="handleSizeChange"
        @pagination-page-change="pageChange"
        withSelect
        :selectionEntityLabels="['TENANT', 'TENANTS']"
        :selectionCustomActions="selectionActions"
        :useMultiPageSelect="true"
        :providedSelectedRows="providedSelectedRows"
        :selectAllHandler="loadMatchingIds"
        @selection-change="handleSelectionChange"
        >

        <template #companyName="row">
          <span @click="handleRowClick(row)" >{{ row.companyName }}</span>
        </template>
        <template #id="row">
          <el-tooltip :content="`Click to copy: ${row.id}`" placement="top">
            <i class="el-icon-document-copy" @click="copyID(row.id)"></i>
          </el-tooltip>
        </template>
        <template #trialExpDate="row">
          <span  @click="handleRowClick(row)">{{ row.trialExpDate | date }}</span>
        </template>
        <template #shortCode="row">
          <span @click="handleRowClick(row)" >{{ row.shortCode | text }}</span>
        </template>
        <template #messageServiceProvider="row">
          <span @click="handleRowClick(row)">{{ row.messageServiceProvider }}</span>
        </template>

        <template #status="row">
          <CellToolTip :value="row.customerStatus">
              <div slot-scope="{textValue}" class="flex">
                <span
                  class="font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial"
                >{{textValue}}</span>
              </div>
          </CellToolTip>
        </template>
        <template #firstInterestDateTime="row">
          <span  @click="handleRowClick(row)">{{ row.firstInterestDateTime | date }}</span>
        </template>
        <template #firstStartedTrialDateTime="row">
          <span  @click="handleRowClick(row)">{{ row.firstStartedTrialDateTime | date }}</span>
        </template>
        <template #firstConvertedToPaidDateTime="row">
          <span  @click="handleRowClick(row)">{{ row.firstConvertedToPaidDateTime | date }}</span>
        </template>
        <template #firstChurnedDateTime="row">
          <span  @click="handleRowClick(row)">{{ row.firstChurnedDateTime | date }}</span>
        </template>
        <template #lastPaidPositiveInvoiceLineItemDateTime="row">
          <span  @click="handleRowClick(row)">{{ row.lastPaidPositiveInvoiceLineItemDateTime | date }}</span>
        </template>
        <template #totalNumberOfMonthsPaidByTenant="row">
          <span  @click="handleRowClick(row)">{{ row.totalNumberOfMonthsPaidByTenant | text }}</span>
        </template>
        <template #lifetimePaymentTotal="row">
          <span  @click="handleRowClick(row)">{{ row.lifetimePaymentTotal ? '$' + row.lifetimePaymentTotal.toFixed(2).toString() : '—' | text}}</span>
        </template>
        <template #averageMonthlyInvoiceTotal="row">
          <span  @click="handleRowClick(row)">{{ row.averageMonthlyInvoiceTotal ? '$' + row.averageMonthlyInvoiceTotal.toFixed(2).toString() : '—' | text }}</span>
        </template>
        <template #isTemporaryAccount="row">
          <span
            style="padding-top: 1px; padding-bottom: 1px"
            class="self-center px-3 ml-5 font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial" :class="row.isTemporaryAccount ? getPillColorGreen.class : getPillColorRed.class">
              {{ row.isTemporaryAccount ? getPillColorGreen.label : getPillColorRed.label }}
          </span>
        </template>
        <template #isTestingAccount="row">
          <span
            style="padding-top: 1px; padding-bottom: 1px"
            class="self-center px-3 ml-5 font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial" :class="row.isTestingAccount ? getPillColorGreenTesting.class : getPillColorRedTesting.class">
              {{ row.isTestingAccount ? getPillColorGreenTesting.label : getPillColorRedTesting.label }}
          </span>
        </template>
        <template #featureAccess="row">
          <span>
              {{ featureAccessText(row)}}
          </span>
        </template>
        <template #featureEnabled="row">
          <span>
              {{ featureEnabledText(row)}}
          </span>
        </template>
        <template #custom-dropdown="row">
              <el-dropdown @command="handleCommand">
                <span class="context-menu"><i class="uil uil-ellipsis-h"></i></span>     
                <el-dropdown-menu  slot="dropdown">
                    <el-dropdown-item data-cy="view-tenant" :command="{action: 'view', row: row}"><i class="uil uil-eye text-base"></i><span>View Tenant</span></el-dropdown-item>
                    <el-dropdown-item :command="{action: 'login', row: row}"><i class="uil uil-users-alt text-base"></i>Log into this DSP as Support Administrator</el-dropdown-item>
                    <el-dropdown-item v-if="row.messageServiceProvider != 'Telnyx' && !row.accountPremiumStatus?.includes('None')" divided :command="{action: 'add', row: row}"><i class="uil uil-envelope text-base"></i>Change messaging provider to Telnyx</el-dropdown-item>
                    <el-dropdown-item v-if="row.messageServiceProvider == 'Telnyx' && (row.accountPremiumStatus?.includes('None') || !row.accountPremiumStatus?.includes('None'))" :command="{action: 'remove', row: row}" divided><i class="text-red-500 uil uil-phone-times text-base"></i><span class="text-red-500">Remove & decommission number</span></el-dropdown-item>
                    <el-dropdown-item v-if="!row.accountPremiumStatus?.includes('None')" :command="{action: 'deactivateTenant', row:row}"><i class="uil uil-bill text-base"></i>Change Plan to None</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
        </template>
        <template #balanceDue="row">
          <span @click="handleRowClick(row)">{{ row.calculatedOutstandingBalance ? '$' + row.calculatedOutstandingBalance.toFixed(2).toString() : '—' | text}}</span>
        </template>

      </custom-table>
    </table-card>
    
  <div v-if="nextToken" class="flex justify-center mt-4">
    <el-button round @click="loadData({})">Load More</el-button>
  </div>

  <!-- EXPORT DIALOG -->
  <el-dialog
      title="Export Tenant Records"
      :show-close="true"
      :before-close="handleClose"
      :center="true"
      :visible.sync="showExportDialog"
      width="390px"
    >
      <ExportDialog
        :modal="true"
        :statusChecked="getFilterStatusChecked()"
        :number="getResultTotal"
        exportField="Tenant"
        exportFields="Tenants"
        @export-action="handleExportData"
        @close-dialog="handleClose"
        :hideFilters="!isFiltered"
      >
      </ExportDialog>
  </el-dialog>

  <!-- CHANGE PLAN MODAL -->
  <ChangePlanModal :title="titleChangePlan" :tenant="tenantChangePlan" :show="showChangePlan" @close="showChangePlan = false" @resultChangePlan="resultChangePlan"/>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex"
// import { getUser } from '@/api/queries'
import { setTenantStatus } from '../Tenants/functions'
import TenantForm from './components/TenantForm'
import DeactivateTenantModal from './components/DeactivateTenantModal'
import { searchTenants, countTenants, listTenants, listUserIds, vehiclesByStatus, staffsByStatus, devicesByStatus, listAccidents, valueListsByKey, listStaffs, documentsByType, listPendingMessages, updateTenantCoaching, updateTenantMessageService, updateTenantPlans } from './queries'
import { createTenant, createPremiumStatusHistory, createNotification, updateStaff, deleteValueListItem, updateTenant, updateDocument, updatePendingMessage, updateValueListItem, updateUser, deleteUser, createMessageServiceProvider, createUser, updateTelnyx, createTelnyx, createCustomLists, createOptionsCustomLists } from '@/graphql/mutations'
import { getTenant } from '@/views/Accident/queries.js'
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { listUsers } from '@/views/Settings/Users/query'
import { weeklyPerformanceMetrics, dailyPerformanceMetrics} from '@/utilities/defaultCoachingSettings'
import { updateZohoAccountTenant } from '../../../components/Zoho/zoho';
import {exportCSV} from "@/utilities/exportCSV"
import { telnyxAddNumber, telnyxRemoveNumber, getAvailableNumber, updateAvailableNumber} from './telnyx'
import { setGroup, changePlanToNone } from './tenant'
import * as labelsMethods from './labelsMethods'
import { onCreateCompanyNotification} from '@/graphql/subscriptions';
import { v4 as uuidv4 } from 'uuid';
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import SearchBox from "@/components/TableCard/SearchBox";
import CellToolTip from '@/components/CellToolTip'
import CounterFilter from "@/components/CounterFilters";
import MultiSelectFilter from "@/components/MultiSelectFilter";
import SingleSelectFilter from "@/components/SingleSelectFilter";
import ExportDialog from "@/components/ExportDialog";
import { REGULAR_ACCOUNT, CHILD_ACCOUNT, ACCOUNT_COUNTRY_DEFAULT, CUSTOMER_TYPE, CUSTOMER_SUB_TYPE_XL, CUSTOMER_SUBTYPE, ACCOUNT_TIME, ACCOUNT_TESTING, ACCOUNT_COUNTRY, CUSTOMER_STATUS, FEATURES_ACCESS_PERMISSIONS ,FEATURES_ENABLED_PERMISSIONS,ABBREVATION_MAP_FEATURES} from './constants';
import ChangePlanModal from "./components/ChangePlanModal"
import EventBus from '@/eventBus'
import * as customListMethods from './customListMethods'
import { multipleDefaultFilter, initialSingleDefaultFilter } from "@/components/filtersFunctions"

  export default {
    components: {
      TenantForm, 
      DeactivateTenantModal, 
      TableCard, 
      CustomTable,
      ContainerFilter, 
      SearchBox,
      CellToolTip,
      CounterFilter,
      MultiSelectFilter,
      SingleSelectFilter,
      ExportDialog,
      ChangePlanModal
    },
    data() {
      return {
        trialWithoutExpDate: [],
        unknowTenants: [],
        selectedRows: [],
        dynamoTenantLength: 0,
        formContent: 0,
        loading: false,
        countA: 0,
        countB: 0,
        providedSelectedRows: 0,
        deactivateModalOpen: false,
        deactivateTenantLoading: false,
        deactivateTenantMessage: '',
        modalOpen: false,
        refreshModalOpen: false,
        originationModalOpen: false,
        newVersionNumber: '',
        numberOfBulletsToAdd: 1,
        refreshType: '',
        releaseNotes: '',
        tableData: [],
       // originalTableData: [],
        search: '',
        newTenantForm:{
          companyName: '',
          shortCode: '',
          growSurfParticipantId: '',
          growSurfParticipantEmail: '',
          growSurfParticipantFullName: '',
          growSurfReferredByEmailBackup: '',
          growSurfReferredByFullNameBackup: '',
          growSurfParticipantReferralComplete: false,
          originationNumber: '',
          messageServiceProvider: '',
          // Account type fields
          allowParentData: false,
          parentAccountId: null,
          accountType: REGULAR_ACCOUNT,
          trialExpDate: null,
          firstInterestDateTime: null,
          firstStartedTrialDateTime: null,
          firstConvertedToPaidDateTime: null,
          firstChurnedDateTime: null,
          accountCanceledNotes: '',
          customerType: '',
          customerSubType: '',
          accountCountry: ACCOUNT_COUNTRY_DEFAULT,
          isTemporaryAccount: false,
          isTestingAccount: false,
          featureAccessVehiclePhotoLogsHeraAi:false,
          featureAccessInventoryManagement:false,
          featureEnabledVehiclePhotoLogsHeraAi:false,
          featureEnabledInventoryManagement:false,
          timeZone: null     
        },
        servicesMessage: [
          'None',
          'Bandwidth',
          'Telnyx'
        ],
        filterStatus: CUSTOMER_STATUS,
        filterType: CUSTOMER_TYPE,
        filterSubType: CUSTOMER_SUBTYPE,
        filterTime: ACCOUNT_TIME,
        filterCountry: ACCOUNT_COUNTRY,
        filterTesting: ACCOUNT_TESTING,
        filterFeatureAccess: FEATURES_ACCESS_PERMISSIONS,
        filterFeatureEnabled: FEATURES_ENABLED_PERMISSIONS,
        filterResultFeatureAccess:"Any",
        filterResultFeatureEnabled:"Any",
        abbreviationMapFeature: ABBREVATION_MAP_FEATURES,
        balanceDueSelected: "any",
        balanceDueOptions: [
          { value: "any", label: "Any", selected: false },
          { value: "no balance due", label: "No Balance Due", selected: false },
          { value: "balance due > 0", label: "Balance Due > 0", selected: false }
        ],
        nextToken: null,
        limit: 100,
        originationNumberTemp: '',
        dataFixModalOpen: false,
        weeklyPerformanceMetrics: [],
        dailyPerformanceMetrics: [],
        seeds: {
            'roster-status': { values: ['On Time', 'Late, With Call', 'Late, No Call', 'Called Out', 'No Show, No Call'] },
            'staff-status': { values: [] },
            'device-status': { values: ['Active', 'Inactive'] },
            'vehicle-status': { values: ['Active', 'Inactive', 'Inactive - Maintenance', 'Inactive - Grounded'] }
        }, 
        cols: [
          { name: "Name", col: "companyName", fixed: false, width: "180", sortable: 'custom'},
          { name: "ID", col: "id", fixed: false, width: "50", sortable: false},
          { name: "Short Code", col: "shortCode", fixed: false, width: "140", sortable: 'custom'},
          { name: "Trial Exp", col: "trialExpDate", fixed: false, width: "170", sortable: 'custom'},
          { name: "SMS provider", col: "messageServiceProvider", fixed: false, width: "180", sortable: 'custom' },
          { name: "Status", col: "status", fixed:false, width: "160", sortable: 'custom' },
          { name: "First Interest", col: "firstInterestDateTime", fixed:false, width: "190", sortable: 'custom' },
          { name: "First Started Trial", col: "firstStartedTrialDateTime", fixed:false, width: "190", sortable: 'custom' },
          { name: "First Converted", col: "firstConvertedToPaidDateTime", fixed:false, width: "160", sortable: 'custom' },
          { name: "First Churned", col: "firstChurnedDateTime", fixed:false, width: "160", sortable: 'custom' },
          { name: "Customer Type", col: "customerType", fixed:false, width: "160", sortable: 'custom' },
          { name: "Customer Sub-type", col: "customerSubType", fixed:false, width: "190", sortable: 'custom' },
          { name: "Account Country", col: "accountCountry", fixed:false, width: "200", sortable: 'custom' },
          { name: "Temporary Account", col: "isTemporaryAccount", fixed:false, width: "220", sortable: 'custom' },
          { name: "Last Paid Line Item", col: "lastPaidPositiveInvoiceLineItemDateTime", fixed:false, width: "200", sortable: 'custom' },
          { name: "Total Months Paid", col: "totalNumberOfMonthsPaidByTenant", fixed:false, width: "200", sortable: 'custom' },
          { name: "Lifetime Payment Total", col: "lifetimePaymentTotal", fixed:false, width: "240", sortable: 'custom' },
          { name: "Average Monthly Invoice Total", col: "averageMonthlyInvoiceTotal", fixed:false, width: "290", sortable: 'custom' },
          { name: "Testing Account", col: "isTestingAccount", fixed:false, width: "190", sortable: 'custom' },
          { name: "Cancellation Notes", col: "accountCanceledNotes", fixed:false, width: "200", sortable: 'custom' },
          { name: "Feature Access", col: "featureAccess", fixed:false, width: "200", sortable: 'custom' },
          { name: "Features Enabled", col: "featureEnabled", fixed:false, width: "200", sortable: 'custom' }
        ],
        fieldMap:{ "featureAccess": "featureAccess","featureEnabled":"featureEnabled", "status": "customerStatus", companyName: "companyName", shortCode:"shortCode", trialExpDate:"trialExpDate", messageServiceProvider:"messageServiceProvider", balanceDue:"calculatedOutstandingBalance" },
        sort: { 
          field: "companyName", 
          order: "desc"
        },
        optionsContextRow: [],
        tableCount: 0,
        showExportDialog: false,
        optionsContextHeader: [
          // { label: "Export Tenants", action: "export", divided: false },
        ],
        statusChecked: ["Customer Status ","DSP Type", "Balance Due"],
        totalTenants: 0,
        allRecordsCount: 0,
        currentPage: 1,
        pageSize: 50,
        showChangePlan: false,
        titleChangePlan: '',
        tenantChangePlan: {},
        isFiltered: false,
        tenantSubscriptionForAssociates: null,
        notifications: [],
        notificationsCount: 0,
        viewName: 'tenant-list',
        createdFrom: '',
        tenantsReloading: false,
        currentButtonNotification: null
      }
    },
    created(){
        this.loadAllTenantAlerts()
        this.newTenantForm.trialExpDate = this.$moment().add(45, 'days').toDate()
        this.weeklyPerformanceMetrics = weeklyPerformanceMetrics
        this.dailyPerformanceMetrics = dailyPerformanceMetrics
        if(this.valuelists['staff-status'].items){
          this.seeds["staff-status"].values = this.valueListActivesOnly(this.valuelists['staff-status'].items).map(v => (v.value))
        }
        this.loadFilters()
        this.loadData({updateTable:true})
        try { 
              this.tenantSubscriptionForAssociates = API.graphql(graphqlOperation(onCreateCompanyNotification))
                  .subscribe({
                  next: async (notificationData) => {
                      const { owner, affectedTable} = notificationData.value.data.onCreateCompanyNotification
                      const backCreatedFrom = owner ? owner : null
                    
                      if (affectedTable === 'Tenant') {
                          if (!this.currentButtonNotification) {
                              this.currentButtonNotification = {};
                          }
                          if (backCreatedFrom && backCreatedFrom != null) {
                              this.createdFrom = backCreatedFrom
                              if(backCreatedFrom && backCreatedFrom == this.userInfo.cognitoSub) {
                                  this.currentButtonNotification[`origin-${this.viewName}-${this.createdFrom}`] = true
                              } else {
                                  this.currentButtonNotification[`origin-${this.viewName}-${this.createdFrom}`] = false
                              }
                              return
                          }
                          this.notificationsCount += 1;
                          this.notifications.push(notificationData.value.data.onCreateCompanyNotification);
                          if (this.currentButtonNotification[`origin-${this.viewName}-${this.createdFrom}`] && this.currentButtonNotification[`origin-${this.viewName}-${this.createdFrom}`] == true) this.reloadTableData()
                          else{
                              this.displayUserNotificationWithButton({
                                  title: 'Update Tenant List',
                                  type: "warning",
                                  duration: 0,
                                  message: 'An update of tenants were detected. Please refresh page to see it...'
                              }, this.viewName, () => {
                                this.deselectAll()
                                this.loadData({updateTable:true})
                              });
                          }
                      }
                  },
                  error: (error) => {
                      console.error('Subscription error:', error);
                  }
              });
          } catch(e) {
              console.log('Error: Associate Subscription Failed', e);
          }
      },
      beforeDestroy() {
          if (this.tenantSubscriptionForAssociates) {
              this.tenantSubscriptionForAssociates.unsubscribe();
          }
      },
    
    mounted(){
      // Loads second, but only once.
      // Store subscription loads first but multiple times:
      // this.$store.subscribe((mutation, state) => {
      //     if(mutation.type === 'setTenantCoachingInfo') {
      
      /*
      EventBus.$on('tenant-update-subscription', async (tenant)=>{        
        if(!this.loading && !this.subscriptionUpdated){
          // wait for RDS stream to process before updating
          this.subscriptionUpdated = true
          setTimeout(async ()=>{
            await this.loadData(null, null, true)
            this.subscriptionUpdated = false
          }, 1800)
        }
      })
      */
      
      this.newVersionNumber = this.$store.state.version
      
    },
    computed: {
      ...mapState([
        'valuelists',
        'userInfo'
      ]),

      getPillColorGreen() {
        return {
            label: 'Yes',
            class: 'text-green-600 bg-green-500',
        }
      },

      getPillColorRed() {
        return {
            label: 'No',
            class: 'text-red-600 bg-red-500',
        }
      },

      getPillColorGreenTesting() {
        return {
            label: 'Yes',
            class: 'text-green-600 bg-green-500',
        }
      },

      getPillColorRedTesting() {
        return {
            label: 'No',
            class: 'text-red-600 bg-red-500',
        }
      },

      filterStatusChecked: {
        get() {
          return this.getFilter('status', CUSTOMER_STATUS, this.filterStatus);
        },
        set(items) {
          this.setFilter('status', items);
        }
      },

      filterTypeChecked: {
        get() {
          return this.getFilter('type', CUSTOMER_TYPE, this.filterType);
        },
        set(items) {
          this.setFilter('type', items);
        }
      },

      filterFeatureAccessChecked: {
        get() {
          return this.getFilter('featureAccess', FEATURES_ACCESS_PERMISSIONS, this.filterFeatureAccess);
        },
        set(items) {
          this.setFilter('featureAccess', items);
        }
      },
      filterFeatureEnabledChecked: {
        get() {
          return this.getFilter('featureEnabled', FEATURES_ENABLED_PERMISSIONS, this.filterFeatureEnabled);
        },
        set(items) {
          this.setFilter('featureEnabled', items);
        }
      },

      filterSubTypeChecked: {
        get() {
          return this.getFilter('subtype', CUSTOMER_SUBTYPE, this.filterSubType);
        },
        set(items) {
          this.setFilter('subtype', items);
        }
      },

      filterTimeChecked: {
        get() {
          return this.getFilter('time', ACCOUNT_TIME, this.filterTime);
        },
        set(items) {
          this.setFilter('time', items);
        }
      },

      filterTestingChecked: {
        get() {
          return this.getFilter('testing', ACCOUNT_TESTING, this.filterTesting);
        },
        set(items) {
          this.setFilter('testing', items);
        }
      },

      filterCountryChecked: {
        get() {
          return this.getFilter('country', ACCOUNT_COUNTRY, this.filterCountry);
        },
        set(items) {
          this.setFilter('country', items);
        }
      },

      getResumeFilters() {
        const filters = [
          [this.filterStatusChecked, this.filterStatus],
          [this.filterTypeChecked, this.filterType],
          [this.filterFeatureAccessChecked, this.filterFeatureAccess],
          [this.filterFeatureEnabledChecked, this.filterFeatureEnabled],
          [this.filterSubTypeChecked, this.filterSubType],
          [this.filterTimeChecked, this.filterTime],
          [this.filterTestingChecked, this.filterTesting],
          [this.filterCountryChecked, this.filterCountry]
        ];
        const total = filters.reduce((sum, [checked, all]) => sum + (checked.length === all.length ? 0 : 1), 0);
        return total;
      },

      totalExportsRecords(){
        return this.tableData?.length || 0
      },

      titleExport() {
        const title =this.totalExportsRecords > 1
          ? "Tenant Records"
          : "Tenant Record"
        return title
      },
      selectionActions() {
        const shouldDisplay = (selectedRows) => (selectedRows.length) > 0
        return [
          {
            title: "Update Feature Access",
            shouldDisplay,
            submenu: [
              { command: 'feature_access_vplai_on', label: 'Turn On "Vehicle Photo Log Hera AI"', handler: this.turnOnOffFeatures, shouldDisplay: true },
              { command: 'feature_access_vplai_off', label: 'Turn Off "Vehicle Photo Log Hera AI"', handler: this.turnOnOffFeatures, shouldDisplay: true },
              { command: 'feature_access_inventorym_on', label: 'Turn On "Inventory Management"', handler: this.turnOnOffFeatures, shouldDisplay: true },
              { command: 'feature_access_inventorym_off',label: 'Turn Off "Inventory Management"', handler: this.turnOnOffFeatures, shouldDisplay: true }
            ]
          },
          {
            title: "Update Feature Enabled",
            shouldDisplay,
            submenu: [
              { command: 'feature_enabled_vplai_on', label: 'Turn On "Vehicle Photo Log Hera AI"', handler: this.turnOnOffFeatures, shouldDisplay: true },
              { command: 'feature_enabled_vplai_off', label: 'Turn Off "Vehicle Photo Log Hera AI"', handler: this.turnOnOffFeatures, shouldDisplay: true },
              { command: 'feature_enabled_inventorym_on', label: 'Turn On "Inventory Management"', handler: this.turnOnOffFeatures, shouldDisplay: true },
              { command: 'feature_enabled_inventorym_off', label: 'Turn Off "Inventory Management"', handler: this.turnOnOffFeatures, shouldDisplay: true }
            ]
          }
        ];
      },
      exportRecords(){
        let arrData = []
        this.tableData.forEach((data, index) => {
          arrData[index] = {
            "Company Name": data.companyName,
            "Short Code": data.shortCode,
            "Trial Expiration": this.$moment(data.trialExpDate).format("MM/DD/YYYY"),
            "Service provider": data.messageServiceProvider,
            "Customer Status": data.customerStatus,
            "Balance Due": data.calculatedOutstandingBalance
          }
        })       
        return arrData
      },
      trialClassColorConflict() {
        return this.trialWithoutExpDate.length > 0 ? 'text-red-500 font-semibold' : 'text-black';
      },
      statusClassColorConflict() {
        return this.unknowTenants.length > 0 ? 'text-red-500 font-semibold' : 'text-black';
      },
      totalClassColorConflict() {
        return 'text-orange-500 font-semibold'
      },
      getResultTotal() {
        return this.totalTenants || this.allRecordsCount
      },
    },

    watch: {
      search(){
        if(this.search.length >= 0)
          this.filterTenantsbyStatus(true)
      }
    },

    methods: {
      async deselectAll() {
            this.selectedRows = []      
            // reset selected rows in component via prop change        
            this.providedSelectedRows++ 
            this.selectedRows = []
        },
      clearcurrentButtonNotification() {
              if (!this.currentButtonNotification) {
                this.currentButtonNotification = {};
              }
              this.currentButtonNotification[`origin-${this.viewName}-${this.createdFrom}`] = true;
            },

        validateIsTableUpdated(action) {
          if (this.currentButtonNotification && this.currentButtonNotification[`${this.viewName}-not-updated`] == true) {
              this.displayUserNotificationWithButton({
                title: 'Update Tenant List',
                type: "warning",
                duration: 0,
                message: `An update of tenant were detected in this report. Please refresh the page first to continue ${action} tenant...`
                }, this.viewName, () => {
                  this.deselectAll()
                  this.loadData({updateTable:true})
                }, true);
              return false
          } else {
              return true
          }
        },
      async reloadTableData() {
              if (this.tenantsReloading === false) {
                this.tenantsReloading = true
                await this.sleepTimeOut(3000)
                this.loadData({updateTable:true, hideLoading:true})
                .catch((error) => {
                    console.error("Error loading data:", error);
                })
                .finally(() => {
                    this.tenantsReloading = false;
                });
              }
            },
      turnOnOffFeatures(selectedRowsArray, selectRowsByCb, command) {
            let permissionValue,field;
            switch (command) {
              case 'feature_access_vplai_on':
              case 'feature_access_vplai_off':
              case 'feature_enabled_vplai_on':
              case 'feature_enabled_vplai_off':
              case 'feature_access_inventorym_on':
              case 'feature_access_inventorym_off':
              case 'feature_enabled_inventorym_on':
              case 'feature_enabled_inventorym_off':
                const type = command.includes('access') ? 'Access' : 'Enabled';
                permissionValue = command.includes('off') ? false : true;
                const feature = command.includes('vplai') ? 'VehiclePhotoLogsHeraAi' : 'InventoryManagement';
                field = `feature${type}${feature}`;
                break;
              default:
                console.error('Unknown command:', command);
            }
            
            if(this.selectedRows?.length > 0) {
              try {
                let apiName = 'heraPublicApi';
                let path = '/executeMutation';
                let post = {
                  body:{
                    "type": 'tenant',
                    "mutation": 'update',
                    "owner": this.userInfo.cognitoSub,
                    "group": this.userInfo.tenant.group,
                    "items": {
                      tenantListToUpdate: this.selectedRows,
                      permissionValue: permissionValue,
                      field:field
                    }
                  }
                }
                    this.displayUserNotification({
                        title: 'Processing...',
                        type: "info",
                        duration: 5000,
                        message: 'Hera is currently updating the Tenant List.'
                    })

                  safeFunction(API.post)(apiName, path, post);
                  this.deselectAll();
                } catch (e) {
                    this.displayUserNotification({
                        title: "Error",
                        type: "error",
                        message: e
                    })
                }

            }
        },
      featureAccessText(row) {
        const features = [];
        if (row.featureAccessVehiclePhotoLogsHeraAi) {
          features.push('Vehicle Management: Vehicle Photo Logs Hera AI');
        }
        if (row.featureAccessInventoryManagement) {
          features.push('Inventory Management');
        }
        return features.length > 0 ? features.join(', ') : '—';
      },
      featureEnabledText(row) {
        const features = [];
        if (row.featureEnabledVehiclePhotoLogsHeraAi) {
          features.push('Vehicle Management: Vehicle Photo Logs Hera AI');
        }
        if (row.featureEnabledInventoryManagement) {
          features.push('Inventory Management');
        }
        return features.length > 0 ? features.join(', ') : '—';
      },
      loadMatchingIds: async function(){
        let ids = []
            try{
                ids = await this.loadData({updateTable:false,onlyIds:true})
            }catch(e){
                return console.error('error finding all matching asscoaties IDs', e)
            }
            let idMap = {}
            ids.forEach(id=>idMap[id.id]=true)
            this.selectedRows = ids.map(id=>id.id)
            return idMap
      },
      handleClose() {
        this.showExportDialog = false;
        this.isFiltered = false;
      },
      async copyID(id) {
        try {
          await navigator.clipboard.writeText(id);
          this.displayUserNotification({
            title: "Successfully Copied ID",
            type: "success",
            message: id
          })
        } catch(e) {
          console.error('error copying ID to clipboard:', e);
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e
          })
        }
      },
      
      getFilter(type, defaultAccount, filtersList) {
        const query = this.$route.query[type];
        let defaultFilter = defaultAccount;
        const filtersTenant = sessionStorage.getItem("filtersTenant");
        if (filtersTenant) {
          const filters = JSON.parse(filtersTenant);
          defaultFilter = filters[type] || defaultAccount;
        }
        return multipleDefaultFilter(query, defaultFilter, filtersList);
      },

      setFilter(type, items) {
        const filtersTenant = sessionStorage.getItem("filtersTenant");
        const options = filtersTenant ? JSON.parse(filtersTenant) : {};
        options[type] = items;
        sessionStorage.setItem("filtersTenant", JSON.stringify(options));
      },

      updateFilters(e) {
        const { type, filters } = e;
        const filterMapping = {
          status: 'filterStatusChecked',
          type: 'filterTypeChecked',
          testing: 'filterTestingChecked',
          country: 'filterCountryChecked',
          featureAccess: 'filterFeatureAccessChecked',
          featureEnabled: 'filterFeatureEnabledChecked'
        };

        if (filterMapping[type]) {
          this[filterMapping[type]] = filters;
        }

        this.loadData({searchOrFilter:true})
      },

      async updateOptions(e) {
        this.balanceDueOptions = e.options
        this.balanceDueSelected = e.currentFilter
        this.loadData({searchOrFilter:true})
      },

      loadFilters(){
        const type = "balanceDueFilter"
        let optionList = this.balanceDueOptions
        let result = initialSingleDefaultFilter(type, this.balanceDueSelected, optionList, false)
        this.balanceDueSelected = result.newFilter
        this.balanceDueOptions = result.newOptions
      },
      async loadAllTenantAlerts(){
        let allTenants = await this.gLoadListAll(listTenants, {}, 'listTenants');
        this.dynamoTenantLength = allTenants.length
        for (const tenant of allTenants){
          tenant.status = setTenantStatus(tenant.accountPremiumStatus, tenant.trialExpDate)
          if (tenant.status === 'Trial' && !tenant.trialExpDate) this.trialWithoutExpDate.push(tenant)
          if (tenant.status === 'Unknown') this.unknowTenants.push(tenant)
        };
        return
      },

      filterTenantsbyStatus(search=false) {
        this.loadData({search:search,searchOrFilter:true})
      },

      async removeTelnyxNumber(tenantRow) {
        try {
          this.loading = true
          await telnyxRemoveNumber(tenantRow)
          this.displayUserNotification({
            title: "Success",
            type: "success",
            message: 'Tenant Updated'
          });
          await this.loadData({})
        } catch(e){
          console.error('removeTelnyxNumber', e)
          this.loading = false;
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e
          })
        } finally {
          this.loading = false;
        }
      },

      setClassStatus(row) {
        return {
          'text-yellow-600 bg-yellow-500' : row.customerStatus.toLowerCase() == 'trial',
          'text-red-600 bg-red-500' : row.customerStatus.toLowerCase() == 'lapsed trial' || row.customerStatus.toLowerCase() == 'churned', 
          'text-orange-600 bg-orange-500' : row.customerStatus.toLowerCase() == 'unknown', 
          'text-green-600 bg-green-500' : row.customerStatus.toLowerCase().includes('active'),
        }
      },

      async addTelnyxNumber(tenantRow) {
        try {
          this.loading = true;
          await telnyxAddNumber(tenantRow)
          this.displayUserNotification({
            title: "Success",
            type: "success",
            message: 'Tenant Updated'
          });
          await this.loadData({})
        } catch(e){
          console.error('addTelnyxNumber', e)
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e
          })
        } finally {
          this.loading = false;
        }
      },
      async handleCommand(payload) {
        if (payload.action === 'remove') {
          this.$confirm('This will delete your messaging provider. Continue?', 'Warning', {
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(async () => {
            if (payload?.row?.originationNumber) {
              await this.removeTelnyxNumber(payload.row);
            } else if (payload.row.id) {
              let tenant = await this.api(getTenant, { id: payload.row.id })
              await this.removeTelnyxNumber(tenant?.data?.getTenant);
            }
          }).catch((error) =>{
            console.error("Error on Remove & decommission number", error);
          });
        } else if(payload.action === 'add') {
          this.$confirm('This will change messaging provider to Telnyx. Continue?', 'Warning', {
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(async () => {
            await this.addTelnyxNumber(payload.row);
          }).catch(() =>{
            console.error("Change canceled.");
          });
        }else if(payload.action === 'login') {          
          try{
            await setGroup(payload.row)
          }catch(e){
            console.error('error logging in as admin', e)
            this.loading = false
            this.displayUserNotification({
                title: "Error",
                type: "error",
                message: "Error creating system user"
            })
          }                    
        } else if(payload.action === 'view') {
          this.handleRowClick(payload.row);
        }else if(payload.action === 'deactivateTenant') {
          let message = `Would you like to continue?`;
          if(!!payload.row.bundleDeactivationAllowedAt) {
            let deactivationDate = this.$moment(payload.row.bundleDeactivationAllowedAt).format('YYYY-MM-DD');
            message = `The tenant's bundle expires on ${deactivationDate}; would you like to continue?`
          } 
          this.titleChangePlan = message
          this.tenantChangePlan = { ...payload.row }            
          this.showChangePlan = true           
        }else if(payload.action === 'export'){
          this.showExportDialog = true
        }
      },
      async resultChangePlan(result){
        if(result) this.deactivateTenantMessage = result.message
        this.deactivateTenantLoading = false
        if(result.status=='Success'){
          this.loadData({})
        }
      },

      searchData(e) {
        this.search = e;
      },

      sortColumn(e){
        
        this.sort = { 
          field: this.fieldMap[e.prop], 
          order: e.order == "descending" ? "desc" : "asc"
        } 

        // if no pagination, sort in dom
        //if(this.tableData.length < this.pageSize){
          //console.log('not enough results to server sort')
        //  e.column.sortable = true
        //}else{          
          return this.loadData({searchOrFilter:true})
        //}
      },

      // is this ever used?
      filterTenants({data, search}){
        console.log('------ filtering tenants???')
        let searchString = search.toLowerCase()
        const filteredData = data.filter((item)=> {
          const originationNumberMatch = item.originationNumber?.includes(searchString)
          const companyMatch = item.companyName.toLowerCase().includes(searchString)
          const userEmails= item.users.items?.map((user)=>user.email)
          const userEmailMatch = userEmails.filter((email)=> email.toLowerCase().includes(searchString))
          const userPhones= item.users.items?.map((user)=>user.phone)
          const userPhoneMatch= userPhones.filter((phone)=> phone?.includes(searchString))
          const shortCodeMatch = item.shortCode?.toLowerCase().includes(searchString)
          return originationNumberMatch || userEmailMatch.length > 0 || userPhoneMatch.length > 0 || shortCodeMatch || companyMatch
        })
        return filteredData
      },

      addBullets(){
        var str = ''
        var i
        for(i = 1; i <= this.numberOfBulletsToAdd; i++){
          if(!this.releaseNotes) this.releaseNotes += "•"
          else this.releaseNotes += "\n•"
        }
      },

      openForceRefresh(){
        this.refreshType = ''
        this.releaseNotes = ''
        this.numberOfBulletsToAdd = 1
        this.refreshModalOpen = true
      },


      /**
       * Queries all staff statuses value lists and delete the old inactive statuses
       */
      async commitStaffStatusesUpdate(){
        this.loading = true
        var valueLists
        var input = {
          key: 'staff-status',
          limit: 1000
        }
        try{
          valueLists = await this.gLoadListAll(valueListsByKey, input, 'valueListsByKey')
        }
        catch(e){
          console.error(e)
          //Continue if the error is that some value lists don't have a tenant
          if(e.errors[0].message.includes('Cannot return null for non-nullable type')){
            valueLists = e.data.valueListsByKey.items.filter((valueList) =>{
              return valueList != null
            })
            console.error(valueLists)
          }
          else{
            console.error(e)
            this.displayUserError(e)
            this.loading = false;
            return
          }
        }
        try{
          //Loop through every value list
          valueLists.forEach(async(valueList)=>{
              var items = valueList.items.items

              //Loop through every value list item
              await Promise.all(items.map(async(item)=>{
                if(item.custom != true && (item.value.toLowerCase() == "inactive" || item.value.toLowerCase() == "terminated" || item.value.toLowerCase() == "medical leave")){
                  var result = await this.api(deleteValueListItem, {input:{ id: item.id }})
                  console.error(result)
                }
              }));
          });

          this.dataFixModalOpen = false

        }
        catch(e){
          console.error(e)
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e.message
          })
        }
        finally{
          this.loading = false;
        }
      },

        /**
       * Queries all staff statuses value lists and delete the old inactive statuses
       */
      async commitStaffVacationStatusesUpdate(){
        this.loading = true
        var valueLists
        var input = {
          key: 'staff-status',
          limit: 1000
        }
        try{
          valueLists = await this.gLoadListAll(valueListsByKey, input, 'valueListsByKey')

        }
        catch(e){
          console.error(e)
          //Continue if the error is that some value lists don't have a tenant
          if(e.errors[0].message.includes('Cannot return null for non-nullable type')){
            valueLists = e.data.valueListsByKey.items.filter((valueList) =>{
              return valueList != null
            })
            console.error(valueLists)
          }
          else{
            console.error(e)
            this.displayUserError(e)
            this.loading = false;
            return
          }
        }
        try{

          //Loop through every value list
          console.log(valueLists)
          valueLists.forEach(async(valueList)=>{
              var items = valueList.items.items

              //Loop through every value list item
              await Promise.all(items.map(async(item)=>{
                if(item.custom != true && (item.value.toLowerCase() == "inactive - personal time/vacaction")){
                  var result = await this.api(deleteValueListItem, {input:{ id: item.id }})
                  console.log(result)
                }
              }));
          });

          this.dataFixModalOpen = false

        }
        catch(e){
          console.error(e)
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e.message
          })
        }
        finally{
          this.loading = false;
        }
      },

      async commitDeleteI9 (){
        this.loading = true
        try {
          const input = {type: "I9"}
          const documents = await this.gLoadListAll(documentsByType, input, 'documentsByType');


          documents.forEach(async (doc) => {
            await this.api(updateDocument, { input: { id: doc.id, type: "Misc", group: doc.group }});
          });

          const documentInput = {
            key: 'document-type'
          }
          const valueLists = await this.gLoadListAll(valueListsByKey, documentInput, 'valueListsByKey')
          valueLists.forEach(async(list)=> {
            const valueListItems = list?.items?.items ?? []
            const i9Item = valueListItems.find((item) => item.value === "I9");
            if (i9Item) {
              await this.api(deleteValueListItem, {input: { id: i9Item.id }});
            }
          })
          this.loading = false
        } catch(err) {
         console.error({err})
         this.loading = false
         throw err
       }
      },

      /**
       * Confirms if user wants to delete the old DA inactive statuses
       */
      staffStatusConfirmation(){
        this.$confirm('This will delete all the old '+this.$t('label.associate')+' statuses and keep the new ones. Continue?', 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.commitStaffStatusesUpdate()
        }).catch(() => {
          console.error("canceled delete")
        });
      },

      /**
       * Confirms if user wants to delete the old DA vacation statuses
       */
      staffVacationStatusConfirmation(){
        this.$confirm('This will delete all the old '+this.$t('label.associate')+' statuses with mispelled vacation and keep the new ones. Continue?', 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.commitStaffVacationStatusesUpdate()
        }).catch(() => {
          console.error("canceled delete")
        });
      },

      deleteI9(){
        this.$confirm('This will set all documents using I9 type to Misc and delete I9 from document-type. Continue?', 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.commitDeleteI9()
        }).catch(() => {
          console.error("canceled delete")
        });
      },


      /**
       * Confirms if user wants to delete the old DA vacation statuses
       */
      staffPendingMessageConfirmation(){
        this.$confirm('This will update the existing pending messages pendingMessageStaffId field to staffId field. Continue?', 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.commitPendingMessageUpdate()
        }).catch(() => {
          console.error("canceled delete")
        });
      },

       async commitPendingMessageUpdate(){
        this.dataFixModalOpen = false
        this.loading = true
        try{
          var input = {
            limit: 300,
          }
          var pendingMessages = await this.gLoadListAll(listPendingMessages, input, 'listPendingMessages')
          console.log(pendingMessages)

          await Promise.all(pendingMessages.map(async(pendingMessage)=>{
            if(pendingMessage?.staff?.id){
                var input = {
                  id: pendingMessage.id,
                  staffId: pendingMessage.staff.id,
                  group: pendingMessage.group
                }
                await this.api(updatePendingMessage, {input})
            }
          }))
        }
        catch(e){
          console.error(e)
          this.displayUserError(e)
        }
        finally{
          this.loading = false
        }
      },

      confirmSetPermissions(){
        this.$confirm("This will update all users's permissions based on their current role. Continue?", 'Warning', {
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.commitSetPermissions()
        }).catch(() => {
          console.error("canceled delete")
        });
      },

      async commitSetPermissions(){
            this.dataFixModalOpen = false
            this.loading = true
            try{
                //load all users
                var users = await this.gLoadListAll (listUsers, {}, 'listUsers')

                await Promise.all(users.map( async user  => {
                    let role = user.role
                    let isActive = user.isActive

                    var input = {
                      id: user.id,
                      group: user.group,
                      permissionLogin: isActive
                    }

                    if(role.toLowerCase() == 'admin'){
                      input.permissionFullAccess = true
                      input.permissionDocuments = true
                      input.permissionCounselings = true
                      input.permissionAccidents = true
                      input.permissionInjuries = true
                      input.permissionDrugTests = true
                    }
                    else if(role.toLowerCase() == 'dispatcher'){
                      input.permissionFullAccess = false
                      input.permissionDocuments = true
                      input.permissionCounselings = true
                      input.permissionAccidents = true
                      input.permissionInjuries = true
                      input.permissionDrugTests = true
                    }
                    else if(role.toLowerCase() == 'supervisor'){
                      input.permissionFullAccess = false
                      input.permissionDocuments = true
                      input.permissionCounselings = true
                      input.permissionAccidents = false
                      input.permissionInjuries = true
                      input.permissionDrugTests = true
                    }
                    else if(role.toLowerCase() == 'teamlead'){
                      input.permissionFullAccess = false
                      input.permissionDocuments = false
                      input.permissionCounselings = true
                      input.permissionAccidents = false
                      input.permissionInjuries = false
                      input.permissionDrugTests = false
                    }

                    this.api(updateUser, {input: input})

                }))

                this.$message({
                    type: 'success',
                    message: "Users' Permissions Updated"
                });
            }
            catch(e){
                console.error(e)
                this.displayUserError(e)
            }
            finally{
                this.loading = false
            }
        },


      confirmDefaultCoaching(){
        this.$confirm("This will update all tenant's coaching settings to the defaults. Continue?", 'Warning', {
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.commitDefaultCoaching()
        }).catch(() => {
          console.error("canceled delete")
        });
      },

        async commitDefaultCoaching(){
            this.dataFixModalOpen = false
            this.loading = true
            try{
                var input = {
                  limit: 300,
                }
                var tenants = await this.gLoadListAll(searchTenants, input, 'searchTenants')


                // set default toggles and thresholds
                await Promise.all(tenants.map(async(tenant)=>{
                    // set default driver report range
                     input = {
                        id: tenant.id,
                        group: tenant.group,
                        coachingDriverRankRange: 30,
                    }
                    await this.api(updateTenantCoaching, {input: input})

                    await this.resetDefaults('Scorecard', tenant.id, tenant.group)
                    await this.resetDefaults('CXFeedback', tenant.id, tenant.group)
                    await this.resetDefaults('Mentor', tenant.id, tenant.group)
                    await this.resetDefaults('Netradyne', tenant.id, tenant.group)
                }))

            }
            catch(e){
                console.error(e)
                this.displayUserError(e)
            }
            finally{
                this.loading = false
            }
        },


      /**
       * Load data using the searchTenants custom GraphQL query
       */
      loadData: async function({search=null, from=0, searchOrFilter=null,updateTable=true, hideLoading, onlyIds, selectedIds}){
        if(!hideLoading) this.loading = true;

        this.filterResultFeatureAccess = this.generateFilters(this.filterFeatureAccessChecked,this.abbreviationMapFeature)
        this.filterResultFeatureEnabled = this.generateFilters(this.filterFeatureEnabledChecked,this.abbreviationMapFeature)
        
        // reset to page 1 if filtering
        if(searchOrFilter) {
          this.currentPage = 1
        }
        
        let start = (this.currentPage-1) * this.pageSize
        if(start < 0) start = 0   
                 
        let csFilter = this.filterStatus.length == this.filterStatusChecked.length ? [] : this.filterStatusChecked
        selectedIds = !!selectedIds && Array.isArray(selectedIds) ? selectedIds : false
        
        let body = {            
          "offset": start,
          "limit": this.pageSize,
          "onlyIds": onlyIds,
          "selectedIds": selectedIds,
          "filters":{
            "customerStatus":  csFilter,
            "customerType": this.filterTypeChecked,
            "customerSubType": this.filterSubTypeChecked,
            "time": this.filterTimeChecked,
            "testing": this.filterTestingChecked,
            "accountCountry": this.filterCountryChecked,
            "balanceDueFilter": this.balanceDueSelected,
            "featureAccess": this.filterResultFeatureAccess,
            "featureEnabled": this.filterResultFeatureEnabled
          },
          "search":""
        }

        // if typing a search set current page to 0 and size to 25
        if(search){ 
          this.currentPage = 1
          //this.pageSize = 25
        }
        // search here is form value
        if(this.search){
          body.search = this.search
        }
        
        // handle sorting
        if(this.sort){
          body.sort = { direction: this.sort.order, field: this.sort.field }
        }

        let tenants, req = { body }                  
        
        try {
          tenants = await API.post('rds', '/query/Tenant', req)
          if(!updateTable) return tenants.data
          this.tableData = tenants.data
          this.totalTenants = tenants.total
          this.allRecordsCount = tenants.allTotal ?? this.totalTenants
        } catch(e) {
          console.error('error getting rds', e)
          this.totalTenants = 0
          this.allRecordsCount = 0
          this.tableData = []
          this.displayUserError(e)
        } finally {
          this.loading = false
        }
      },

      handleExport (type) {
        this.showExportDialog = true;
        if(type === 'filtered') {
          this.isFiltered = true;
        }
      },

      async handleExportData() {
        this.showExportDialog = false;
        this.displayUserNotification({
          title: "Export",
          type: "info",
          message: `Exported CSV file is being generated. Notification will be sent with download link once ready.`,
        });
        let csFilter = this.filterStatus.length == this.filterStatusChecked.length ? [] : this.filterStatusChecked;

        const BODY = {
          type: 'rds',
          table: 'Tenant',
          owner: this.userInfo.cognitoSub,
          group: this.userInfo.tenant.group,
          userId: this.userInfo.id,
          isFormattingRequired: true,
          report: 'tenantList',
          titleNotification: 'Tenant List',
          rdsBody: {
            search: this.isFiltered ? this.search : '',
            filters:{
              customerStatus: this.isFiltered ? csFilter : [],
              customerType: this.isFiltered ? this.filterTypeChecked : [],
              customerSubType: this.isFiltered ? this.filterSubTypeChecked : [],
              time: this.isFiltered ? this.filterTimeChecked : [],
              testing: this.isFiltered ? this.filterTestingChecked : [],
              accountCountry: this.isFiltered ? this.filterCountryChecked : [],
              balanceDueFilter: this.isFiltered ? this.balanceDueSelected : 'any'
            },
            sort: { direction: this.sort.order, field: this.sort.field }
          }
        }

        let req = {
          body: BODY,
        }

        try {
          await API.post('csvDataExport', '/data-export', req);
        } catch (e) {
          this.displayUserError(e)
        } finally {
          this.isFiltered = false;
        }
      },

      pageChange(e){
        this.currentPage = e 
        this.loadData({from:1, updateTable:true})    
      },

      handleSizeChange(e){
        this.pageSize = e
        this.currentPage = 1
        this.loadData({})
      },
      handleSelectionChange(selectedRows,rows) {
            this.selectedRows = selectedRows
            this.selectedRowsValues = rows
        },

      generateFilters(filterFeatureChecked, abbreviationMapFeature) {
        let filters = {};
        let abbreviationGroups = new Map();
        const hasOnOff = filterFeatureChecked.some(item => item.includes('(ON)') || item.includes('(OFF)'));
        const hasEnabledDisabled = filterFeatureChecked.some(item => item.includes('(Enabled)') || item.includes('(Disabled)'));
        filterFeatureChecked.forEach(item => {
          const category = Object.keys(abbreviationMapFeature).find(cat => item.includes(cat));
          if (category) {
            const abbr = abbreviationMapFeature[category];
            if (!abbreviationGroups.has(abbr)) {
              abbreviationGroups.set(abbr, []);
            }
            abbreviationGroups.get(abbr).push(item);
          }
        });
        abbreviationGroups.forEach((items, abbr) => {
          let status;
          
          if (hasOnOff) {
            status = items.some(item => item.includes('(ON)')) &&
                    items.some(item => item.includes('(OFF)')) ? 'ANY' : 
                    items[0].includes('(ON)') ? 'ON' : 'OFF';
          } else if (hasEnabledDisabled) {
            status = items.some(item => item.includes('(Enabled)')) &&
                    items.some(item => item.includes('(Disabled)')) ? 'ANY' : 
                    items[0].includes('(Enabled)') ? 'Enabled' : 'Disabled';
          } else {
            status = 'UNKNOWN';
          }
          filters[abbr] = status;
        });
        return filters;
      },

      async refreshClients(){
        //confirm required data
        if( !this.newVersionNumber || !this.refreshType){
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: "Make sure the version and type are filled in."
          })
          return
        }
        let apiName = 'ForceLogoutAndRefresh'
          let path = '/forcelogoutandrefresh'
          let post = {body:{
            "newVersionNumber" : this.newVersionNumber,
            "releaseNotes": this.releaseNotes,
            "refreshType": this.refreshType,
            "currUserId": this.$store.state.userInfo.id,
            "cognitoSub": this.$store.state.userInfo.cognitoSub
            }
          }
        this.loading = true
        this.refreshModalOpen = false
        API.post(apiName, path, post) //The await was removed because this function does not need to give a response in the frontend. It should only be executed from the lambda.
        this.loading = false
      },

      /**
       * Confirm if the user wants to update staffs empty string email
       */
      confirmUserNotificationUpate(){
        this.$confirm('This will update all users with default notification settings. Update?', 'Warning',{
          confirmButtonText: 'Update',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        .then(() =>{
            this.setUserFlags()
        })
        .catch(() =>{
            console.error("canceled send");
        });
      },


      /** Create a new Tenant and Group */
      async createTenant(){
        this.loading = true
        try {
          // Check for available numbers
          const availableNumber = await getAvailableNumber()

          try {
            //validate form
            await this.$refs.tenantForm.$refs.form.validate();
          } catch (error) {
            throw new Error('Please fill out all required fields and correct any errors.')
          }

          //Create Group
          let groupId = uuidv4();
          await this.createCognitoGroup(groupId)

          //Get Data By GrowSurf
          const growSurfData = await this.getDataByApiGrowSurf(this.newTenantForm?.growSurfParticipantId)
          const parseGrowSurfData = this.getParsingDataGrowSurf(growSurfData)

          //Create Tenant record
          //IF YOU CHANGE THESE DEFAULTS, UPDATE THE SIGNUP FORM LAMBDA TOO

          const input = {
            ...this.newTenantForm,
            ...parseGrowSurfData,
            group: groupId,
            coachingDriverRankRange: 30,
            accountPremiumStatus: ["trial"],
            messageServiceProvider: 'None',
            // account type fields
            allowParentData: false,
            parentAccountId: null,
            accountType: REGULAR_ACCOUNT,
            customerStatus: 'Trial'
          }

          input.firstStartedTrialDateTime = new Date().toISOString();

          if (this.newTenantForm.parentAccountId) {
            input.parentAccountId = this.newTenantForm.parentAccountId;
            input.accountType = CHILD_ACCOUNT;
          }
          this.newTenantForm.featureAccessVehiclePhotoLogsHeraAi  = this.newTenantForm.featureAccessVehiclePhotoLogsHeraAi ?? false
          this.newTenantForm.featureEnabledVehiclePhotoLogsHeraAi  = this.newTenantForm.featureEnabledVehiclePhotoLogsHeraAi ?? false
          this.newTenantForm.featureEnabledInventoryManagement  = this.newTenantForm.featureEnabledInventoryManagement ?? false
          this.newTenantForm.featureAccessInventoryManagement  = this.newTenantForm.featureAccessInventoryManagement ?? false
          this.newTenantForm.permissionHeraAi  = this.newTenantForm.permissionHeraAi ?? false
          this.newTenantForm.permissionVehiclePhotoLogsHeraAi  = this.newTenantForm.permissionVehiclePhotoLogsHeraAi ?? false
          this.newTenantForm.permissionInventoryManagement = this.newTenantForm.permissionInventoryManagement ?? true

          delete input.originationNumber
          const result = await this.api(createTenant, {input: input})

          if(!!result?.data?.createTenant?.id){
            let premiumStatusInput = {
              group: input.group,
              accountPremiumStatus: input.accountPremiumStatus,
              premiumStatusHistoryTenantId: result.data.createTenant.id,
            }        
            await this.api(createPremiumStatusHistory, { input: premiumStatusInput })
          }          

          await updateAvailableNumber(result.data.createTenant)

          await this.createCustomList(result.data.createTenant.group, result.data.createTenant.id)
          await this.createLabelsTypes(result.data.createTenant.group)

          // default coaching thresholds
            await this.resetDefaults('Scorecard', result.data.createTenant.id, groupId)
            await this.resetDefaults('CXFeedback', result.data.createTenant.id, groupId)
            await this.resetDefaults('Mentor', result.data.createTenant.id, groupId)
            await this.resetDefaults('Netradyne', result.data.createTenant.id, groupId)
            await this.resetDefaults('EOC', result.data.createTenant.id, groupId)
            await this.resetDefaults('ProperParkingSequence(PPS)', result.data.createTenant.id, groupId)
          result.data.createTenant.originationNumber = availableNumber?.phoneNumber
          await this.doUpdateZohoAccountTenant(result.data.createTenant)
          
          this.displayUserNotification({
            title: "Success",
            type: "success",
            message: 'The new Tenant was successfully created.'
          });
          this.modalOpen = false
          this.tableData = []
          this.loadData({})
          this.loading = false
          this.$router.push({name: 'SystemTenantDetail', params: { id: result.data.createTenant.id }})
        } catch(e) {
          this.loading = false
          console.error(e)
          this.displayUserError(e)
        }
      },

      async createCustomList(group, tenantId) {
        try {
          let apiName = 'heraPublicApi';
          let path = '/executeMutation';
          let post = {
            body:{
              "type": 'custom-list',
              "mutation": 'create',
              "group": group,
              "tenantId": tenantId,
              "owner": this.userInfo.cognitoSub,
            }
          }

          this.displayUserNotification({
            title: 'Processing...',
            type: "info",
            duration: 5000,
            message: 'Hera is currently creating Custom Lists.'
          })

          safeFunction(API.post)(apiName, path, post);

        } catch (e) {
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e
          })
        }
      },

      
      showNotify(customListType){
        //show notification when creating custom list
        return this.$notify({
            title: 'Info',
            message: `Creating a Custom List of ${customListType}...`,
            type: 'info',
            offset: 100,
            duration: 0
        })
      },

      async createLabelsTypes( group ){
        try{
          await labelsMethods.createDefaultTypes( group, this.api )
        }catch(e){
            console.error(e)
            this.displayUserError(e)
        }
      },
      async resetDefaults(source, tenantId, group){
        try{
            var all = null
            var weeklyOrDaily = source == 'Scorecard' || source == 'CXFeedback' || source == 'ProperParkingSequence(PPS)' ? 'weekly' : 'daily'

            let defaultThresholds = {}
            this[weeklyOrDaily + 'PerformanceMetrics'].forEach(metric =>{
                if(metric.src == source){
                    if(metric.defaults.thresholdIssue ) defaultThresholds['coaching' + metric.name + 'ThresholdIssue'] = metric.defaults.thresholdIssue
                    if(metric.defaults.threshold ) defaultThresholds['coaching' + metric.name + 'Threshold'] = metric.defaults.threshold
                    if(metric.defaults.thresholdPR ) defaultThresholds['coaching' + metric.name + 'ThresholdPR'] = metric.defaults.thresholdPR
                    if(metric.defaults.thresholdKudo ) defaultThresholds['coaching' + metric.name + 'ThresholdKudo'] = metric.defaults.thresholdKudo
                    if(metric.defaults.ratingIssue ) defaultThresholds['coaching' + metric.name + 'RatingIssue'] = metric.defaults.ratingIssue
                    if(metric.defaults.thresholdKudo ) defaultThresholds['coaching' + metric.name + 'RatingKudo'] = metric.defaults.ratingKudo
                    if(metric.defaults.ratingCO) defaultThresholds['coaching' + metric.name + 'RatingCO'] = metric.defaults.ratingCO
                    if(metric.defaults.ratingPR) defaultThresholds['coaching' + metric.name + 'RatingPR'] = metric.defaults.ratingPR
                }
            })

            let defaultEnable = {}
            this[weeklyOrDaily + 'PerformanceMetrics'].forEach(metric =>{
                if(metric.src == source){
                    if(metric.defaults.issue != null && metric.defaults.issue != undefined) defaultEnable['coaching' + metric.name + 'Issue'] = metric.defaults.issue
                    if(metric.defaults.co != null && metric.defaults.co != undefined) defaultEnable['coaching' + metric.name + 'CO'] = metric.defaults.co
                    if(metric.defaults.pr != null && metric.defaults.pr != undefined) defaultEnable['coaching' + metric.name + 'PR'] = metric.defaults.pr
                    if(metric.defaults.kudo != null && metric.defaults.kudo != undefined) defaultEnable['coaching' + metric.name + 'Kudo'] = metric.defaults.kudo
                }
            })

            const objectSplit1 = Object.entries(defaultThresholds).slice(0, 40)
            const objectSplit2 = Object.entries(defaultThresholds).slice(40)

            const defaultThresholds1 = objectSplit1.reduce(function(acc, obj) {
                acc[obj[0]] = obj[1];
                return acc;
            }, {});

            const defaultThresholds2 = objectSplit2.reduce(function(acc, obj) {
                acc[obj[0]] = obj[1];
                return acc;
            }, {});

            // update thresholds  part 1
            var input = {
                id: tenantId,
                group: group,
                ...defaultThresholds1
            }
            await this.api(updateTenantCoaching, {input: input})

            if(objectSplit2.length > 0) {
                // update thresholds part 2
                // Second update is needed to avoid "Invalid UpdateExpression: Expression size has exceeded the maximum allowed size
                var input = {
                    id: tenantId,
                    group: group,
                    ...defaultThresholds2
                }
                await this.api(updateTenantCoaching, {input: input})
            }

            // update enables
            input = {
                id: tenantId,
                group: group,
                ...defaultEnable
            }
            await this.api(updateTenantCoaching, {input: input})
        }
        catch(e){
            console.error(e)
            this.displayUserError(e)
            this.loading = false
        }

      },

      /** Create a cognito group for the new tenant */
      async createCognitoGroup(groupName) {
        let apiName = 'AdminQueries';
        let path = '/createGroup';
        let myInit = {
            body: {
              "groupname": groupName
            },
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        var result = await API.post(apiName, path, myInit);
        return result
      },

      /**
       * On row click go to the StaffDetail route.
       * @param {object} row
       * @param {object} column
       * @param {object} event
       */
      handleRowClick(row, column, event){
        this.$router.push({name: 'SystemTenantDetail', params: { id: row.id }})
      },


      /**
       * Gets a single cognito user
       * @param {string} username
       * */
      async listCognitoUsers(){
        let apiName = 'AdminQueries';
        let path = '/listUsers';
        let myInit = {
            queryStringParameters: {
              "limit": 5,
              "filter": "cognito:user_status = \"FORCE_CHANGE_PASSWORD\""
            },
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        var result = await API.get(apiName, path, myInit);
        console.log(result)
      },


      async updateTenantsOrigination(){

        try {
          var cleanPhone = this.cleanPhoneNumber(this.originationNumberTemp)

          if (cleanPhone.length > 11){
            throw { errors: [{ message: 'invalid phone number: too long'}]}
          }else if (cleanPhone.length < 10){
            throw { errors: [{ message: 'invalid phone number: too short'}]}
          }else if (cleanPhone.length == 10){
            cleanPhone = '+1' + cleanPhone
          }else if (cleanPhone.length == 11){
            cleanPhone = '+' + cleanPhone
          }

          await Promise.all(this.tableData.map( async tenant  => {
            var input = {
                id: tenant.id,
                group: tenant.group,
                originationNumber: cleanPhone
            }

            var result = this.api(updateTenant, {input: input})

          }))
          this.originationModalOpen = false
          this.originationNumberTemp = ''

          this.$message({
            type: 'success',
            message: 'Tenants Updated'
          });

          await this.loadData({})

        }catch(e){
          this.originationModalOpen = false
          console.error(e)
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e.errors[0].message
          })
        }
      },

      async setUserFlags(){
        //load all users
        var users = await this.gLoadListAll (listUsers, {}, 'listUsers')
        console.log(users)
        // return

        try {
          await Promise.all(users.map( async user  => {
            var input = {
                id: user.id,
                group: user.group,
                receiveSmsTaskReminders: user.phone ? true : false,
                receiveEmailTaskReminders: user.email ? true : false,
                receiveSmsTaskAssignments: user.phone ? true : false,
                receiveEmailTaskAssignments: user.email ? true : false,
            }
            // console.log(user.firstName + ' ' + user.lastName)
            // console.log(input)
            var result = this.api(updateUser, {input: input})

          }))

          this.$message({
            type: 'success',
            message: 'Users Updated'
          });

        }catch(e){
          console.error(e)
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e.errors[0].message
          })
        }
      },

      async commitDAStatusesUpdate(){
        try{
          //Update all staff with the inactive status
          var input = {
            status: 'Inactive',
            limit: 1000
          }

          var inactiveStaffs = await this.gLoadListAll(staffsByStatus, input, 'staffsByStatus')
          console.log({inactiveStaffs:inactiveStaffs})
          await Promise.all(inactiveStaffs.map(async(staff)=>{
            var input = {
              id: staff.id,
              group: staff.group,
              status: 'Inactive - Misc'
            }
            await this.api(updateStaff, {input: input})
          }))

          //Update all staff with the medical leave status
          var input = {
            status: 'Medical Leave',
            limit: 1000
          }

          var medicalLeaveStaffs = await this.gLoadListAll(staffsByStatus, input, 'staffsByStatus')
          console.log({medicalLeaveStaffs:medicalLeaveStaffs})
          await Promise.all(medicalLeaveStaffs.map(async(staff)=>{
            var input = {
              id: staff.id,
              group: staff.group,
              status: 'Inactive - Medical Leave'
            }
            await this.api(updateStaff, {input: input})
          }))

          //Update all staff with the terminated status
          var input = {
            status: 'Terminated',
            limit: 1000
          }

          var terminatedStaffs = await this.gLoadListAll(staffsByStatus, input, 'staffsByStatus')
          console.log({terminatedStaffs:terminatedStaffs})
          await Promise.all(terminatedStaffs.map(async(staff)=>{
            var input = {
              id: staff.id,
              group: staff.group,
              status: 'Inactive - Terminated'
            }
            await this.api(updateStaff, {input: input})
          }))

          this.dataFixModalOpen = false
          this.displayUserNotification({
            title: "Success",
            type: "success",
            message: `${this.$t('label.associate')} update successful`
          })

        }
        catch(e){
          console.error(e)
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e.message
          })
        }
        finally{
          this.loading = false;
        }
      },


      /**
       * Confirms if user wants to delete the old DA inactive statuses
       */
      daStatusConfirmation(){
        this.$confirm(`This will update all ${this.$t('label.associates')} will outdated status values. Continue?`, 'Warning', {
          confirmButtonText: 'Update',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.commitDAStatusesUpdate()
        }).catch(() => {
          console.error("canceled update")
        });
      },

      // this is part of HERA-472. It can be removed when HERA-472 is no longed needed.
      async generateReport() {
                this.loading = true

        let reportData = []

        for (const key in this.seeds) {

          try {
            var list = await this.getValueListsByKey(key)
            let result = this.compareItems(key, list)
            reportData.push(...result)

          } catch(e){
            console.error(e)
          }
        }

        reportData.sort((a, b) => {
          return ('' + a.companyName.toLowerCase()).localeCompare(b.companyName.toLowerCase());
        })

        this.exportCSV(reportData)
        this.dataFixModalOpen = false
        this.loading = false
      },

      // this is part of HERA-472. It can be removed when HERA-472 is no longed needed.
      compareItems(key, list) {
        let seedItems = this.seeds[key].values,
            dspList = []

        list.forEach(item => {
          if(item) {
            item.statesList = []

            item.valuesList.forEach(valueItem => {
              if(!valueItem.hidden && !valueItem.deleted) {
                if(!seedItems.includes(valueItem.value)) {
                  dspList.push(item)
                  item.statesList.push(valueItem.value)
                }
              }

            })
          }
        })

        dspList.forEach(item => delete item.valuesList)

        return [...new Set(dspList)]
      },

      // this is part of HERA-472. It can be removed when HERA-472 is no longed needed.
      async getValueListsByKey(key) {
        this.loading = true
        var valueLists
        var input = {
          key,
          limit: 1000
        }

        valueLists = await this.gLoadListAllValueLists(valueListsByKey, input, 'valueListsByKey')

        return valueLists.map(item => {
          if(item) {
            return {
              id: item.tenant.id,
              companyName: item.tenant.companyName.replaceAll(',',''),
              key: item.key,
              valuesList: item.items.items
            }
          }
        })
      },

      // this is part of HERA-472. It can be removed when HERA-472 is no longed needed.
      exportCSV(arrData) {
        const fileName = `report-dsplist-${this.$moment().format('MM/DD/YYYY')}.csv`
        exportCSV({arrData, fileName})
        this.showExportDialog = false
      },

      gLoadListAllValueLists: async (query, input, queryName) => {
        var nextToken = null
        var list = []
        do{
            input.nextToken = nextToken
            var response
            try{
              response = await API.graphql(graphqlOperation(query, cleanVars(input, query)))
            }
            catch(err){
              response = err
            }

            list = [ ...list, ...response.data[queryName].items]
            nextToken = response.data[queryName].nextToken
        } while (nextToken)

        return list
      },
      async setMessageService() {
        this.loading = true;
        try{
          //Loop through every value list
          const listTenant = await this.gLoadListAll(searchTenants, {nextToken: null}, 'searchTenants');
          await listTenant.map(async (tenant)=>{
            let input = {
              id: tenant.id,
              group: tenant.group,
              messageServiceProvider: 'Bandwidth',
            }

            if(!tenant.originationNumber || tenant.originationNumber==='+11111111111') {
              input.messageServiceProvider = 'None';
            }

            await this.api(updateTenantMessageService, {input: input})

            let inputForHistory ={
              previousMessageServicerProvider: tenant.messageServiceProvider,
              currentMessageServicerProvider: input.messageServiceProvider,
              date: this.$moment(),
              messageServiceProviderTenantId: tenant.id,
              group: tenant.group,
            }
            await this.api(createMessageServiceProvider, {input:inputForHistory})
          })
        }
        catch(e){
          console.error(e)
          this.loading = false;
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e.message
          })
        }
        finally{
          this.displayUserNotification({
            title: "Success",
            type: "success",
            message: 'Tenants Updated'
          });
          this.dataFixModalOpen= false;
          this.loading = false;
        }
      },
      async setDaTierCdfScore() {
        this.loading = true;
        try{
          console.log('called setDaTierCdfScore')
          const listTenant = await this.gLoadListAll(searchTenants, {nextToken: null}, 'searchTenants');
          await listTenant.map(async (tenant)=>{

          // coachingDaTierThresholdIssue
          // coachingDaTierThresholdKudo
          // coachingDaTierIssue
          // coachingDaTierKudo
          // coachingDaTierRatingIssue
          // coachingDaTierRatingCO
          // coachingDaTierRatingPR
          // coachingDaTierCO
          // coachingDaTierPR
          // coachingDaTierRatingKudo

          // coachingCdfScoreThresholdIssue
          // coachingCdfScoreThreshold
          // coachingCdfScoreThresholdPR
          // coachingCdfScoreThresholdKudo
          // coachingCdfScoreIssue
          // coachingCdfScoreKudo
          // coachingCdfScoreRatingIssue
          // coachingCdfScoreCO
          // coachingCdfScorePR
          // coachingCdfScoreRatingKudo

            let input = {
              id: tenant.id,
              group: tenant.group,
              coachingCdfScoreCO: false,
              coachingCdfScoreIssue: false,
              coachingCdfScoreKudo: false,
              coachingCdfScorePR: false,
              coachingCdfScoreThreshold: 100,
              coachingCdfScoreThresholdIssue: 96,
              coachingCdfScoreThresholdKudo: 100,
              coachingCdfScoreThresholdPR: 100,
              coachingDaTierCO: true,
              coachingDaTierIssue: true,
              coachingDaTierKudo: true,
              coachingDaTierPR: true,
              coachingDaTierRatingCO: 'Great',
              coachingDaTierRatingIssue: 'Fair',
              coachingDaTierRatingKudo: 'Fantastic',
              coachingDaTierRatingPR: 'Fantastic',
              coachingDaTierThresholdIssue: 0,
              coachingDaTierThresholdKudo: 0
            }

            console.log('---tenant input', JSON.stringify(input))
            await this.api(updateTenant, {input: input})

          })
        } catch(e){
          console.error('---setDaTierCdfScore', e)
          this.loading = false;
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e.message
          })
        } finally{
          this.displayUserNotification({
            title: "Success",
            type: "success",
            message: 'Setting DaTier and CdfScor Done'
          });
          this.dataFixModalOpen= false;
          this.loading = false;
        }
      },
      
      getFilterStatusChecked(){
        const balanceDue = this.balanceDueOptions.find(item => item.value == this.balanceDueSelected)?.label || "";
        return [
          {
            filter: 'Customer Status',
            values: this.filterStatusChecked
          },
          {
            filter: 'Type',
            values: this.filterTypeChecked
          },
          {
            filter: 'Feature Access',
            values: this.filterFeatureAccessChecked
          },
          {
            filter: 'Feature Enabled',
            values: this.filterFeatureEnabledChecked
          },
          {
            filter: 'Sub-Type',
            values: this.filterSubTypeChecked
          },
          {
            filter: 'Permanence',
            values: this.filterTimeChecked
          },
          {
            filter: 'Country',
            values: this.filterCountryChecked
          },
          {
            filter: 'Testing',
            values: this.filterTestingChecked
          },
          {
            filter: 'Balance Due',
            values: [balanceDue]
          }
        ]
      },
      async doUpdateZohoAccountTenant(tenant){
        let _payload = {
          id: tenant.zohoCrmAccountRecordId,
                    Tenant_ID: tenant.id,
                    Account_Name: tenant.companyName,
                    Short_Code:tenant.shortCode,
                    Uses_XL_Coaching:tenant.customerSubType === CUSTOMER_SUB_TYPE_XL ? "Yes" : "No",
                    Origination_Number:tenant.originationNumber,
                    Customer_Status:tenant.customerStatus,
                    Cancellation_Reason:tenant.accountCanceledReason,
                    Cancellation_Notes:tenant.accountCanceledNotes,
                    Discount_Percent:tenant.discountPercent ? tenant.discountPercent.toString() : "",
                    Discount_Percent_Label:tenant.discountPercentLabel,
                    Discount_Fixed:tenant.discountFixed ? tenant.discountFixed.toString() : "",
                    Discount_Fixed_Labeld:tenant.discountFixedLabel,
                    Trial_Expiration_Date1:tenant.trialExpDate.split('T')[0],
                    Timezone:tenant.timeZone,
                    Group: tenant.group
        }
        if(tenant.parentAccount && tenant.parentAccount.zohoCrmAccountRecordId){
          _payload.Parent_Account = {id: tenant.parentAccount.zohoCrmAccountRecordId}
        }
        try {
          if(!this.$store.getters.isZohoCrmDisabled)
              await updateZohoAccountTenant('update', _payload, this.displayUserError)
          } catch (error) {
            console.error('error updating zoho account', error)
          }
      },

      getParsingDataGrowSurf(data){
        if(!data) return {}
        const { firstName, lastName, email, referrer } = data
        const parseData = {
          growSurfReferredByFullNameBackup: (referrer) ? `${referrer.firstName} ${referrer.lastName}` : null,
          growSurfReferredByEmailBackup: (referrer) ? `${referrer.email}` : null,
          growSurfParticipantFullName: (firstName) ? `${firstName} ${lastName}` : null,
          growSurfParticipantEmail: email ?? null
        }
        return parseData
      },

      async getDataByApiGrowSurf(participantId){
        if(!participantId) return 
        try{
          const apiName = 'tenant'
          const path = '/growSurf/' + participantId
          const {dataGrowSurf} = await API.get(apiName, path)
          return dataGrowSurf
        }catch(e){
            console.error(e)
        }
      }

    }
  }

// this is part of HERA-472. It can be removed when HERA-472 is no longed needed.
function cleanVars(variables, query){
//Remove created/updated timestamps from mutations.
if( variables.input ){
    if(variables.input.createdAt && !query.includes('mutation UpdateMessage')){
    delete variables.input.createdAt
  }
  if(variables.input.updatedAt){
    delete variables.input.updatedAt
  }
  if(!variables.input.group && !query.includes("$input: Delete")){
    variables.input.group = store.state.userInfo.tenant.group
  }
}

return variables
}
</script>

<style>
.el-message-box__header .el-message-box__title{
  padding-left: 0px !important;
}
.el-dialog__body{
  padding-top: 0
}

</style>
