<template>
  <div >
    <!-- CARD GRID -->
    <div class="mt-4 grid grid-cols-8 grid-flow-row gap-4">
        <!-- TERMS AND CONDITIONS -->
        <div class="col-span-4 border-2 rounded-lg shadow max-h-120  overflow-y-scroll">
          <!-- Termly Tracking Code -->
          <div class="-mt-12" name="termly-embed" data-id="968db6eb-0700-41c4-a39c-d0ab4592474e" data-type="iframe"></div>

        </div>


        <!-- ESTIMATED COST CONTENT -->
        <div class="col-span-4 relative p-4 border-2 rounded-lg shadow">
          <div class="text-gray-600 text-lg font-bold">ORDER SUMMARY</div>
           <EstimatedTotal
            :selected="selected"
            :costPerDriver="costPerDriver"
            :numActiveStaff="numActiveStaff"
            :flatMonthlyBillingAmount="tenant.flatMonthlyBillingAmount"
           />

          <div class="w-full absolute bottom-0 mb-4 pr-8 mt-20">
            <div class="mt-4 flex">
              <el-button class="flex-1" :disabled="loading" @click="showPurchaseOptions">Back</el-button>
              <el-button data-cy="step2-continue-btn" type="primary" class="flex-1" :disabled="loading" @click="checkout">Continue</el-button>
            </div>
          </div>
        </div>

      </div>

  </div>
</template>

<script>
import EstimatedTotal from './EstimatedTotal'

export default {
  name: 'OrderSummary',
  props: ['selected', 'costPerDriver', 'numActiveStaff', 'tenant'],
  components:{
    EstimatedTotal
  },
  data(){
    return{
      loading: false
    }
  },

  created(){
    var d = document
    var s = 'script'
    var id = 'termly-jssdk'
    var js, tjs = d.getElementsByTagName(s)[0];
    // if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://app.termly.io/embed-policy.min.js";
    tjs.parentNode.insertBefore(js, tjs);
  },

  methods:{
    showPurchaseOptions(){
      this.$emit('show-purchase-options')
    },

    checkout(){
      this.$emit('checkout')
    }

  }
}
</script>

<style scoped>

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all .3s ease;
}
/* .slide-fade-leave-active {
  transition: all .8s cubic-bezier(0.8, 1.0, 1.0, 0.5);
} */
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
