<template>
  <div >
    <!-- CARD GRID -->
    <div class="mt-4 grid grid-cols-8 grid-flow-row gap-4">

        <!-- TRIAL CARDS -->
          <div v-if="showTrial" class="p-4 rounded-lg shadow col-span-8 border-2" :class="{'border-brand': !this.selected.standard && !this.selected.bundle}">
            <div class="flex">
              <div class="flex-1">
                <p class="text-gray-600 text-lg font-bold ">HERA TRIAL</p>
                <ul class="list-disc mt-2">
                  <!-- <li class="text-xs ml-4">30 day free trial</li>
                  <li class="text-xs ml-4">Access to all premium add-ons</li>
                  <li class="text-xs ml-4">Upgrade at any time</li> -->
                </ul>
              </div>
              <div class="flex-initial text-center">
                <p class=" text-2xl font-bold text-gray-600">{{trialDaysRemainingDisplay}}</p>
                <p class="text-xs mb-2 text-gray-600">Days Remaining</p>
              </div>
            </div>
          </div>

          <div v-if="trialDaysRemaining < 0 && showTrial" class="col-span-8 bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
            <div class="flex">
              <div class="py-1"><svg class="fill-current h-6 w-6 text-red-500 mr-4  transform rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
              <div>
                <p class="font-bold">Trial expired.</p>
                <!-- <p class="text-sm">Please select a plan below to regain access to Hera.</p> -->
                <p class="text-sm">Please select "Hera Subscription" below to regain access to Hera.</p>
              </div>
            </div>
          </div>
          <div v-else-if="trialDaysRemaining < 8 && trialDaysRemaining > -1 && showTrial" class="col-span-8 bg-yellow-100 border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md" role="alert">
            <div class="flex">
              <div class="py-1"><svg class="fill-current h-6 w-6 text-yellow-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
              <div>
                <p class="font-bold">Your free trial will expire on {{$store.state.userInfo.tenant.trialExpDate | humanDate}}.</p>
                <!-- <p class="text-sm">Please select a plan below to maintain access to Hera.</p> -->
                <p class="text-sm">Please select "Hera Subscription" below to maintain access to Hera.</p>
              </div>
            </div>
          </div>

        <!-- HERA STANDARD -->
        <!-- <div
          data-cy="hera-standard-card"
          class="p-4 rounded-lg shadow col-span-4 flex border-2 cursor-pointer hover:border-gray-600"
          :class="{'border-brand': this.selected.standard}"
          @click="choose('standard')">
          <div class="flex-1">
            <p class="text-gray-600 text-lg font-bold ">BASE PLAN</p>
            <ul class="list-disc mt-2">
              <li class="text-xs ml-4">Access to all non-premium features</li>
              <li class="text-xs ml-4">Choose individual premium add-ons</li>
              <li class="text-xs ml-4">Deactivate premium add-ons at any time</li>
            </ul>
          </div>
          <div class="flex-initial text-center">
            <p class=" text-2xl font-bold text-gray-600">${{costPerDriver.standard}}</p>
            <p class="text-xs mb-2 text-gray-600">per {{$t('label.associate')}} per month<sup>1,3</sup></p>
          </div>
        </div> -->

        <!-- HERA PREMIUM BUNDLE -->
        <div
          data-cy="hera-premium-bundle-card"
          class="p-4 flex border-2 rounded-lg shadow col-span-4 cursor-pointer hover:border-gray-600"
          :class="{'border-brand': this.selected.bundle}"
          @click="choose('bundle')">
          <div class="flex-1">
            <!-- <p class="text-gray-600 text-lg font-bold">PREMIUM BUNDLE</p> -->
            <p class="text-gray-600 text-lg font-bold">HERA SUBSCRIPTION</p>
            <ul class="list-disc mt-2">
              <!-- <li class="text-xs ml-4">Premium Performance and Coaching</li>
              <li class="text-xs ml-4">Premium Daily Rostering</li>
              <li class="text-xs ml-4">Premium {{$t('label.associate')}} Management</li>
              <li class="text-xs ml-4">Premium Vehicle Management</li> -->
              <li class="text-xs ml-4">Performance and Coaching</li>
              <li class="text-xs ml-4">Daily Rostering</li>
              <li class="text-xs ml-4">{{$t('label.associate')}} Management</li>
              <li class="text-xs ml-4">Vehicle Management</li>
              <li class="text-xs ml-4">Hera Messenger</li>
            </ul>
          </div>
          <div class="flex-initial text-center" v-if="tenant.flatMonthlyBillingAmount === 0">
            <p class=" text-2xl font-bold text-gray-600">${{bundleCostPerDriver}}</p>
            <!-- <p class="text-xs mb-2 text-gray-600">per {{$t('label.associate')}} per month<sup>2,3</sup></p> -->
            <p class="text-xs mb-2 text-gray-600">per {{$t('label.associate')}} per month<sup>1</sup></p>
            <!-- <p class="text-xs mb-2 text-gray-600 w-40">One year contract required</p> -->
            <!-- <p class="text-xs mb-2 text-gray-600 w-40">Includes $1 per {{$t('label.associate')}} per month discount</p> -->
          </div>
          <div class="flex-initial text-center" v-if="tenant.flatMonthlyBillingAmount > 0">
            <p class=" text-2xl font-bold text-gray-600">${{tenant.flatMonthlyBillingAmount}}</p>
            <p class="text-xs mb-2 text-gray-600">per Month</p>
          </div>
        </div>


        <!-- UPGRADE INDIVIDUALLY CONTENT -->
        <!-- <transition name="slide-fade">
          <div class="grid grid-cols-1 grid-flow-row gap-4 col-span-4" v-show="showOptions">
            <PremiumFeaturesChoice
              data-cy="f-performance-coaching"
              name="Premium Performance &amp; Coaching"
              slug="performance"
              :expDate="tenant.premiumFeatureExpiration"
              :selected="selected.performance"
              :features="performanceFeatures"
              :costPerDriver="costPerDriver.performance"
              :bundleSelected="selected.bundle"
              @choose="choose"/>

            <PremiumFeaturesChoice
              data-cy="f-daily-rostering"
              name="Premium Daily Rostering"
              slug="rostering"
              :expDate="tenant.premiumFeatureExpiration"
              :selected="selected.rostering"
              :features="rosteringFeatures"
              :costPerDriver="costPerDriver.rostering"
              :bundleSelected="selected.bundle"
              @choose="choose"/>

            <PremiumFeaturesChoice
              data-cy="f-da-management"
              name="Premium Associate Management"
              slug="staff"
              :expDate="tenant.premiumFeatureExpiration"
              :selected="selected.staff"
              :features="staffFeatures"
              :costPerDriver="costPerDriver.staff"
              :bundleSelected="selected.bundle"
              @choose="choose"/>

            <PremiumFeaturesChoice
              data-cy="f-vechicle-management"
              name="Premium Vehicle Management"
              slug="vehicles"
              :expDate="tenant.premiumFeatureExpiration"
              :selected="selected.vehicles"
              :features="vehiclesFeatures"
              :costPerDriver="costPerDriver.vehicles"
              :bundleSelected="selected.bundle"
              @choose="choose"/>
          </div>
        </transition> -->

        <!-- ESTIMATED COST CONTENT -->
        <div class="col-span-4 relative p-4 border-2 rounded-lg shadow" v-show="showOptions" >
          <div class="text-gray-600 text-lg font-bold">ESTIMATED TOTAL</div>

          <EstimatedTotal
            :selected="selected"
            :costPerDriver="costPerDriver"
            :numActiveStaff="numActiveStaff"
            :flatMonthlyBillingAmount="tenant.flatMonthlyBillingAmount"
          />

          <div class="w-full absolute bottom-0 mb-4 pr-8 mt-20">
            <div class="mt-4 flex">
              <el-button class="flex-1" v-if="editOptions" :disabled="loading" @click="cancelEdit">Cancel</el-button>
              <el-button data-cy="step1-continue-btn" type="primary" class="flex-1" :disabled="loading" @click="showOrderSummary">Continue</el-button>
            </div>
          </div>
        </div>
      </div>

    <div class="mt-4 text-xs text-gray-600" v-if="tenant.flatMonthlyBillingAmount === 0">
      <!-- <p>1. The base plan is required to use Hera. A 30 day free trial is given to all new accounts. Additional premium add-ons can be purchased at an additional cost.</p> -->
      <!-- <p>2. One year contract required. After one year, you can downgrade, select individual premium add-ons to upgrade, or renew your premium bundle.</p> -->
      <p>1. Billed monthly based on number of active {{$t('label.associates')}}. Charges are calculated on a per day basis.</p>
      <!-- <p  v-show="showOptions">4. You can deactivate an individual premium add-on 24 hours after upgrading.</p> -->
    </div>
  </div>
</template>

<script>
import { Auth, API } from 'aws-amplify';
import PremiumFeaturesChoice from './PremiumFeaturesChoice'
import EstimatedTotal from './EstimatedTotal'
import { listSystems } from '../queries'

export default {
  name: 'OrderSelect',
  props: ['tenant', 'numActiveStaff', 'editOptions', 'prevSelection'],
  components:{
    PremiumFeaturesChoice,
    EstimatedTotal
  },
  data(){
    return{
      system: {},
      loading: false,
      costPerDriver: {
        standard: 2,
        performance: 3,
        rostering: 1,
        staff: 3,
        vehicles: 1,
      },

      performanceFeatures: [
        'Import weekly CX and POD reports and use in coaching opportunities',
        'Import daily Mentor data and use in coaching opportunities',
        'Send coaching opportunities via SMS and email',
        'View and manage a list of pending staff counselings',
        'Create counseling forms for drivers to sign',
        'Send counseling alerts via SMS and email',
        'Attrition forecasting',
        'Report on your best and worst performing drivers over time',
        'Allow staff to grade driver performance through field assessments (Coming Soon)',
      ],
      rosteringFeatures: [
        'Send roster information to drivers via SMS and email',
        'Broadcast stand-up announcements to your team via SMS and email',
        'Import roster data from your Amazon Daily Roster',
        'Track driver attendance and trigger counseling requirements',
        'Inline Key Focus Area Coaching on Daily Roster'
      ],
      staffFeatures: [
        'Track drug tests, OSHA injury reports, parking tickets, Amazon Violation/Defects, rescues',
        'Assign vehicles to drivers for faster and COVID-ready rostering',
        `Hera Messenger: Send and receive messages with ${this.$t('label.associates')} via SMS`,
        'Receive alerts for expiring drivers licenses (per compliance requirements)',
        'Assign and manage tasks for users'
      ],
      vehiclesFeatures: [
        'Create Accident Records, saving paper and time',
        'Assign rabbits to vehicles for faster rostering',
        'Receive maintenance and checkup reminders',
      ],

      selected: {
        standard: false,
        bundle: false,
        performance: false,
        rostering: false,
        staff: false,
        vehicles: false,
      }
    }
  },

  mounted(){
    this.tenant.accountPremiumStatus.forEach(type =>{
      if(type != 'trial') this.selected[type] = true
    }),

    this.loadData()
  },

  computed: {

    bundleCostPerDriver(){
      return this.costPerDriver.standard +
      this.costPerDriver.performance +
      this.costPerDriver.rostering +
      this.costPerDriver.staff +
      this.costPerDriver.vehicles - this.system.defaultCostBundle ;
    },

    showOptions(){
      return this.selected.standard || this.selected.bundle
    },

    trialDaysRemainingDisplay(){
      if(!this.tenant.trialExpDate) return ''
      else{
        var daysRemaining = Math.ceil( ( new Date(this.tenant.trialExpDate).getTime() -  new Date().getTime()) / (1000 * 3600 * 24) )
        return daysRemaining < 0 ? 0 : daysRemaining
      }
    },

    trialDaysRemaining(){
      if(!this.tenant.trialExpDate) return ''
      else{
        var daysRemaining = Math.ceil( ( new Date(this.tenant.trialExpDate).getTime() -  new Date().getTime()) / (1000 * 3600 * 24) )
        return daysRemaining
      }
    },

    showTrial(){
      return !this.$store.getters.isTrialExpired
    }
  },

  methods:{

    choose(feature){
      var currentTime = new Date()
      var endTime = new Date(this.tenant[feature + 'DeactivationAllowedAt'])
      var expTimePassed = currentTime.getTime() >= endTime.getTime()
      var noExpTime = !this.tenant[feature + 'DeactivationAllowedAt'] || !this.tenant.accountPremiumStatus.includes(feature)

      // choose freely
      if(expTimePassed || noExpTime || (feature != 'bundle' && this.selected.bundle)){
        this.selected[feature] = !this.selected[feature]
        if(this.selected[feature] == true && (feature != 'bundle')){
          if(this.tenant.accountPremiumStatus.includes('performance')) this.selected.performance = true
          if(this.tenant.accountPremiumStatus.includes('rostering')) this.selected.rostering = true
          if(this.tenant.accountPremiumStatus.includes('staff')) this.selected.staff = true
          if(this.tenant.accountPremiumStatus.includes('vehicles')) this.selected.vehicles = true
          this.selected.bundle = false
          this.selected.standard = true

          // automatcialy select bundle if they choose all the features
          // if(this.selected.standard && this.selected.performance && this.selected.rostering && this.selected.staff && this.selected.vehicles){
          //   this.selected.bundle = true
          //   this.selected.standard = false
          //   this.selected.performance = false
          //   this.selected.rostering = false
          //   this.selected.staff = false
          //   this.selected.vehicles = false
          // }

        }
        else if((this.selected[feature] == true) && (feature == 'bundle')){
          this.selected.standard = false
          this.selected.performance = false
          this.selected.rostering = false
          this.selected.staff = false
          this.selected.vehicles = false
        }
      }
      // have to wait until downgrade time expires
      else{
        this.displayUserNotification({
          type: 'warning',
          title: 'Warning',
          message: 'Individual premium add-ons can be deactivated 24 hours after being ugraded.'
        });
      }

    },

    showOrderSummary(){
      this.$emit('show-order-summary', {selected: this.selected, costPerDriver: this.costPerDriver})
    },

    cancelEdit(){
      this.$emit('cancel-edit')
    },

    /**
     * Load data using the listSystems custom GraphQL query
     */
    async loadData(){
      this.loading = true
      try{
        if(this.prevSelection){
          this.selected = this.prevSelection
        }
        var system = await this.api(listSystems)
        system = system.data.listSystems.items[0]
        if(!system) system = {}
        this.system = system

        this.costPerDriver.standard = this.system.defaultCostStandard
        this.costPerDriver.performance = this.system.defaultCostPerformance
        this.costPerDriver.rostering = this.system.defaultCostRostering
        this.costPerDriver.staff = this.system.defaultCostStaff
        this.costPerDriver.vehicles = this.system.defaultCostVehicles

        this.loading = false
      }catch(e){
        console.error(e)
        this.loading = false
        this.displayUserError(e)
      }
    },

  }
}
</script>

<style scoped>
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .3s ease;
  }
  /* .slide-fade-leave-active {
    transition: all .8s cubic-bezier(0.8, 1.0, 1.0, 0.5);
  } */
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>
