<template>
  <div class="multiselect-filter mx-2">
    <el-dropdown :trigger="dropdownTrigger" :hide-on-click="false" :disabled="isDisabled" ref="parentMenu">
      <span class="el-dropdown-link flex items-baseline">
        <span :class="{'text-sm font-bold': isFilterActive}">{{ title }}</span>
        <span class="ml-1 py-0.5 px-1 rounded-lg bg-gray-100" >
          {{ counterFilterAsText }}
        </span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <div class="h-2">
          <i @click="closeSubNav" class="el-icon-close toggle-close-icon active absolute right-1 top-1 p-1 text-lg opacity-50"></i>
        </div>
        <el-dropdown-item key="all">
          <el-checkbox 
            v-if="showAllOptionInDropdown" 
            v-model="isCheckedAll"
            @change="handleAllChecked"
            :disabled="disabledAllOptionInDropdown"
          >
            {{filterWordBeforeTitle}} {{ filterAllTitle || title }}
          </el-checkbox>
        </el-dropdown-item>
        <el-dropdown-item v-if="showAllOptionInDropdown" divided />
        <el-checkbox-group
          v-model="filters"
          :min="1" 
          class="flex flex-col" 
          :disabled="isCheckedAll"
          @change="handleChecked()"
        >
          <el-dropdown-item v-for="(item, i) in filterOptions" :label="item" :key="i" class="flex content-between row-filter"> 
            <el-checkbox :label="item" class="w-full relative" :ref="item" :value="true">
              <span :class="{'style-font-italic': item == uuidOfBlankOption}">{{ item == uuidOfBlankOption ? ` No ${title}` : item }}</span>
              <el-tooltip class="item leading-10 z-40 " effect="dark" placement="bottom" v-if="showUncheckedTooltip(item)">
                <div slot="content"> 
                    <p class="font-bold mb-1 text-baseline">You cannot uncheck this option because you must have at least one option checked.</p>
                </div>
                <span class="tooltip"></span>
              </el-tooltip>
            </el-checkbox>
            <span v-if="!isCheckedAll">
              <span v-if="showOnlyLink(item)" class="text-xs pl-2" :ref="`ref-${item}`" @click="handleOnly(item)">only</span>
            </span>
          </el-dropdown-item>
        </el-checkbox-group>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>

export default {
  name: "MultiSelectFilterV2",

  props: {
    title: {
      type: String,
      required: true,
      default: "MultiSelect Filter"
    },

    filterAllTitle: {
      type: String,
      default: ''
    },

    filterWordBeforeTitle: {
      type: String,
      required: false,
      default: 'All'
    },
    
    type: {
      type: String,
      required: true,
      default: "default"
    },

    options: {
      type: Array,
      required: true,
      default: () => []
    },

    selected: {
      type: Array,
      required: false,
      default: () => []
    },

    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    showIsBlankOption: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    counterFilterAsText() {
      if (this.isCheckedAll) return this.filterWordBeforeTitle
      if (this.itsIncluded(this.filterOptions, this.filters)) return this.filterWordBeforeTitle
      return this.counterFiltersAsNumber
    },

    counterFiltersAsNumber() {
      return this.filters.length
    },

    showAllOptionInDropdown() {
      return this.filterOptions.length > 1
    },
    
    disabledAllOptionInDropdown() {
      return this.options.length < 1
    },

    isFilterActive() {
      return this.counterFilterAsText != this.filterWordBeforeTitle && this.counterFilterAsText != '0'
    },

    isAllByParam() {
      return String(this.$route.query[this.type])?.includes("-1")
    },

    isBlankByParam() {
      return String(this.$route.query[this.type])?.includes("-2")
    },

    filterByParamIsUndefined() {
      return typeof this.$route.query[this.type] == "undefined"
    },

    filterOptions() {
      if (this.showIsBlankOption) {
        return [...this.options, this.uuidOfBlankOption]
      }
      return this.options
    }
  },
  data() {
    return {
      uuidOfBlankOption: '',
      filters: [],
      isCheckedAll: false,
      dropdownTrigger: 'hover',
      heraCode: 'H3R45765'
    }
  },
  watch: {
    selected() {
      this.isCheckedAll = false
      if (!this.filterByParamIsUndefined) {
        this.handleCheckboxStatus()
        return
      }
      this.handleCheckboxStatusWhenFilterByParamIsUndefined()
    }
  },
  mounted() {
    this.uuidOfBlankOption = this.generateUUIDFilter()
    if (!this.filterByParamIsUndefined) {
      this.handleCheckboxStatus()
      return
    }
    this.handleCheckboxStatusWhenFilterByParamIsUndefined()
  },
  beforeMount(){
    if(window.innerWidth < 769){ 
      this.dropdownTrigger = 'click'
    }
  },
  methods: {
    handleCheckboxStatusWhenFilterByParamIsUndefined() {
      let selectedAvailable = this.selected.filter(f => this.options.includes(f))

      this.filters = [...selectedAvailable]

      let isAllSelectedButNotBlankOption = this.areEqualsFiltersAndOptions(selectedAvailable, this.filterOptions.filter(f => f != this.uuidOfBlankOption))

      if (isAllSelectedButNotBlankOption && this.selected.some(str => str.includes(this.heraCode))) {
        this.filters = [...selectedAvailable, this.uuidOfBlankOption]
        this.isCheckedAll = true
      }

      if (isAllSelectedButNotBlankOption && !this.selected.some(str => str.includes(this.heraCode)) && !this.showIsBlankOption) {
        this.filters = [...selectedAvailable]
        this.isCheckedAll = true
      }

      if (!isAllSelectedButNotBlankOption && this.selected.length > 1 && this.selected.some(str => str.includes(this.heraCode))) {
        this.filters = [...selectedAvailable, this.uuidOfBlankOption]
      }

      if (this.selected.length == 1 && this.selected[0].includes(this.heraCode)) {
        this.filters = [this.uuidOfBlankOption]
      }
      
      this.handleDisabledOfCheckbox()

      this.emitFilterStatus()
    },
    handleCheckboxStatus() {
      let selected = this.selected.filter(f => this.options.includes(f))

      // Define filters
      this.filters = [...selected]
      if (this.isBlankByParam || this.isAllByParam) {
        this.filters = [...selected, this.uuidOfBlankOption]
      }

      // Define isCheckedAll
      let isFiltersApplyEqualsToOptions = this.areEqualsFiltersAndOptions(this.filters, this.filterOptions)
    
      if (this.isAllByParam) { 
        this.isCheckedAll = true
      }

      if (!this.isAllByParam && isFiltersApplyEqualsToOptions) {
        this.isCheckedAll = true
      }
    
      //
      this.handleDisabledOfCheckbox()

      //
      this.emitFilterStatus()
    },
    handleDisabledOfCheckbox() {
      if (this.isCheckedAll) {
        setTimeout(() => {this.addIsDisabled()}, 200)
      } else {
        this.removeIsDisabled([...this.filters])
      }
    },
    generateUUIDFilter() {
      return `${this.heraCode}-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    emitFilterStatus() {
      this.$emit('status', {
        'applyAllFilters': !this.isFilterActive,
        'applyBlankFilter': this.filters.includes(this.uuidOfBlankOption),
        'filters': this.filters.filter(f => f != this.uuidOfBlankOption)
      })
    },
    closeSubNav(){
      const mp = this.$refs.parentMenu
      mp.hide() 
    },
    handleChecked() {      
      if(this.areEqualsFiltersAndOptions(this.filters, this.filterOptions)){
        this.isCheckedAll = true
      }
      this.emitFilterStatus()

      if (this.isCheckedAll) {
        this.addIsDisabled()
      } else {
        this.removeIsDisabled([...this.filters])
      }

      this.updateFiltersApplied();
    },
    handleOnly(item){
      this.isCheckedBlank = false;
      this.filters = [item] 

      this.emitFilterStatus()
      this.removeIsDisabled([item])
      this.updateFiltersApplied();
    },

    handleAllChecked() {

      if(!this.isCheckedAll) {
        if (this.itsIncluded(this.filterOptions, this.filters)) {
          return
        }
      }

      this.filters = this.filterOptions;

      if (this.isCheckedAll) {
        this.addIsDisabled()
      }

      if (!this.isCheckedAll) {
        this.removeIsDisabled([...this.filters])
      }

      this.emitFilterStatus()
      this.updateFiltersApplied();
    },

    areListsEqual(listA, listB) {
      // Check if the lengths are equal
      if (listA.length !== listB.length) {
        return false;
      }

      // Check if both arrays have the same elements, regardless of order
      return listA.every(element => listB.includes(element));
    },

    updateFiltersApplied() {
      let type = this.type;
      
      let indexes = [];

      if (this.showIsBlankOption) {
        if (this.areListsEqual(this.filters, this.filterOptions)) {
          indexes = [-1]
        }
        
        if (!this.areListsEqual(this.filters, this.filterOptions)) {
          if (this.filters.includes(this.uuidOfBlankOption)) {
            indexes = [
              ...this.filters.filter(f => f != this.uuidOfBlankOption).map(item => this.filterOptions.indexOf(item)).filter(s => s != -1),
              -2
            ];
          } else {
            indexes = this.filters.map(item => this.filterOptions.indexOf(item)).filter(s => s != -1);
          }
        }
      }

      if (!this.showIsBlankOption) {
        if (this.areListsEqual(this.filters, this.options)) {
          indexes = [-1]
        }

        if (!this.areListsEqual(this.filters, this.options)) {
          indexes = this.filters.map(item => this.options.indexOf(item)).filter(s => s != -1);
        }
      }
      const isSameRoute = this.$route.query[type] === indexes.join(',');
      if (!isSameRoute) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [type]: indexes.join(','),
          }
        });

        this.$emit('update-filters', {
          filters: this.filters,
          type
        })
      }

    },

    removeIsDisabled(value){
      value.forEach((element) => {
        setTimeout(() => {
          const [refCheckBox] = this.$refs[element]
          const nodeCheckBox = refCheckBox?.$el.childNodes[0]
          if(nodeCheckBox) nodeCheckBox.classList.remove('is-disabled')
        }, 150)
      })
    },

    addIsDisabled(){
      for (let index = 0; index < this.filters.length; index++) {
        const element = this.filters[index];
        if (typeof this.$refs[element] != "undefined") {
          const [refCheckBox] = this.$refs[element]
          const nodeCheckBox = refCheckBox.$el.childNodes[0]
          nodeCheckBox.classList.add('is-disabled')
        }
      }
    },
    
    showOnlyLink(filterName){
      if (this.isCheckedAll) return false
      if (this.filters.includes(filterName) && this.filters.length == 1) {
        return false
      }
      return true
    },

    showUncheckedTooltip(filterName) {
      if (this.isCheckedAll) return false
      if (this.counterFiltersAsNumber > 1) return false
      if (!this.filters.includes(filterName)) return false
      return true
    },

    areEqualsFiltersAndOptions( a, b ) {
      return this.isSameLength(a, b) && this.itsIncluded( a, b );
    },

    itsIncluded( a, b ) {
      return a.every( ( element ) => b.includes( element ) )
    },

    isSameLength( a, b ) {
      return a.length === b.length;
    }
    
  }
}
</script>

<style lang="scss" scoped>
.style-font-italic {
  font-style: italic;
}
.select-custom {
  text-decoration: underline;
  color: #0268BC;
  font-weight: 500;
}

.unselect-custom {
  text-decoration: none;
  color: inherit;
  font-weight: normal;
}


.multiselect-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.row-filter > span{
  display: none;
}

.row-filter:hover > span{
  display: block;
}

.tooltip{
  position: absolute;
  bottom: 10px;
  left: 1px;
  width: 16px;
  height: 16px;
}

.el-dropdown-menu{
  max-height: 90vh;
  overflow-y: auto;
}

</style>