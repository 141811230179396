export const REGULAR_ACCOUNT = 'REGULAR';
export const PARENT_ACCOUNT = 'PARENT';
export const CHILD_ACCOUNT = 'CHILD';
export const CUSTOMER_SUB_TYPE_XL = 'XL'
export const CUSTOMER_SUB_TYPE_ZL = 'ZL'
export const CUSTOMER_TYPE_AMAZON_DSP = 'Amazon DSP'
export const ACCOUNT_COUNTRY_DEFAULT = 'USA'
export const CUSTOMER_STATUS = [
    'Active - Bundle',
    'Active - Premium',
    'Active - Standard',
    'Churned',
    'Lapsed Trial',
    'Trial',
    'Trial (Plan Selected)',
    'Unknown'
]
export const CUSTOMER_TYPE = [CUSTOMER_TYPE_AMAZON_DSP,'FedEx ISP'];
export const CUSTOMER_SUBTYPE = ['ZL','XL','Regular', 'Lite'];
export const CUSTOMER_TYPE_SUBTYPE_AMAZON_DSP = ['ZL','XL', 'Lite'];
export const CUSTOMER_TYPE_SUBTYPE_FEDEX_ISP = ['Regular'];
export const ACCOUNT_COUNTRY = ['USA','Canada','UK','Australia'];
export const ACCOUNT_TIME = ['Permanent','Temporary'];
export const ACCOUNT_TESTING = ['Yes','No'];
export const FEATURE_ACCESS_PERMISSION_FILL = {
    featureAccessVehiclePhotoLogsHeraAi: 'Vehicle Management: Vehicle Photo Logs Hera AI',
    featureAccessInventoryManagement: 'Inventory Management'
};
export const FEATURE_ENABLED_PERMISSION_FILL= {
    featureEnabledVehiclePhotoLogsHeraAi: 'Vehicle Management: Vehicle Photo Logs Hera AI',
    featureEnabledInventoryManagement: 'Inventory Management'
};
export const FEATURES_ACCESS_PERMISSIONS = ['Vehicle Management: Vehicle Photo Logs Hera AI (ON)',
                                            'Vehicle Management: Vehicle Photo Logs Hera AI (OFF)',
                                            'Inventory Management (ON)',
                                            'Inventory Management (OFF)'];
export const FEATURES_ENABLED_PERMISSIONS = ['Vehicle Management: Vehicle Photo Logs Hera AI (Enabled)',
                                            'Vehicle Management: Vehicle Photo Logs Hera AI (Disabled)',
                                            'Inventory Management (Enabled)',
                                            'Inventory Management (Disabled)'];
export const ABBREVATION_MAP_FEATURES = {
                                        'Vehicle Management': 'VPLAI',
                                        'Inventory Management': 'IM'
                                        };
export const ACCOUNT_CANCELED_REASON = [
    "Cost Savings",
    "DeliverRE Partnership Ended",
    "Didn't Fully Utilize or Find Value",
    "Dropped Associates to 0",
    "DSP Closed",
    "Internal Processes in Use",
    "Leveraging Previous Internal Processes",
    "Non-Bundle Customer",
    "Pause Subscription",
    "Reduced Route Count",
    "Restructuring Internal Business Operations",
    "Secondary Site Closure",
    "Stopped Using - No Explanation",
    "Switched to Competitor - DSPworkplace",
    "Switched to Competitor - Drivr",
    "Switched to Competitor - LMDmax",
    "Switched to Competitor - Lokiteck",
    "Switched to Competitor - Manage my DSP",
    "Switched to Competitor - Other"
];