import {
  vehicleFragment,
  associateFragment,
  deviceFragment,
  userFragment,
  dailyRosterFragment,
  dailyLogFragment,
  associateFragmentWithConnections,
  associateFragmentWithoutConnections,
  companyNotificationFragment,
  userNotificationFragment
} from './fragments'

export const vehiclesByGroup = vehicleFragment + /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...vehicleFragment
      }
      nextToken
    }
  }
`;
export const getVehicle = vehicleFragment + /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      ...vehicleFragment
    }
  }
`;
export const getStaff = associateFragment + /* GraphQL */ `
  query getStaff($id: ID!) {
    getStaff(id: $id) {
      ...associateFragment
    }
  }
`;
export const staffsByGroup = associateFragment + /* GraphQL */ `
query StaffsByGroup(
  $group: String
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroup(
    group: $group
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ...associateFragment
    }
    nextToken
  }
}
`;

/**
 * StaffByGroupStatus query, this query only contains data from the staff table, without connections
 */
export const staffsByGroupStatusWithoutConexions = associateFragmentWithoutConnections + /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...associateFragmentWithoutConnections
      }
      nextToken
    }
  }
`;

/**
 * StaffsByGroupStatus query, this query only contains staff connections
 */
export const staffsByGroupStatusWithConexions = associateFragmentWithConnections + /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...associateFragmentWithConnections
      }
      nextToken
    }
  }
`;

export const devicesByGroup = deviceFragment + /* GraphQL */ `
  query DevicesByGroup(
    $group: String
    $deviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByGroup(
      group: $group
      deviceName: $deviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...deviceFragment
      }
      nextToken
    }
  }
`;

export const usersByTenant = userFragment + /* GraphQL */ `
  query UsersByTenant(
    $userTenantId: ID,
    $sortDirection: ModelSortDirection,
    $filter: ModelUserFilterInput,
    $limit: Int,
    $nextToken: String
    ) {
    usersByTenant(
      userTenantId: $userTenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ){
      items{
        ...userFragment
      }
      nextToken
    }
  }
`;

export const getDailyRoster = dailyRosterFragment + /* GraphQL */ `
  query GetDailyRoster($id: ID!) {
    getDailyRoster(id: $id) {
      ...dailyRosterFragment
    }
  }
`;
export const dailyLogsByDate = dailyLogFragment + /* GraphQL */ `
  query DailyLogsByDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogsByDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...dailyLogFragment
      }
      nextToken
    }
  }
`;

export const associateList = `fragment associateFragment on Staff {
  id
  firstName
  lastName
  status
  transporterId
  dob
  phone
  email
  dlExpiration
  vehicleReport
  gasCardPin
  defaultVehicle {
  id
  status
  name
  }
  defaultVehicle2 {
  id
  status
  name
  }
  defaultVehicle3 {
  id
  status
  name
  }
  gender
  pronouns
  isALead
  assignedLead
  hourlyStatus
  hireDate
  labels(limit: 513) {
  items {
  id
  createdAt
  group
  labelId
  label {
  id
  name
  status
  }
  }
  nextToken
  }
  preferredDaysOff
  finalCheckIssueDate
  scoreCards(limit: 513) {
  items {
  id
  group
  overallTier
  year
  week
  }
  nextToken
  }
  route(limit: 513) {
  items {
  id
  group
  rescued
  }
  }
  routeRescuer(limit: 513) {
  items {
  id
  }
  }
  }
  
  query StaffsByGroup($group: String, $firstName: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelStaffFilterInput, $limit:       Int, $nextToken: String) {
  staffsByGroup(group: $group, firstName: $firstName, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
  items {
  ...associateFragment
  }
  nextToken
  }
}
`;

export const companyNotificationsByGroup = companyNotificationFragment + /* GraphQL */ `
  query CompanyNotificationsByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyNotificationsByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...companyNotificationFragment
      }
      nextToken
    }
  }
`;

export const userNotificationsByUserIdAndCreatedAt = userNotificationFragment + /* GraphQL */ `
  query UserNotificationsByUserIdAndCreatedAt(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotificationsByUserIdAndCreatedAt(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...userNotificationFragment
      }
      nextToken
    }
  }
`;

export const userNotificationsByUserIdAndIsRead = userNotificationFragment + /* GraphQL */ `
  query UserNotificationsByUserIdAndIsRead(
    $userId: ID
    $isRead: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotificationsByUserIdAndIsRead(
      userId: $userId
      isRead: $isRead
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...userNotificationFragment
      }
      nextToken
    }
  }
`;