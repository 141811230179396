import { API } from "aws-amplify"
import { mixinMethods } from '@/main'
import store from '@/store'
import { Notification as notify } from 'element-ui'
import { subscribedDevicesByUserId } from './queries.js'
const PUBLIC_VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY

export async function subscribeToPushNotifications() {
    try {
        // Wait for the service worker to be ready
        const registration = await navigator.serviceWorker.ready

        // Request notification permission from the user
        //'Notification' is https://developer.mozilla.org/en-US/docs/Web/API/Notification
        const permission = await Notification.requestPermission()
        if (permission === 'granted') {
            // Subscribe to push notifications
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
            });

            sendSubscription(subscription)
        }else if(permission === 'denied'){
            notify({
                title: 'Warning', 
                message: 'Notifications are blocked. Reset browser permissions to receive push notifications', 
                type: 'warning'
            })
        }
        return permission
    } catch (error) {
        console.error('Error during subscription:', error)
    }
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
    const rawData = window.atob(base64)
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)))
}

function sendSubscription(subscription) {
    let apiName = "heraPublicApi"
    let path = "/executeMutation"
    let post = {
        body: {
            type: "subscribe-push-notifications",
            group: store.state.userInfo.tenant.group,
            userId:  store.state.userInfo.id,
            subscriptionData: JSON.stringify(subscription),
            items: {},
        },
    }
    safeFunction(API.post)(apiName, path, post)
}

export async function manageSubscription() {
    if('Notification' in window && Notification.permission === 'granted'){
        const registration = await navigator.serviceWorker.ready
        const existingSubscription = await registration.pushManager.getSubscription()
        if(existingSubscription){
            const endpoint = existingSubscription.endpoint
            if(!store.state.userInfo?.id) return
            const input = { 
                userId: store.state.userInfo.id,
            }
            const subscribedDevices = await mixinMethods.gLoadListAll(subscribedDevicesByUserId, input, "subscribedDevicesByUserId")
            let exist = false
            for(let device of subscribedDevices){
                const subscriptionData = JSON.parse(device.subscriptionData)
                const _endpoint = subscriptionData.endpoint
                if(_endpoint === endpoint) exist = true
            }
            if(!exist){
                //unsubscribe current endpoint
                await existingSubscription.unsubscribe()
            }
            //new subcription
            await subscribeToPushNotifications()
        } else {
            //new subcription
            await subscribeToPushNotifications()
        }
    }

}

export async function checkPushSubscription() {
    let exist = false
    let registration = await navigator.serviceWorker.ready
    let subscription = await registration.pushManager.getSubscription() // Returns the subscription or null
    const endpoint = subscription?.endpoint
    //ckeck SubscribedDevices by userId
    const input = { 
        userId: store.state.userInfo.id,
    }
    const subscribedDevices = await mixinMethods.gLoadListAll(subscribedDevicesByUserId, input, "subscribedDevicesByUserId")
    for(let device of subscribedDevices){
        const subscriptionData = JSON.parse(device.subscriptionData)
        const _endpoint = subscriptionData.endpoint
        if(_endpoint === endpoint) exist = true
    }
    return exist
}