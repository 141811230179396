<template>
  <el-menu
    class="collapsible-menu-custom"
    mode="vertical"
    default-active="0"
    :collapse="true"
    :collapse-transition="true"
  >
    <el-submenu index="0">
      <template slot="title">
        <div class="el-dropdown-menu__item">{{ menuData.title }}</div>
      </template>
      <template v-for="(item, itemIndex) in menuData.submenu">
        <el-menu-item
          v-if="checkDisplay(item)"
          :key="itemIndex"
          :index="itemIndex.toString()"
          @click="$emit('command', item.command)"
        >
          <div class="el-dropdown-menu__item">{{ item.label }}</div>
        </el-menu-item>
      </template>
    </el-submenu>
  </el-menu>
</template>
<script>
  export default {
    name: "CollapsibleMenu",
    props: {
      menuData: {
        type: Object,
        required: true
      }
    },
    methods:{
      checkDisplay(item){
        if(typeof item.shouldDisplay === 'boolean'){
          return item.shouldDisplay
        }
        if (item.shouldDisplay instanceof Function) {
          return item.shouldDisplay();
        }
        return true
      }
    }
  };
</script>
<style>
  .collapsible-menu-custom.el-menu--collapse {
    @apply w-full !important;
  }
  .collapsible-menu-custom .el-submenu__title, .el-menu-item:has( > .el-dropdown-menu__item) {
    padding: 0px !important;
    line-height: unset !important;
    height: unset !important;
  }
  .collapsible-menu-custom.el-menu--collapse > .el-submenu > .el-submenu__title .el-submenu__icon-arrow{
    display: block !important;
  }
  .collapsible-menu-custom .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
    @apply transform rotate-180 !important;
  }
</style>