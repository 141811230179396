import { getDateForWeekYear, getMaxWeeksInYear, validateLastWeekYear } from '@/main';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const dateFormatter = {
  name: 'date',
  formatToString: (date) => moment(date).format(dateFormat),
  isValid: (queryParamValue) => {
    const pattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const isValid = pattern.test(queryParamValue);
    return isValid;
  },
  formatToDate: (dateString) => {
    let [ year, month, day ] = dateString.split("-");
    month = parseInt(month) - 1;
    return new Date(year, month, day);
  },
}

const weekFormatter = {
  name: 'week',
  formatToString: (date) => {
    const year = validateLastWeekYear(date);
    const week = date.getWeekNumber();
    return `${year}-week-${week}`;
  },
  isValid: (queryParamValue, cbValidator = null) => { // cb means call back function
    const pattern = /^\d{4}-week-(0[1-9]|[1-4]\d|5[0-3])$/;
    const isValidPattern = pattern.test(queryParamValue);
    if(!isValidPattern) return false;
    
    let isValidWeek = false;
    let [year, week] = queryParamValue;
    year = parseInt(year);
    week = parseInt(week);
    const maxWeek = getMaxWeeksInYear(year);
    isValidWeek = week <= maxWeek;
    if(!isValidPattern) return false

    if(cbValidator) {
      const isValidCB = cbValidator();
      return isValidCB;
    }

    return true;
  },
  formatToDate: (dateString) => {
    const [ year, week ] = dateString.split('-week-')
    return getDateForWeekYear(week, year);
  },
}

const daterangeFormatter = {
  name: 'daterange',
  formatToString: (date) => {
    if(date instanceof Date) {
      return moment(date).format(dateFormat);
    }

    if(Array.isArray(date) && date.length === 1) {
      return moment(date[0]).format(dateFormat);
    }

    if(Array.isArray(date) 
      && date.length === 2 
      && moment(date[0]).isSame(date[1])
    ) {
      return moment(date[0]).format(dateFormat)
    }

    if(Array.isArray(date) && date.length === 2) {
      let [date1, date2] = date;
      if(date1 > date2) {
        const aux = date1;
        date1 = date2;
        date2 = aux;
      }
      date1 = moment(date1).format(dateFormat);
      date2 = moment(date2).format(dateFormat);
      return `${date1}-to-${date2}`;
    }
    return "not-valid";
  },
  isValid: (queryParamValue) => {
    const oneDatePattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const isValidOneDate = oneDatePattern.test(queryParamValue);

    const twoDatesPattern =  /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-to-\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const isValidTwoDates = twoDatesPattern.test(queryParamValue);
    
    return isValidOneDate || isValidTwoDates;
  },
  formatToDate: (dateString) => {
    const isOneDate = !dateString.includes('-to-');
    if(isOneDate){
      let [ year, month, day ] = dateString.split("-");
      month = parseInt(month) - 1;
      const date = new Date(year, month, day)
      return [date, date];
    }

    const isTwoDates = dateString.includes('-to-');
    if(isTwoDates) {
      let [ date1, date2 ] = dateString.split('-to-');
    
      let [ yearDate1, monthDate1, dayDate1 ] = date1.split("-");
      monthDate1 = parseInt(monthDate1) - 1;
      date1 = new Date(yearDate1, monthDate1, dayDate1);
  
      let [ yearDate2, monthDate2, dayDate2 ] = date2.split("-");
      monthDate2 = parseInt(monthDate2) - 1;
      date2 = new Date(yearDate2, monthDate2, dayDate2);
  
      if(date1 <= date2) return [date1, date2]
      return [date2, date1]
    }
  },
}

export const formatters = {
  date: dateFormatter,
  week: weekFormatter,
  daterange: daterangeFormatter,
};
