export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoSub
      firstName
      lastName
      phone
      email
      notificationEmailAddress
      notificationSmsNumber
      receiveNotificationInApp
      receiveNotificationBySms
      receiveNotificationByEmail
    }
  }
`;

export const subscribedDevicesByUserId = /* GraphQL */ `
  query SubscribedDevicesByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubscribedDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscribedDevicesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        subscriptionData
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;