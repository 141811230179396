
export const staffsByGroupStatus = /* GraphQL */ `
query StaffsByGroupStatus(
  $group: String
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $sortDirectionMessage: ModelSortDirection
  $filterMessage: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroupStatus(
    group: $group
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      smsLastMessageTimestamp
      smsLastMessage
      status
      messages(
        sortDirection: $sortDirectionMessage
        filter: $filterMessage
        limit: 25
      ) {
        items {
          messageType
          channelType
          bodyText
          createdAt
        }
      }
      messageReadStatus {
        items {
          readUpToDateTime
          group
          userID
          staffID
        }
      }
    }
    nextToken
  }
}
`;

export const devicesByGroupStatus = /* GraphQL */ `
query DevicesByGroupStatus(
  $group: String
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDeviceFilterInput
  $sortDirectionMessage: ModelSortDirection
  $filterMessage: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  devicesByGroupStatus(
    group: $group
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deviceName
      deviceLastMessageTimestamp
      deviceLastMessage
      status
      messages(
        sortDirection: $sortDirectionMessage
        filter: $filterMessage
        limit: 100
      ) {
        items {
          messageType
          channelType
          bodyText
          createdAt
        }
      }
      messageReadStatus {
        items {
          readUpToDateTime
          group
          userID
          deviceID
        }
      }
    }
    nextToken
  }
}
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      firstName
      lastName
      status
      phone
      photo
      smsLastMessage
    }
  }
`;

export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      deviceName
      phoneNumber
      status
      deviceLastMessage
    }
  }
`;
export const messagesByStaffId = /* GraphQL */ `
  query MessagesByStaffId(
    $staffId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByStaffId(
      staffId: $staffId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        channelType
        group
        bodyText
        senderName
        smsStatus
        smsSendInformation
        isReadS
        isReadBy {
          items {
            id 
            userID
          }
        }
        sender {
          id
        }
        messageType
        attachmentLink
        contentType
        attachment{
          id
          s3Key
          fileSize
          contentType
          expirationDate
          shortenUrl {
            items {
              id
              shortenId
            }
            nextToken
          }
        }
        hasParts
      }
      nextToken
    }
  }
`;
export const messagesByDeviceId = /* GraphQL */ `
  query MessagesByDeviceId(
    $deviceId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByDeviceId(
      deviceId: $deviceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        channelType
        group
        bodyText
        senderName
        deviceSmsStatus
        deviceSmsSendInformation
        isReadS
        isReadBy {
          items {
            id 
            userID
          }
        }
        sender {
          id
        }
        messageType
        attachmentLink
        contentType
        attachment{
          id
          s3Key
          fileSize
          contentType
          expirationDate
          shortenUrl {
            items {
              id
              shortenId
            }
            nextToken
          }
        }
        hasParts
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      createdAt
      channelType
      bodyText
      smsStatus
      smsSendInformation
      isReadS
      attachmentLink
      linkExpiryDate
      deviceSmsStatus
      deviceSmsSendInformation
    }
  }
`;

export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      s3Key
      fileSize
      expirationDate
      contentType
    }
  }
`;

export const vehiclesByGroup = /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        mileage
        vin
        licensePlate
        gasCard
        company {
          id
          option
        }
      }
      nextToken
    }
  }
`;

export const accidentsByVehicle = /* GraphQL */ `
  query AccidentsByVehicle(
    $vehicleId: String
    $accidentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByVehicle(
      vehicleId: $vehicleId
      accidentDate: $accidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accidentDate
        staff {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const accidentsByVehicleAndHistoryType = /* GraphQL */ `
  query AccidentsByVehicleAndHistoryType(
    $vehicleId: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByVehicelByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByVehicleAndHistoryType(
      vehicleId: $vehicleId
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accidentDate
        vehicleHistoryType
        staff {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const messageReadStatusByUserAndStaff = /* GraphQL */ `
  query MessageReadStatusByUserAndStaff(
    $group: String
    $staffIDUserID: ModelMessageReadStatusByUserAndStaffCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageReadStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageReadStatusByUserAndStaff(
      group: $group
      staffIDUserID: $staffIDUserID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        userID
        staffID
        readUpToDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageReadStatusByUserAndDevice = /* GraphQL */ `
  query MessageReadStatusByUserAndDevice(
    $group: String
    $staffIDUserID: ModelMessageReadStatusByUserAndDeviceCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageReadStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageReadStatusByUserAndDevice(
      group: $group
      staffIDUserID: $staffIDUserID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        userID
        deviceID
        readUpToDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getStaffByRouteDeviceId = /* GraphQL */ `
  query GetStaffByRouteDeviceId($deviceId: ID!) {
    routeByDevice(routeDeviceId: $deviceId) {
      items {
        createdAt
        updatedAt
        staff {
          firstName
          lastName
        }
      }
    }
  }
`;