import { API, graphqlOperation } from 'aws-amplify'
import { updateTenantMessageService } from './queries'
import { createMessageServiceProvider, updateTelnyx, createTelnyx } from '@/graphql/mutations'
import store from '../../../store'
import { listTelnyxs } from '@/graphql/queries'
import { getAllTelnyxTenantEnviroment } from '@/views/System/Telnyx/utils'
import moment from 'moment-timezone';

async function telnyxSaveChanges(tenantRow, phoneNumber, idTelnyx, typeService) {
  let inputTenant = {
      id: tenantRow.id,
      group: tenantRow.group,
      messageServiceProvider: typeService,
      originationNumber: phoneNumber,
  }
  const tenantUpdated = await api(updateTenantMessageService, {input: inputTenant})
  
  let inputForHistory = {
      previousMessageServicerProvider: tenantRow.messageServiceProvider,
      currentMessageServicerProvider: inputTenant.messageServiceProvider,
      date: moment(),
      messageServiceProviderTenantId: tenantRow.id,
      group: tenantRow.group,
  }
  await api(createMessageServiceProvider, {input:inputForHistory})

  if (tenantRow.messageServiceProvider == 'Telnyx' || tenantRow.messageServiceProvider == 'None') {
      let group = typeService === 'None' ? 'null' : tenantRow.group
      let inputTelnyx = {
          id: idTelnyx,
          group: group,
      }
      await api(updateTelnyx, { input: inputTelnyx })
  } else if (tenantRow.messageServiceProvider == 'Bandwidth' && typeService != 'None') {
      const existingTelnyxId = await checkForExists(phoneNumber)
      if (existingTelnyxId) {
        let inputTelnyx = {
          id: existingTelnyxId,
          group: tenantRow.group,
        }
        await api(updateTelnyx, { input: inputTelnyx })
      } else {
          let input = {
              group: tenantRow.group,
              phoneNumber: phoneNumber,
              telnyxId: idTelnyx,
              status: 'active',
              date: new Date().toISOString(),
          }
          await api(createTelnyx, { input: input })
      }
  }

  return tenantUpdated
}

export async function checkForExists(number) {
  const responseList = await gLoadListAll(listTelnyxs, {}, 'listTelnyxs')

  let telnyxNumber = responseList.find(item => {
      return item.phoneNumber === number
  })

  if (!telnyxNumber) {
      return false
  }

  return telnyxNumber.id
}

export async function getAvailableNumber() {
  const responseList = await gLoadListAll(listTelnyxs, {}, 'listTelnyxs')

  let telnyxNumber = responseList.find(ele => {
      return ele.status === 'active' && ele.group === 'null'
  })

  if (!telnyxNumber) {
      console.log('getAvailableNumber: No available Telnyx numbers found')
      throw 'No available Telnyx numbers found'
  }
  
  const telnyxNumberAllEnviroment =  await getAllTelnyxTenantEnviroment()

  let validateTelnyx = telnyxNumberAllEnviroment.resultTelnyx.find(item => {
      return item.phoneNumber === telnyxNumber.phoneNumber && item.id != telnyxNumber.id
  })

  if (validateTelnyx) {
      throw `The Telnyx number is already active in the environment <b>${validateTelnyx.enviroment}</b>`
  }

  return telnyxNumber
}

export async function updateAvailableNumber(tenantRow) {
  let newNumber = tenantRow.originationNumber
  let newNumberId = 0
  const responseList = await gLoadListAll(listTelnyxs, {}, 'listTelnyxs')

  let telnyxNumber = responseList.find(ele => {
      return ele.status === 'active' && ele.group === 'null'
  })

  if (!telnyxNumber) {
    console.log('updateAvailableNumber: No available Telnyx numbers found')
    throw 'No available Telnyx numbers found'
  }

  newNumber = telnyxNumber.phoneNumber
  newNumberId = telnyxNumber.id
  return telnyxSaveChanges(tenantRow, newNumber, newNumberId, 'Telnyx')
}

export async function telnyxAddNumber(tenantRow) {
  let newNumber = tenantRow.originationNumber
  let newNumberId = 0

  if(tenantRow.messageServiceProvider != 'Bandwidth') {
      let telnyxNumber = await getAvailableNumber()
      newNumber = telnyxNumber.phoneNumber
      newNumberId = telnyxNumber.id
  }

  return telnyxSaveChanges(tenantRow, newNumber, newNumberId, 'Telnyx')
}

export async function telnyxRemoveNumber(tenantRow) {

  let idTelnyx = 0

  if(tenantRow.messageServiceProvider == 'Telnyx') { 
    const responseList = await gLoadListAll(listTelnyxs, {},'listTelnyxs')

    let telnyxNumber = responseList.find((ele) => {
      return ele.phoneNumber === tenantRow.originationNumber
    })

    if(!telnyxNumber) {
      console.log('removeTelnyxNumber: number not found', JSON.stringify(tenantRow))
      throw 'Telnyx number not found in database'
    }

    idTelnyx = telnyxNumber.id
  }

  return telnyxSaveChanges(tenantRow, null, idTelnyx, 'None')
}

async function gLoadListAll(query, input, queryName) {
  var nextToken = null
  var list = []
  do{
      input.nextToken = nextToken
      var response
      try{
        response = await safeFunction(API.graphql)(graphqlOperation(query, cleanVars(input, query)))
      }
      catch(err){
        response = errorCatch(err)
      }

      list = [ ...list, ...response.data[queryName].items]
      nextToken = response.data[queryName].nextToken
  }while(nextToken)
  return list
}

export async function api(query, variables = {}, timestampQuery = null, timestampQueryName = null) {
  let result
  try{
    result = await safeFunction(API.graphql)(graphqlOperation(query, cleanVars(variables, query)))
  }
  catch(err){
    console.error(err)
    result = errorCatch(err)
  }

  return result
}

function errorCatch(err) {
  if (err.message === "No current user" || err.message === "not authenticated") {
    router.push({
      path: '/',
      query: { redirect: window.location.pathname }
    })
  }
  console.log("errorCatch", err)
  err.errors = err.errors.filter((e) => {
    // ignore unauthorized errors with null values
    // fix for amplify error: https://github.com/aws-amplify/amplify-cli/issues/4907
    if (e.message.indexOf("Not Authorized to access") === 0) {
      const val = get(err.data, e.path);
      if (val === null) {
        return false;
      }
    }
    return true;
  })

  if (err.errors.length !== 0) {
    throw err;
  }

  return err;
}

function cleanVars(variables, query) {
  //Remove created/updated timestamps from mutations.
  if (variables.input) {
    if (variables.input.createdAt && !query.includes('mutation UpdateMessage')) {
      delete variables.input.createdAt
    }
    if (variables.input.updatedAt) {
      delete variables.input.updatedAt
    }
    if (!variables.input.group && !query.includes("$input: Delete")) {
      variables.input.group = store.state.userInfo.tenant.group
    }
  }

  return variables
}