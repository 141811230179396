<template>
    <div data-cy="da-kudo-form" v-if="hasPerformanceCoachingManagement">
        <el-form label-width="183px" label-position="top" :model="formFields" ref="form" :rules="rules" v-loading="loading">
            <div class="grid grid-flow-row grid-cols-12 w-full gap-x-4">
                <el-form-item :label="$t('label.associate')" prop="staffId" class="col-span-12 lg:col-span-6">
                    <el-select data-cy="da-list" :disabled="staffSelectDisabled" v-model="formFields.staffId" filterable clearable placeholder="Type to search...">
                        <el-option
                        v-for="item in daList"
                        :key="item.id"
                        :label="item.firstName + ' ' + item.lastName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Date of Occurrence" prop="date" class="col-span-12 lg:col-span-6">
                    <DatePicker data-cy="date-of-occurrence" :selectedDate="formFields.date" placeholder="Select Date" @date-selected="handleDateSelected($event, 'date')" :disableTimeZoneConversion="true"/>
                </el-form-item>
                
                <el-form-item label="Kudo Type" prop="kudoType.id" class="col-span-12 lg:col-span-6">
                    <CustomListsOptionsSelect
                        v-model="formFields.kudoType"
                        filterable
                        data-cy="kudo-type"
                        ref="kudoTypeDropdown"
                        :placeholder="`Select ${$t('label.associate')} Kudo Type`"
                        listType="kudo-type"
                        linkText="+ Add New Associate Kudo Type"
                        :customListEnabledArgs="[!editing, formFields.kudoType.id, [], hiddenSection]"
                    />
                </el-form-item>
            </div>

            <el-form-item label="Notes" prop="notes">
                <el-input data-cy="notes"
                    type="textarea"
                    :autosize="{ minRows: 3 }" 
                    v-model="formFields.notes"/>
            </el-form-item>
        </el-form>
        <div class="text-gray-600 mb-8 break-word">{{$t('label.associate')}} Kudos do not get sent out to {{$t('label.associates')}} and can only be seen by users who log into Hera.</div>
        <div slot="footer" class="dialog-footer text-right w-full">
            <ButtonGroupWrapper>
                <template slot-scope="{disabled, setDisabled}">
                    <el-button
                        type="plain"
                        :disabled="disabled"
                        @click="$emit('close-add-kudo')"
                    >
                        {{closeButtonLabel}}
                    </el-button>
                    <ButtonWrapper
                        type="primary"
                        :clickHandler="mutateDaKudo"
                        :disabled="disabled"
                        @cb-start="setDisabled(true)"
                        @cb-end="setDisabled(false)"
                    >
                        {{actionButtonLabel}}
                    </ButtonWrapper>
                </template>
            </ButtonGroupWrapper>
        </div>
    </div> 
    <div class="flex justify-between card" v-else>
      <h4 class="mb-3 mt-2 text-sm bold">Please contact your administrator if you need access to Performance & Coaching
        in Hera.</h4>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import { HIDDEN_KUDO_TYPE_CREATE_KUDO } from '@/utilities/constants/hiddenSecctionForCustomList';
    import { KUDO_TYPE_CREATE_KUDO } from '@/utilities/constants/defaultSectionsByCustomList';
    import { getKudoUpdatedTimestamp } from '@/utilities/timestampQueries'
    import { createKudo, updateKudo } from '@/graphql/mutations'
    import DatePicker from '@/components/Datepicker';
    import ButtonWrapper from '@/components/shared/ButtonWrapper/ButtonWrapper';
    import ButtonGroupWrapper from '@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper';
    import { valueMapping, idMappings } from "@/utilities/constants/oldIssueOrKudoValues";
    import CustomListsOptionsSelect from '@/components/CustomListsOptionsSelect';
    import loadAssociateMixin from '@/views/Staff/loadAssociateMixin';

    export default {
        name: 'DaKudoForm',
        components: {
            DatePicker,
            ButtonWrapper,
            ButtonGroupWrapper,
            CustomListsOptionsSelect,
        },
        props: {
            editFormFields: {
                type: Object,
                require: true,
                default: () => ({})
            }
        },
        mixins: [loadAssociateMixin],
        data() {
            return {
                loading: false,
                daList: [],
                formFields: {
                    date: null,
                    notes: null,
                    staffId: null,
                    kudoType: { id: null }
                },
                rules: {
                    staffId: [
                        { required: true, message: 'Associate is required', trigger: 'change' },
                    ],
                    "kudoType.id": [
                        { required: true, message: 'Kudo type is required', trigger: 'change' }
                    ],
                    date: [
                        { required: true, message: 'Date is required', trigger: 'change' }
                    ]
                }
            }
        },

        created(){
            this.loadData()
            if(!this.editing){
                //set default option
                this.formFields.kudoType = this.customListEnabled(this.kudoTypeList).find(item => item.isDefaultForSections?.includes(KUDO_TYPE_CREATE_KUDO)) || { id: null };
            }
        },

        computed: {

            ...mapGetters([
                'kudoTypeList',
                'hasPerformanceCoachingManagement'
            ]),

            hiddenSection(){
                return HIDDEN_KUDO_TYPE_CREATE_KUDO;
            }, 
            
            staffSelectDisabled(){
                return !!this.editFormFields?.staff?.id
            },
            editing(){
                if(this.editFormFields?.staff?.id && this.editFormFields?.id) return true
                return false
            },
            closeButtonLabel(){
                return this.editing ? 'Close' : 'Cancel'
            },
            actionButtonLabel(){
                return this.editing ? 'Update' : 'Create'
            }
        },

        methods: {
            handleDateSelected(date, model) {
                let transformedDate = null
                if(date != null) transformedDate = this.removeTZfromDate(date);
                this.formFields[model] = transformedDate
            },

            async mutateDaKudo(){
                try{
                    //validate
                    await this.$refs.form.validate((valid) => {
                        if (!valid) {
                        throw{
                            errors: [
                                { message: "Please fill out all required fields."}
                            ]
                        }
                        }
                    })
                    let input = { ...this.formFields }

                    delete input.staff
                    delete input.route
                    delete input.type
                    input.kudoTypeId = input.kudoType.id
                    input.kudoStaffId = input.staffId
                    input.date = this.$moment(input.date).format('YYYY-MM-DD');
                    const optionFound = this.kudoTypeList.find(item => item.id === input.kudoType?.id)
                    if(optionFound && !optionFound?.isEnabled){
                        this.$refs.kudoTypeDropdown.$refs.select.focus();
                        throw { message: this.optionDisabledMessage(optionFound.option) }
                    }
                    delete input.kudoType;
                    
                    if(this.editing){
                        if(!input.createdFrom){ 
                            const match = input.id?.match(/-(s|cx|m|n|e)-/)
                            const fragment = match ? match[0] : null
                            input.createdFrom = fragment ? idMappings[fragment] : 'form'
                        }
                        await this.api(updateKudo, { input }, getKudoUpdatedTimestamp, 'getKudo' )
                        this.displayUserNotification({
                            title: "Success",
                            type: "success",
                            message: "Kudo updated"
                        }) 
                    }
                    else{
                        input.createdFrom = 'form'
                        await this.api( createKudo, { input } )
                        this.displayUserNotification({
                            title: "Success",
                            type: "success",
                            message: "Kudo created"
                        }) 
                    }
                    this.$emit('mutate-add-kudo')
                    
                }
                catch(e){
                    this.displayUserError(e)
                }
            },
    
            async loadData(){
                this.loading = true
                this.formFields = {
                    date: null,
                    notes: null,
                    staffId: null,
                    kudoType: { id: null }
                }
                if(!this.editing && this.editFormFields?.staff?.id){
                    this.formFields.staffId = this.editFormFields.staff.id
                }
                if(this.editing && this.editFormFields?.staff?.id){
                    this.formFields.id = this.editFormFields.id
                    this.formFields.kudoType = this.editFormFields.kudoType || this.editFormFields.type
                    this.formFields.notes = this.editFormFields.notes
                    this.formFields.date = this.editFormFields.date
                    this.formFields.updatedAt = this.editFormFields.updatedAt
                    this.formFields.staffId = this.editFormFields.staff.id
                    this.formFields.createdFrom = this.editFormFields.createdFrom
                }

                if(this.editing && !this.editFormFields?.type?.id){
                    //functionality to edit old records
                    const kudoType = valueMapping[this.formFields.kudoType] || this.formFields.kudoType
                    const optionKudoType = this.kudoTypeList.find(item => item.option === kudoType);
                    this.formFields.kudoType = optionKudoType || { id: null };
                }
                if(this.editing && this.editFormFields.type){
                    this.formFields.kudoType = this.editFormFields?.type
                }

                if(this.editFormFields?.route?.id){
                    this.formFields.kudoRouteId = this.editFormFields.route.id
                    /** To lock the list with a single staff */
                    this.daList = [this.editFormFields.staff]
                    this.loading = false
                    return
                    /** */
                }
                //load list of active DAs
                try{
                    let activeAssociates = await this.getAssociateListByStatus('allActives')
                    this.daList = activeAssociates.sort((a,b) => { return ('' + a.firstName.toLowerCase()).localeCompare(b.firstName.toLowerCase()); })
                }
                catch(e){
                    this.printUserError(e)
                    this.displayUserError(e)
                }
                finally{
                    this.loading = false
                }

            },

        }
    }
</script>
<style scoped>
    .el-select, .el-date-editor{
        width: 100% !important;
    }
</style>